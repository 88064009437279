<template>
  <div>
    <div class="chart-yearly">
      <div class="yearly">
        <chart-column
          :tab="tab"
          :isEnabledScope3="isEnabledScope3"
          :branchIds="branchIds"
          :chartData="chartData"
          :isGetAllFilter="isGetAllFilter"
          :startMonth="startMonth"
          @openDialogStateDetail="openDialogStateDetail"
          @onUpdateStateDetail="onUpdateStateDetail"
        ></chart-column>
      </div>
      <div class="yearly-detail">
        <chart-detail-yearly
          :titleEachType="$t('dashboard_main.label_scope_type_discharge')"
          :branchIds="branchIds"
          :isEnabledScope3="isEnabledScope3"
          :selectedChartData="selectedChartData"
          :selectedChartDataWithCompareValues="selectedChartDataWithCompareValues"
          :isShowContent="isShowChartDetailYearly"
          :tab="tab"
          :isGetAllFilter="isGetAllFilter"
        ></chart-detail-yearly>
      </div>
    </div>
    <div class="dialog-state-detail">
      <chart-detail-yearly-dialog :dialog="isShowChartDetailDialog" @close="closeDialog">
        <chart-detail-yearly
          :titleEachType="$t('dashboard_main.label_scope_type_discharge')"
          :branchIds="branchIds"
          :isEnabledScope3="isEnabledScope3"
          :selectedChartData="selectedChartData"
          :selectedChartDataWithCompareValues="selectedChartDataWithCompareValues"
          :isShowContent="isShowChartDetailYearly"
          :tab="tab"
          :isGetAllFilter="isGetAllFilter"
          :isDialog="true"
        ></chart-detail-yearly>
      </chart-detail-yearly-dialog>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.chart-yearly {
  .yearly-detail {
    display: none;
  }
}

.dialog-state-detail {
  display: none;
}

// desktop
@include desktop {
  .dialog-state-detail {
    display: block;
  }

  .chart-yearly {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    .yearly {
      display: block;
    }
    .yearly-detail {
      display: block;
      border-radius: 8px;
      border: 1px solid $monoLight;
    }
    .yearly-detail {
      background: $monoWhite;
      border-radius: 8px;
      padding: 16px;
    }
  }
}
</style>

<script>
import { mapActions } from 'vuex';
import ChartColumn from '@/views/dashboard/components/emissions-by-period/column-chart/chart-data.vue';
import ChartDetailYearly from '@/views/dashboard/components/emissions-by-period/column-chart/detail-chart/index.vue';
import ChartDetailYearlyDialog from '@/views/dashboard/components/emissions-by-period/column-chart/detail-chart-dialog/index.vue';
export default {
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    branchIds: {
      type: Array,
      default: () => [],
    },
    isEnabledScope3: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: Number,
      default: 0,
    },
    isGetAllFilter: {
      type: Boolean,
      default: true,
    },
    startMonth: {
      type: Number,
      default: 3,
    }
  },
  components: { ChartDetailYearly, ChartColumn, ChartDetailYearlyDialog },
  data() {
    return {
      isShowChartDetailDialog: false,
      windowWidth: window.outerWidth,
      selectedChartData: {},
      selectedChartDataWithCompareValues: {},
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    if (!window) {
      return;
    }
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    isShowChartDetailYearly() {
      return true;
    },
  },
  watch: {},
  methods: {
    ...mapActions('dashboard', ['updateSelectedEmissionsByPeriodDashboard']),
    onResize() {
      this.windowWidth = window.outerWidth;
    },
    openDialogStateDetail() {
      this.isShowChartDetailDialog = true;
    },
    closeDialog() {
      this.isShowChartDetailDialog = false;
    },
    onUpdateStateDetail(item, status, isOpenDialogDetail= false) {
      if (isOpenDialogDetail) {
        this.openDialogStateDetail();
      }
      let store = {};
      switch (this.tab) {
        case 0:
          store = {
            selectedYear: item?.time_range,
          };
          break;
        case 1:
          store = {
            selectedHalfYear: item?.time_range,
          };
          break;
        case 2:
          store = {
            selectedQuarterYear: item?.time_range,
          };
          break;
        case 3:
          store = {
            selectedMonthYear: item?.time_range,
          };
          break;
        default:
          break;
      }
      const summaryEmission =
        Number(item.emission_scope1) + Number(item.emission_scope2) + Number(item.emission_scope3);
      const periodEmissionScope1 = Number(((Number(item.emission_scope1) / summaryEmission) * 100).toFixed(1));
      const periodEmissionScope2 = Number(((Number(item.emission_scope2) / summaryEmission) * 100).toFixed(1));
      const periodEmissionScope3 = Number((100 - periodEmissionScope1 - periodEmissionScope2).toFixed(1));
      this.selectedChartData = {
        ...item,
        period_emission_scope1: periodEmissionScope1 || 0,
        period_emission_scope2: periodEmissionScope2 || 0,
        period_emission_scope3: periodEmissionScope3 || 0,
      };
      //update store
      this.updateSelectedEmissionsByPeriodDashboard({
        ...this.$store.state.dashboard.selectedEmissionsByPeriodDashboard,
        ...store,
      });
    },
  },
};
</script>
