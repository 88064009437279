<template>
  <div class="state-wrapper" :style="{ display: isShowContent ? 'block' : 'none' }">
    <div class="state-header" :style="getStyleHeader">
      <div class="state-summary" :style="getStyleStateSummary">
        <div class="state-wrap-total-amount" :style="getStyleStateWrapTotalAmount">
          <div class="summary total-amount" :style="getStyleObject">
            <div class="summary__title">{{ chartTitle }}</div>
            <div class="content" :style="{ marginRight: !isDialog && isGetAllFilter ? '8px' : '' }">
              <span class="data">{{ getTotalEmissions }}</span>
              <span class="unit">{{ getUnitName }}</span>
              <div
                v-if="isDialog"
                class="icon-right"
                style="margin-bottom: 4px; margin-left: 8px; display: inline-block"
                @click="goToDetail()"
                @mouseover="right1 = true"
                @mouseleave="right1 = false"
              >
                <img v-if="!right1" width="18" src="img/icons/right.svg" alt="Right" />
                <img v-else width="18" src="img/icons/rightHover.svg" alt="Right" />
              </div>
            </div>
          </div>
          <div
            v-if="isGetAllFilter && !isDialog"
            class="icon-right"
            style="margin-bottom: 4px"
            @click="goToDetail()"
            @mouseover="right1 = true"
            @mouseleave="right1 = false"
          >
            <img v-if="!right1" width="18" src="img/icons/right.svg" alt="Right" />
            <img v-else width="18" src="img/icons/rightHover.svg" alt="Right" />
          </div>
        </div>
        <div v-if="!isGetAllFilter" class="summary state-summary-branch" :style="getStyleSummaryBranch">
          <div class="summary-percent">
            <div class="summary__title">{{ $t('dashboard_main.percent_total_emissions_compare_all_branches') }}</div>
            <div class="content percent-total">
              <div class="pie-chart">
                <v-progress-circular
                  :value="selectedChartData.detail_ratio.rate_all ? selectedChartData.detail_ratio.rate_all : 0"
                  color="#A9A04B"
                  size="28"
                  width="2"
                  rotate="270"
                ></v-progress-circular>
              </div>
              <div class="percent">
                <span class="data">{{ selectedChartData.detail_ratio.rate_all | numberFormat }}</span>
                <span class="percent-unit" v-show="selectedChartData.detail_ratio.rate_all">%</span>
              </div>
            </div>
          </div>
          <div
            class="icon-right"
            v-if="!isDialog"
            style="margin-top: 34px; transform: translateY(-50%)"
            @click="goToDetail()"
            @mouseover="right1 = true"
            @mouseleave="right1 = false"
          >
            <img v-if="!right1" width="18" src="img/icons/right.svg" alt="Right" />
            <img v-else width="18" src="img/icons/rightHover.svg" alt="Right" />
          </div>
        </div>
      </div>
      <div class="chart-ratio-container" :style="getStyleRatioContainer">
        <div class="chart-ratio" :style="getStyleRatio">
          <div class="chart-title__title">{{ getTitleRatio }}</div>
          <div class="chart-ratio__detail">
            <span class="chart-title__unit">{{ ratioValueEmission }}</span>
            <span v-if="ratioValueEmission !== '-'" class="chart-title__unit_suffix">%</span>
            <span v-if="ratioValueEmission !== '-'" class="chart-title_image">
              <img :src="status.src" :alt="status.alt" />
            </span>
          </div>
        </div>
        <div class="chart-ratio" v-if="getStatusCompare" :style="getStyleRatioHasStatusCompare">
          <div class="chart-title__title">{{ getTitleCompare }}</div>
          <div class="chart-ratio__detail">
            <span class="chart-title__unit">{{ ratioValueCompareEmission }}</span>
            <span v-if="ratioValueCompareEmission !== '-'" class="chart-title__unit_suffix">%</span>
            <span v-if="ratioValueCompareEmission !== '-'" class="chart-title_image">
              <img :src="statusIconCompare.src" :alt="statusIconCompare.alt" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="chart-title-new" v-if="!isDialog">
      <div class="scope__title title1">{{ getTitleEachTotalEmission }}</div>
      <!-- <div class="scope__title title3" v-if="!isGetAllFilter">
        {{ $t('dashboard_main.percent_emissions_compare_all_branches') }}
      </div> -->
    </div>
    <div class="chart-detail" :style="getStyleChartDetail">
      <div class="pie-chart" :class="{ 'filter-pie': !isGetAllFilter }" :style="{ width: 'unset' }">
        <chart-yearly-pie :scopeData="scopeDataForPieChart" :isDialog="isDialog" />
      </div>

      <div class="new-detail" :style="getStyleScopeDetail">
        <div class="title" v-if="isDialog">
          <div class="scope__title title1">{{ getTitleEachTotalEmission }}</div>
          <div class="scope__title title3" v-if="!isGetAllFilter">
            {{ $t('dashboard_main.percent_emissions_compare_all_branches') }}
          </div>
        </div>
        <div class="scope">
          <div class="content">
            <div class="color" :style="{ 'background-color': scope1Color }"></div>
            <div class="name scope1">{{ $t('dashboard_main.label_scope') }} 1</div>
          </div>
          <div class="main-data" :style="getStyleScopeMainData">
            <div class="scope-data" :style="getStyleScope">
              <span class="data" :style="getStyleScopeData">{{
                getEmissionData(selectedChartData.emission_scope1)
              }}</span>
              <span class="unit" style="margin-bottom: -5px !important">{{ getUnitName }}</span>
            </div>
            <div class="values-data" :style="getStyleValuesData">
              <div v-if="!isGetAllFilter" class="scope-ratio" :style="getStyleScopeRatio">
                <div class="pie-chart-unit">
                  <v-progress-circular
                    :value="
                      selectedChartData.detail_ratio.rate_scope_1 ? selectedChartData.detail_ratio.rate_scope_1 : 0
                    "
                    color="#A9A04B"
                    size="22"
                    width="2"
                    rotate="270"
                  ></v-progress-circular>
                </div>

                <div class="percent-block" :style="getStylePercentBlock">
                  <div class="data">{{ selectedChartData.detail_ratio.rate_scope_1 | numberFormat }}</div>

                  <div class="unit" v-show="selectedChartData.detail_ratio.rate_scope_1">%</div>
                </div>
              </div>
              <div class="right-icon" @click="goToDetail(1)" @mouseover="right2 = true" @mouseleave="right2 = false">
                <img v-if="!right2" width="18" src="img/icons/right.svg" alt="" />
                <img v-else width="18" src="img/icons/rightHover.svg" alt="Right" />
              </div>
            </div>
          </div>
        </div>
        <v-divider class="devider-my-8" v-if="isDialog"></v-divider>
        <div>
          <!-- SCOPE 2 -->
          <div class="scope">
            <div class="content">
              <div class="color" :style="{ 'background-color': scope2Color }"></div>
              <div class="name scope2">{{ $t('dashboard_main.label_scope') }} 2</div>
            </div>
            <div class="main-data" :style="getStyleScopeMainData">
              <div class="scope-data scope2" :style="getStyleScope">
                <span class="data" :style="getStyleScopeData">{{
                  getEmissionData(selectedChartData.emission_scope2)
                }}</span>
                <span class="unit" style="margin-bottom: -5px !important">{{ getUnitName }}</span>
              </div>
              <div class="values-data" :style="getStyleValuesData">
                <div v-if="!isGetAllFilter" class="scope-ratio" :style="getStyleScopeRatio">
                  <div class="pie-chart-unit">
                    <v-progress-circular
                      :value="
                        selectedChartData.detail_ratio.rate_scope_2 ? selectedChartData.detail_ratio.rate_scope_2 : 0
                      "
                      color="#A9A04B"
                      size="22"
                      width="2"
                      rotate="270"
                    ></v-progress-circular>
                  </div>

                  <div class="percent-block" :style="getStylePercentBlock">
                    <div class="data">{{ selectedChartData.detail_ratio.rate_scope_2 | numberFormat }}</div>
  
                    <div class="unit" v-show="selectedChartData.detail_ratio.rate_scope_2">%</div>
                  </div>
                </div>
                <div class="right-icon" @click="goToDetail(2)" @mouseover="right3 = true" @mouseleave="right3 = false">
                  <img v-if="!right3" width="18" src="img/icons/right.svg" alt="" />
                  <img v-else width="18" src="img/icons/rightHover.svg" alt="Right" />
                </div>
              </div>
            </div>
          </div>

          <!-- CERTIFICATES -->
          <div class="scope certificates-content" :style="getStyleCertificatesContent">
            <div class="content">
              <div class="certificates-name">{{ $t('dashboard_main.total_emission_scope4_by_credit_type') }}</div>
            </div>
            <div class="main-data">
              <div class="scope-data" style="min-width: fit-content" :style="getStyleScope">
                <span class="data" :style="getStyleScopeData">{{
                  getTotalEmissionWhMJ
                }}</span>
                <span class="unit" style="margin-bottom: -5px !important">{{ getUnitName }}</span>
              </div>
            </div>
          </div>
        </div>

        <v-divider class="devider-my-8" v-if="isDialog"></v-divider>
        <!-- SCOPE 3 -->
        <div v-if="isEnabledScope3" class="scope">
          <div class="content">
            <div class="color" :style="{ 'background-color': scope3Color }"></div>
            <div class="name scope3">{{ $t('dashboard_main.label_scope') }} 3</div>
          </div>
          <div class="main-data" :style="getStyleScopeMainData">
            <div class="scope-data" :style="getStyleScope">
              <span class="data" :style="getStyleScopeData">{{
                getEmissionData(selectedChartData.emission_scope3)
              }}</span>
              <span class="unit" style="margin-bottom: -5px !important">{{ getUnitName }}</span>
            </div>
            <div class="values-data" :style="getStyleValuesData">
              <div v-if="!isGetAllFilter" class="scope-ratio" :style="getStyleScopeRatio">
                <div class="pie-chart-unit">
                  <v-progress-circular
                    :value="
                      selectedChartData.detail_ratio.rate_scope_3 ? selectedChartData.detail_ratio.rate_scope_3 : 0
                    "
                    color="#A9A04B"
                    size="22"
                    width="2"
                    rotate="270"
                  ></v-progress-circular>
                </div>

                <div class="percent-block" :style="getStylePercentBlock">
                  <span class="data">{{ selectedChartData.detail_ratio.rate_scope_3 | numberFormat }}</span>
  
                  <span class="unit" v-show="selectedChartData.detail_ratio.rate_scope_3">%</span>
                </div>
              </div>
              <div class="right-icon" @click="goToDetail(3)" @mouseover="right4 = true" @mouseleave="right4 = false">
                <img v-if="!right4" width="18" src="img/icons/right.svg" alt="" />
                <img v-else width="18" src="img/icons/rightHover.svg" alt="Right" />
              </div>
            </div>
          </div>
        </div>

        <v-divider class="devider-my-8" v-if="isDialog"></v-divider>
        <!-- EMISSION REDUCTIONS -->
        <div class="scope">
          <div class="content" :style="{ maxWidth: isGetAllFilter || isDialog ? 'fit-content' : '100%' }">
            <!-- <div class="color" :style="{ 'background-color': '#7C898C' }"></div> -->
            <div class="name scope4" style="width: 100%">{{ $t('dashboard_main.total_emission_scope4') }}</div>
          </div>
          <div class="main-data">
            <div class="scope-data" :style="getStyleScope">
              <span class="data" :style="getStyleScopeData">{{
                getTotalEmissions4
              }}</span>
              <span class="unit" style="margin-bottom: -5px !important">{{ getUnitName }}</span>
            </div>
            <!-- <div class="scope-ratio" v-if="!isGetAllFilter">
              <div class="pie-chart-unit">
                <v-progress-circular
                  :value="selectedChartData.detail_ratio.rate_scope_4 ? selectedChartData.detail_ratio.rate_scope_4 : 0"
                  color="#A9A04B"
                  size="22"
                  width="2"
                  rotate="270"
                ></v-progress-circular>
              </div>

              <span class="data">{{ selectedChartData.detail_ratio.rate_scope_4 | numberFormat }}</span>

              <span class="unit" v-show="selectedChartData.detail_ratio.rate_scope_4">%</span>

              <div class="right-icon" @click="goToDetail(4)" @mouseover="right5 = true" @mouseleave="right5 = false">
                <img v-if="!right5" width="18" src="img/icons/right.svg" alt="" />
                <img v-else width="18" src="img/icons/rightHover.svg" alt="Right" />
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'mobile';
@import 'desktop';
</style>

<script>
import { mapGetters, mapState } from 'vuex';
import { SCOPE_1_COLOR, SCOPE_2_COLOR, SCOPE_3_COLOR } from '../../../chart-color';
import ChartYearlyPie from './pie-chart.vue';
import { ROUTES } from '@/router/constants';
import { TABLET_WIDTH } from '@/constants/screen-size';
import { getTitle, getRatioValue, chartTitleMonthYear } from '@/utils/getTextWithCondition';
import { addThoundSandCommaWithTwoDigit } from '@/utils/convertNumber';
import { DASHBOARD_DISPLAY_UNIT } from '@/constants/dashboard';
import { formatBigNumber, math, formatValue } from '@/concerns/newRegisterData/wijmo.helper';

export default {
  components: { ChartYearlyPie },
  data() {
    return {
      right1: false,
      right2: false,
      right3: false,
      right4: false,
      right5: false,
      totalEmissions: 0,
      windowWidth: window.innerWidth,
    };
  },
  props: {
    isDialog: {
      type: Boolean,
      default: false,
    },
    isShowContent: {
      type: Boolean,
      default: false,
    },
    selectedChartData: {
      type: Object,
      default: () => {},
    },
    isEnabledScope3: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: Number,
      default: 0,
    },
    branchIds: {
      type: Array,
      default: () => [],
    },
    selectedChartDataWithCompareValues: {
      type: Object,
      default: () => {},
    },
    titleEachType: {
      type: String,
      default: null,
    },
    isGetAllFilter: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    selectedChartData: {
      handler() {
        this.totalEmissions = 0;
        if (this.selectedChartData.total_emission)
          this.totalEmissions = this.isEnabledScope3
            ? this.selectedChartData.total_emission
            : this.selectedChartData.total_emission - this.selectedChartData.emission_scope3;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      contractor: 'userData/getContractor',
    }),
    ...mapState('dashboard', ['selectedEmissionsByPeriodDashboard']),
    ...mapState('settingsDashboard', ['itemsSettings']),
    getTotalEmissionWhMJ() {
      return this.getEmissionData(this.selectedChartData.s4Original?.total_emission_Wh_MJ)
    },
    getTotalEmissions4() {
      return this.getEmissionData(this.selectedChartData.s4Original?.total_emission)
    },
    getTotalEmissions() {
      return this.calculateEmissions(this.totalEmissions);
    },
    getUnitName() {
      return this.itemsSettings[this.contractor]?.unitName || 't-CO2';
    },
    getStylePercentBlock() {
      return {
        marginTop: this.isDialog && !this.isGetAllFilter ? '-1px' : '',
      };
    },
    getStyleCertificatesContent() {
      return {
        flexDirection: this.isDialog ? 'column !important' : '',
        alignItems: this.isDialog ? 'flex-start' : '',
      };
    },
    getStyleScopeMainData() {
      return {
        alignItems: this.isDialog && !this.isGetAllFilter ? '' : 'flex-start',
        gap: this.isFilterAndNotDialogScreen ? '8px' : !this.isGetAllFilter ? '16px' : '8px',
        width: this.isDialog && !this.isGetAllFilter ? '100%' : '',
      };
    },
    getStyleScopeData() {
      return {
        fontSize: this.isDialog ? '20px' : '',
      };
    },
    getStyleRatioHasStatusCompare() {
      return {
        flex: '1',
        'border-left': !this.isDialog && this.getStatusCompare ? '1px solid rgba(42, 42, 48, 0.10)' : '',
        'padding-left': !this.isDialog ? '16px' : '',
      };
    },
    getStyleSummaryBranch() {
      return {
        paddingLeft: this.isDialog ? 'unset' : '16px',
        flex: !this.isDialog ? '1' : '',
        borderLeft: this.isDialog ? 'unset' : '1px solid rgba(42, 42, 48, 0.1)',
      };
    },
    getStyleStateWrapTotalAmount() {
      return {
        display: this.isGetAllFilter || this.isDialog ? 'flex' : 'block',
        alignItems: this.isDialog ? 'flex-end' : '',
        maxWidth: !this.isDialog && !this.isGetAllFilter ? 'calc(50% - 16px / 2)' : '',
      };
    },
    getStyleScope() {
      return {
        paddingBottom: '0',
        borderBottom: 'unset',
        flex: !this.isGetAllFilter && this.isDialog ? '1' : '',
      };
    },
    getStyleRatioContainer() {
      return {
        justifyContent: this.isDialog ? 'space-between' : '',
        flexWrap: this.isDialog ? 'nowrap' : '',
        flex: !this.isDialog ? '1' : '',
      };
    },
    getStyleStateSummary() {
      return {
        flexDirection: this.isDialog ? 'column' : 'row',
        gap: this.isDialog ? '24px' : '',
        maxWidth: !this.isDialog ? 'calc(50% - 16px / 2)' : '',
      };
    },
    getStyleScopeDetail() {
      return {
        display: 'grid',
        'grid-template-columns': !this.isDialog ? '1fr 1fr' : '',
        gap: !this.isDialog ? '8px' : '0',
      };
    },
    getStyleHeader() {
      return {
        flexDirection: this.isDialog ? 'column' : 'row',
        gap: this.isDialog ? '24px' : '16px',
      };
    },
    getTitleEachTotalEmission() {
      return this.titleEachType || this.$t('dashboard_main.label_scope_type_discharge');
    },
    getStyleObject() {
      return {
        flex: !this.isDialog && this.isHasBranchIds ? '0 0 50%' : '',
      };
    },
    getStyleRatio() {
      return {
        'border-left': !this.isDialog ? '1px solid #dadae1' : '',
        flex: this.isDialog ? '1' : '',
        'padding-left': !this.isDialog ? '16px' : '',
        maxWidth: this.getStatusCompare && !this.isDialog ? 'calc(50% - 16px / 2)' : '',
      };
    },
    getStyleScopeRatio() {
      return {
        'justify-content': this.isDialog ? 'unset' : '',
      };
    },
    getStyleValuesData() {
      return {
        'align-items': this.isDialog ? 'flex-start' : '',
        flex: !this.isGetAllFilter ? '1' : '',
      };
    },
    getStyleChartDetail() {
      return {
        flexDirection: this.isDialog ? 'column' : '',
        gap: this.isFilterAndNotDialogScreen ? '0' : '',
      };
    },
    getEmissionData() {
      return (number) => {
        return this.calculateEmissions(number);
      };
    },
    getStatusCompare() {
      let statusCompare = false;
      switch (this.tab) {
        case 0: // year
        case 1: // half
          statusCompare = false;
          break;
        case 2: // quarter
          statusCompare = this.selectedEmissionsByPeriodDashboard.checkBoxQuarterYearStatus;
          break;
        case 3: // month year
          statusCompare = this.selectedEmissionsByPeriodDashboard.checkBoxMonthYearStatus;
          break;
        default:
          break;
      }
      return statusCompare;
    },
    getTitleCompare() {
      if (this.tab === 2) {
        // tab quarter
        return this.$t('dashboard_main.label_year_on_year_comparison');
      }
      return this.$t('dashboard_main.label_compared_to_the_same_month_last_year'); // tab month
    },
    ratioValueCompareEmission() {
      const ratio = getRatioValue(this.tab, this.selectedChartData?.detail_ratio, true);
      return ratio ? addThoundSandCommaWithTwoDigit(ratio, '', false, true) : '-';
    },
    statusIconCompare() {
      if (this.selectedChartData?.detail_ratio.up === 1) {
        return {
          src: 'img/icons/up.svg',
          alt: 'Up',
        };
      } else {
        return {
          src: 'img/icons/down.svg',
          alt: 'Down',
        };
      }
    },
    ratioValueEmission() {
      const ratio = getRatioValue(this.tab, this.selectedChartData?.detail_ratio);
      return ratio ? addThoundSandCommaWithTwoDigit(ratio, '', false, true) : '-';
    },
    getTitleRatio() {
      return getTitle(this.tab);
    },
    chartTitle() {
      const subTitle = this.$t('dashboard_main.label_total_emissions');
      return chartTitleMonthYear(
        this.tab,
        this.selectedChartData.year,
        this.selectedChartData.order,
        this.selectedChartData.month,
        subTitle,
      );
    },
    status() {
      if (this.selectedChartData?.detail_ratio.up_same === 1) {
        return {
          src: 'img/icons/up.svg',
          alt: 'Up',
        };
      } else {
        return {
          src: 'img/icons/down.svg',
          alt: 'Down',
        };
      }
    },
    scopeDataForPieChart() {
      let scopeData = [
        {
          brand: `${this.$t('dashboard_main.label_scope')} 1`,
          sales: this.selectedChartData.period_emission_scope1,
        },
        {
          brand: `${this.$t('dashboard_main.label_scope')} 2`,
          sales: this.selectedChartData.period_emission_scope2,
        },
      ];
      if (this.isEnabledScope3) {
        scopeData.push({
          brand: `${this.$t('dashboard_main.label_scope')} 3`,
          sales: this.selectedChartData.period_emission_scope3,
        });
      }
      return scopeData;
    },
    scope1Color() {
      return this.scopeDataForPieChart[0]?.sales < 0 ? '#404D50' : SCOPE_1_COLOR;
    },
    scope2Color() {
      return this.scopeDataForPieChart[1]?.sales < 0 ? '#99A6A9' : SCOPE_2_COLOR;
    },
    scope3Color() {
      return this.scopeDataForPieChart[2]?.sales < 0 ? '#7C898C' : SCOPE_3_COLOR;
    },
    isHasBranchIds() {
      return !this.isGetAllFilter;
    },
    isTabletScreen() {
      return window.innerWidth <= TABLET_WIDTH;
    },
    isFilterAndNotDialogScreen() {
      return !this.isGetAllFilter && this.windowWidth <= 1200 && !this.isDialog;
    },
  },
  methods: {
    goToDetail(scope) {
      let query = {
        scope: scope,
        type: this.tab === 0 ? 1 : this.tab === 3 ? 2 : this.tab + 2,
      };
      switch (this.tab) {
        case 0:
          query = {
            ...query,
            year: this.selectedChartData.year,
          };
          break;
        case 1:
        case 2:
          query = {
            ...query,
            year: this.selectedChartData.year,
            order: this.selectedChartData.order,
          };
          break;
        default:
          query = {
            ...query,
            year: this.selectedChartData.year,
            month: this.selectedChartData.month,
          };
          break;
      }
      this.$router.push({
        path: ROUTES.EMISSIONS + '/' + ROUTES.EMISSIONS_BY_BASE_PERIOD,
        query: query,
      });
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    calculateEmissions(emission, isAxisYChart) {
      if (emission === undefined || emission === null || isNaN(emission)) {
        return '-';
      }

      const unit = this.itemsSettings[this.contractor]?.selectedUnit;
      const isKgCO2 = unit === DASHBOARD_DISPLAY_UNIT.kgCO2;
      const valueEmission = math.bignumber(formatValue(emission));
      const emissionValueResult = isKgCO2
        ? formatBigNumber(math.multiply(valueEmission, 1000), isAxisYChart ? 0 : 50)
        : formatBigNumber(valueEmission, isAxisYChart ? 0 : 50);

      return addThoundSandCommaWithTwoDigit(emissionValueResult, '', false, true);
    },
  },
};
</script>
