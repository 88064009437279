<template>
  <div class="line-chart-wrapper quality-score-chart">
    <!-- <div class="line-chart-actions-top spacer" style="height: 24px;">
    </div> -->
    <div class="line-chart-actions-top">
      <button 
        class="chart-btn-action btn-prev" 
        :disabled="isDisabledPrev"
        @click="prev"
        @mouseover="handleMouseOverPrevBtn"
        @mouseleave="isMouseOverPrev = false"
        @mousedown="isBlurPrev = true"
        @mouseup="isBlurPrev = false"
        @touchstart="handleTouchStartPrevBtn"
        @touchend="handleTouchEndPrevBtn"
      >
        <img :src="prevIconUrl || prevIcon" alt="Prev" />
      </button>
      <button 
        class="chart-btn-action btn-next" 
        :disabled="isDisabledNext"
        @click="next"
        @mouseover="handleMouseOverNextBtn"
        @mouseleave="isMouseOverNext = false"
        @mousedown="isBlurNext = true"
        @mouseup="isBlurNext = false"
        @touchstart="handleTouchStartNextBtn"
        @touchend="handleTouchEndNextBtn"
      >
        <img :src="nextIconUrl || nextIcon" alt="Next" />
      </button>
    </div>
    
    <div class="chart line-chart line-chart-year chart-scroll">
      <wj-flex-chart 
        ref="chart"
        :key="key"
        chartType="LineSymbols" 
        :dataLabel="false" 
        :itemsSource="data"
        :initialized="onChartInitialized" 
        :tooltipContent="customTooltip" 
        :rendered="onChartRendered" 
        :palette="getColor"
      >
        <wj-flex-chart-animation :initialized="initializeAnimation" :easing="easing" :duration="500"></wj-flex-chart-animation>
      </wj-flex-chart>
      <div class="selected-div" :style="selectedDivStyle"></div>
      <div class="hover-div" :style="hoverDivStyle"></div>
    </div>
    <div class="line-chart-actions-bottom">
      <button 
        class="chart-btn-action btn-prev" 
        :disabled="isDisabledPrev"
        @click="prev"
        @mouseover="handleMouseOverPrevBtn"
        @mouseleave="isMouseOverPrev = false"
        @mousedown="isBlurPrev = true"
        @mouseup="isBlurPrev = false"
        @touchstart="handleTouchStartPrevBtn"
        @touchend="handleTouchEndPrevBtn"
      >
        <img :src="prevIconUrl || prevIcon" alt="Prev" />
      </button>
      <button 
        class="chart-btn-action btn-next" 
        :disabled="isDisabledNext"
        @click="next"
        @mouseover="handleMouseOverNextBtn"
        @mouseleave="isMouseOverNext = false"
        @mousedown="isBlurNext = true"
        @mouseup="isBlurNext = false"
        @touchstart="handleTouchStartNextBtn"
        @touchend="handleTouchEndNextBtn"
      >
        <img :src="nextIconUrl || nextIcon" alt="Next" />
      </button>
    </div>
    <!-- <div style="height: 10px"></div> -->
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import '@mescius/wijmo.vue2.chart';
import '@mescius/wijmo.vue2.chart.animation';
import '@mescius/wijmo.styles/wijmo.css';
import { Series } from '@mescius/wijmo.chart';
import { TABLET_WIDTH } from '@/constants/screen-size';
import { PALETTE_COLOR } from '@/constants/products/define-data';
import { formatValue, formatBigNumber, $_helper_isNumberType } from '@/concerns/newRegisterData/wijmo.helper';
import { getTitle, chartTitleMonthYear } from '@/utils/getTextWithCondition';
import { addThoundSandCommaWithTwoDigit } from '@/utils/convertNumber';
const MAX_CHART_ITEMS = 6;
const TYPE_CHART = 'year';
const LABEL_PADDING_X = 16;
const LABEL_PADDING_Y = 26;
export default {
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    tab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      easing: 'EaseInQuad',
      animationMode: 'Point',
      min: 0,
      max: 500,
      axisXItemsSource: [],
      chartValue: [],
      selectedTimeRange: '',
      hoverTimeRange: '',
      isDisabledPrev: true,
      isDisabledNext: false,
      isMouseOverNext: false,
      isMouseOverPrev: false,
      isBlurNext: false,
      isBlurPrev: false,
      isFirstRender: true,
      transitionWidth: '100%',
      footerWidth: 0,
      minWidth: 0,
      data: [],
      breakDownData: [],
      selectionStart: null,
      selectionEnd: null,
      chart: null,
      selectedDivStyle: {},
      hoverDivStyle: {},
      previousRectElement: null,
      palette: PALETTE_COLOR,
      rawData: [],
      key: 0,
      pageCurrent: 0,
      nextIconUrl: '',
      prevIconUrl: '',
    };
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    ...mapState('pcaf', ['dashboard']),
    nextIcon() {
      if (this.isDisabledNext) {
        this.isBlurNext = false;
        this.isMouseOverNext = false;
        return 'img/icons/next-disabled.svg';
      }

      if (this.isBlurNext) {
        return 'img/icons/next.svg';
      }

      if (this.isMouseOverNext) {
        return 'img/icons/next-hover.svg';
      }

      return 'img/icons/next.svg';
    },
    prevIcon() {
      if (this.isDisabledPrev) {
        this.isBlurPrev = false;
        this.isMouseOverPrev = false;
        return 'img/icons/prev-disabled.svg';
      }

      if (this.isBlurPrev) {
        return 'img/icons/prev.svg';
      }

      if (this.isMouseOverPrev) {
        return 'img/icons/prev-hover.svg';
      }

      return 'img/icons/prev.svg';
    },
    hasNegativeNumber() {
      const existNegativeNumber = this.chartValue.find(
        (item) => item.emission_scope1 < 0 || item.emission_scope2 < 0 || item.emission_scope3 < 0,
      );
      return existNegativeNumber ? true : false;
    },
    getColor() {
      return ['rgba(148, 136, 16, 1)'];
    }
  },
  destroyed() {
    this.handleOffEvent()
  },
  methods: {
    ...mapActions('pcaf', ['updateQualityScoreLineChart']),
    updateSelected() {
      if(window.innerWidth < TABLET_WIDTH) {
        this.selectedDivStyle.display = 'none';
        return;
      }
      let index = this.findIndexSelected();
      const selectedDuration = this.breakDownData[index];
      let indexColumn = this.data.findIndex((item) => item?.year && item?.year === selectedDuration?.year);
      if (this.pageCurrent !== 0) {
        indexColumn = indexColumn - 1;
      }
      if (indexColumn >= 0) {
        this.$emit('selectChart', this.breakDownData[index], window.innerWidth < TABLET_WIDTH);
        this.selectedDivStyle = this.updateStylesDiv(indexColumn);
        this.selectedDivStyle.backgroundColor = '#e3eded';
        this.addSelectedClass(indexColumn);
      } else {
        this.selectedDivStyle.display = 'none';
        return;
      }
    },
    statusBtn() {
      if (this.pageCurrent === 0) {
        this.isDisabledNext = this.breakDownData.length <= MAX_CHART_ITEMS;
        this.isDisabledPrev = true;
      } else {
        this.isDisabledNext = this.pageCurrent + MAX_CHART_ITEMS >= this.breakDownData.length;
        this.isDisabledPrev = false;
      }
    },
    findIndexSelected() {
      let findIndex = -1;
      if (this.tab === 0) {
        // year tab
        const oldSelectedYear = this.$store.state.pcaf.dashboard?.qualityScoreLineChart?.selectedYear;
        findIndex = this.breakDownData.findIndex((item) => item.year === oldSelectedYear);
        const currentYear = new Date().getFullYear();
        if (findIndex < 0) {
          findIndex = this.breakDownData.length -1;
        }
      } else if (this.tab === 1) {
        // half year tab
        const oldSelectedHalfYear = this.$store.state.pcaf.dashboard?.qualityScoreLineChart?.selectedHalfYear;
        findIndex = this.breakDownData.findIndex((item) => {
          return oldSelectedHalfYear === item?.year;
        });
        if (findIndex < 0) {
          findIndex = this.breakDownData.length -1;
        }
      } else if (this.tab === 2) {
        // quarter year tab
        const oldSelectedQuarterYear = this.$store.state.pcaf.dashboard?.qualityScoreLineChart?.selectedQuarterYear;
        findIndex = this.breakDownData.findIndex((item) => {
          return oldSelectedQuarterYear === item?.year;
        });
        if (findIndex < 0) {
          findIndex = this.breakDownData.length -1;
        }
      } else if (this.tab === 3) {
        // month tab
        const oldSelectedMonthYear = this.$store.state.pcaf.dashboard?.qualityScoreLineChart?.selectedMonthYear;
        findIndex = this.breakDownData.findIndex((item) => {
          return oldSelectedMonthYear === item?.year;
        });
        if (findIndex < 0) {
          findIndex = this.breakDownData.length -1;
        }
      }
      return findIndex;
    },
    selectedFirstChartItem() {
      let findIndex = this.findIndexSelected();
      if (findIndex >= 0) {
        const item = this.breakDownData[findIndex];
        this.$emit('updateSelectedChartData', item, window.innerWidth < TABLET_WIDTH, false);
      } else {
        const latestDurationIndex = this.breakDownData.length - 1;
        findIndex                 = latestDurationIndex;
        const item                = this.breakDownData[latestDurationIndex];
        this.$emit('updateSelectedChartData', item, window.innerWidth < TABLET_WIDTH, false);
      }
      // set page current
      this.pageCurrent = findIndex - MAX_CHART_ITEMS < 0 ? 0 : findIndex - MAX_CHART_ITEMS + 1;
      // if not first page
      if(this.pageCurrent !== 0) {

        this.data = [...this.breakDownData];
        const indexToCalc =  findIndex - MAX_CHART_ITEMS;

        const dataKeys = Object.keys(this.data[0]).map(item => {
          return item !== TYPE_CHART ? item : ''
        }).filter(item => item !== '');
        const mediumScore = dataKeys?.reduce((result, key) => {
          result[key.toString()] = (this.data[indexToCalc][key] + this.data[indexToCalc + 1][key]) / 2;
          return result;
        }, {})

        const quantityCut = findIndex - MAX_CHART_ITEMS + 1
        this.data.splice(0, quantityCut);

        // add midpoint into first index arr
        this.data.unshift(mediumScore);
        this.data = [...this.data.slice(0, MAX_CHART_ITEMS + 1)]

        this.chart.axisX.max = MAX_CHART_ITEMS;
        this.chart.axisX.min = 0;

      } else {
        this.data = [...this.breakDownData.slice(0, MAX_CHART_ITEMS)];
        this.chart.axisX.max = MAX_CHART_ITEMS - 1;
        this.chart.axisX.min = undefined;
      }
      this.statusBtn();
      this.addClassNameToRemoveSymbol();
      let totalsEmission = this.data.map(obj => +obj?.total_dq_ratio)
      this.updateAxisYChart(totalsEmission);
    },
    initializeAnimation(animation){
      this.chartAnimation = animation;
      this.chartAnimation.animationMode = this.animationMode;
    },
    onMouseOver(item) {
      this.hoverTimeRange = item.time_range;
    },
    onMouseLeave() {
      this.hoverTimeRange = '';
    },
    prepareChartDataValue() {
      if(this.breakDownData.length < MAX_CHART_ITEMS) { 
        this.pageCurrent = 0 ;
      }
      let getTotalEmissionsAtIndex0 = [];
      if (this.pageCurrent !== 0) {
        this.data = this.breakDownData.slice()
        let index = this.pageCurrent - 1;

        const dataKeys = Object.keys(this.data[0]).map(item => {
          return item !== 'year' ? item : ''
        }).filter(item => item !== '');
        const mediumScore = dataKeys?.reduce((result, key) => {
          result[key.toString()] = (this.data[index][key] + this.data[index + 1][key]) / 2;
          if ($_helper_isNumberType(key.toString())) {
            getTotalEmissionsAtIndex0.push((this.data[index][key] + this.data[index + 1][key]) / 2);
          }
          return result;
        }, {})
        
        this.data.splice(0, this.pageCurrent);
        this.data.unshift(mediumScore);

        //get length 5 data chart
        this.data = [...this.data.slice(0, MAX_CHART_ITEMS + 1)]
        
        this.chart.axisX.min = 0;
        this.chart.axisX.max = MAX_CHART_ITEMS;

      } else {
        this.chart.axisX.min = undefined;
        this.chart.axisX.max = MAX_CHART_ITEMS - 1;
       
        //get length 4 data chart
        this.data = [...this.breakDownData.slice(0, MAX_CHART_ITEMS)]

      }

      this.addClassNameToRemoveSymbol();
      let totalsEmission = this.data.map(obj => +obj?.total_dq_ratio)
      this.updateAxisYChart(totalsEmission);
    },
    addClassNameToRemoveSymbol() {
      if (this.chart) {
        const el = this.$refs.chart.$el;
        if (el.classList.contains('remove-symbol-size')) {
          el.classList.remove('remove-symbol-size');
        }
        if (this.pageCurrent !== 0) {
          if (!el.classList.contains('remove-symbol-size')) {
            el.classList.add('remove-symbol-size');
          }
        }
      }
    },
    handleMouseOverNextBtn() {
      this.isMouseOverNext = true;
      this.nextIconUrl = '';
    },
    handleTouchStartNextBtn() {
      this.prevIconUrl = '';
      this.nextIconUrl = 'img/icons/next-hover.svg';
    },
    handleTouchEndNextBtn() {
      setTimeout(() => {
        if (this.isDisabledNext) {
          this.nextIconUrl = 'img/icons/next-disabled.svg';
        } else {
          this.nextIconUrl = 'img/icons/next.svg';
        }
      }, 200);
    },
    next() {
      this.prevIconUrl = '';
      if (this.pageCurrent + MAX_CHART_ITEMS >= this.breakDownData.length) {
        this.isDisabledNext = true;
        this.isDisabledPrev = false;
        return;
      }

      this.isDisabledNext = false;
      this.pageCurrent = this.pageCurrent + 1;
      if (this.pageCurrent + MAX_CHART_ITEMS >= this.breakDownData.length) {
        this.isDisabledNext = true;
      }
      this.prepareChartDataValue();
      if (this.pageCurrent > 0) {
        this.isDisabledPrev = false;
      } else {
        this.isDisabledPrev = true;
      }
    },
    handleMouseOverPrevBtn() {
      this.isMouseOverPrev = true;
      this.prevIconUrl = '';
    },
    handleTouchStartPrevBtn() {
      this.nextIconUrl = '';
      this.prevIconUrl = 'img/icons/prev-hover.svg';
    },
    handleTouchEndPrevBtn() {
      setTimeout(() => {
        if (this.isDisabledPrev) {
          this.prevIconUrl = 'img/icons/prev-disabled.svg';
        } else {
          this.prevIconUrl = 'img/icons/prev.svg';
        }
      }, 200);
    },
    prev() {
      this.nextIconUrl = '';
      if (this.pageCurrent <= 0) {
        this.isDisabledPrev = true;
        return;
      }
      this.isDisabledPrev = false;
      this.pageCurrent = this.pageCurrent - 1;
      this.prepareChartDataValue();
      if (this.pageCurrent <= 0) {
        this.isDisabledPrev = true;
      }

      if (this.pageCurrent + MAX_CHART_ITEMS < this.breakDownData.length) {
        this.isDisabledNext = false;
      }
    },
    transferData(data) {
      const dataChartPrepare = data?.map(product => {
        const durationTime = moment(product.start_at, 'YYYY/MM').format('YYYY/M') + ' - ' + moment(product.end_at, 'YYYY/MM').format('YYYY/M');
        let dataItem = {};
        if(!product?.product_master || product?.product_master.length === 0) {
          dataItem.total_dq_ratio = this.formatNumber(product.total_dq_ratio);
        }
        else {
          dataItem = product.product_master?.reduce((result, item, index) => {
            result[item?.id] = this.formatNumber(item?.total_dq_ratio);
            dataItem[result.name] = this.formatNumber(item?.total_dq_ratio);

            result['item' + item?.id] = item.name;
            return result;
          }, {});
        }
        dataItem['year'] = durationTime;
        dataItem['product'] = product;

        // dataItem['start_at'] = product.start_at;
        // dataItem['end_at'] = product.end_at;
        // dataItem['ratio'] = product.ratio;
        // dataItem['total_dq_ratio'] = product.total_dq_ratio;
        // dataItem['up'] = product.up;
        return dataItem;
      });
      return dataChartPrepare;
    },
    rotateLabelXAxis() {
      if (this.chart) {
        this.chart.axisX.labelAngle = 0;
        this.chart.axisX.labelPadding = LABEL_PADDING_Y;
      }
    },
    onChartInitialized(flexchart) {
      this.chart = flexchart;
      flexchart.bindingX = 'year';
      flexchart.legend.position = 0; // remove chart legend
      flexchart.symbolSize = 8;
      flexchart.axisX.majorTickMarks = false;
      flexchart.axisY.axisLine = true;
      flexchart.axisY.majorGrid = false;
      flexchart.axisX.max = MAX_CHART_ITEMS - 1;
      const chartEl = flexchart.hostElement;
      this.handleEventChart(chartEl);
      flexchart.axisY.labelPadding = LABEL_PADDING_X;
      flexchart.axisX.labelPadding = LABEL_PADDING_Y;
      this.rotateLabelXAxis();
      const el = this.$refs.chart.$el;
      if (this.pageCurrent && this.pageCurrent !== 0) {
        el.classList.add('remove-symbol-size');
      }
      this.updateInfoChart()  
    },
    handleEventChart(chartEl) {
      chartEl.addEventListener('mouseleave', this.mouseLeaveEvent);
      chartEl.addEventListener('click', this.clickEvent);
      chartEl.addEventListener('mousemove', this.hoverEvent);
    },
    handleOffEvent() {
      const chartEl = this.chart.hostElement;

      if (chartEl) {
        chartEl.removeEventListener('mouseleave', this.mouseLeaveEvent);
        chartEl.removeEventListener('click', this.clickEvent);
        chartEl.removeEventListener('mousemove', this.hoverEvent);
      }
    },
    formatNumber(num) {
      return num === 0 ? num : Number(formatValue(num))
    },
    updateInfoChart() {
      const newSeriesConfigurations = [{ binding: 'total_dq_ratio', name: 'total_dq_ratio' }]
      newSeriesConfigurations.forEach((item) => {
        this.chart.series.push(new Series(item));
      });
      let totalsEmission = [...this.breakDownData.slice(0, MAX_CHART_ITEMS)].map(obj => obj?.total_dq_ratio)
      this.updateAxisYChart(totalsEmission);
    },
    onChartRendered(flexchart, args) {
      const isExistRects = flexchart.axisX._rects;
      if(isExistRects) {
        this.updateSelected(); 
        this.updateYAxisRange(); 
      }
    },
    updateIndexSelectedToSore(item) {
      let keySelected = 'selectedYear';
      if (this.tab === 1) {
        // quarter-year
        keySelected = 'selectedHalfYear';
      } else if (this.tab === 2) {
        // half-year
        keySelected = 'selectedQuarterYear';
      } else if (this.tab === 3) {
        // month-year
        keySelected = 'selectedMonthYear';
      }
      this.updateQualityScoreLineChart({
        ...this.dashboard.qualityScoreLineChart,
        [keySelected]: item?.year,
      });
    },
    mouseLeaveEvent(e) {
      this.hoverDivStyle = {
        ...this.hoverDivStyle,
        display: 'none'
      }
    },
    clickEvent(e) {
    
      const chart = this.chart;
      const hitTestInfo = chart.hitTest(e.pageX, e.pageY);
      let index = hitTestInfo.pointIndex;
      if (this.pageCurrent !== 0) {
        if(index === 0) {
          return;
        }
        index = index - 1;
      }
      this.$emit('updateSelectedChartData', hitTestInfo.item, window.innerWidth < TABLET_WIDTH, true);
      this.addSelectedClass(index);
      this.updateIndexSelectedToSore(hitTestInfo.item);    

      if(window.innerWidth < TABLET_WIDTH) {
        this.selectedDivStyle.display = 'none';
        return;
      }

      this.selectedDivStyle = this.updateStylesDiv(index);
      this.selectedDivStyle.backgroundColor = '#e3eded';
    },
    hoverEvent(e) {
      if(window.innerWidth < TABLET_WIDTH) {
        this.hoverDivStyle.display = 'none';
        return;
      }
      const chart = this.chart;
      const hitTestInfo = chart.hitTest(e.pageX, e.pageY);
      if (hitTestInfo.pointIndex === undefined) return;
      let index = hitTestInfo.pointIndex;

      if (this.pageCurrent !== 0) {
        index = index - 1;
      }
      this.hoverDivStyle = this.updateStylesDiv(index);
    },
    updateStylesDiv(index) {
      const chart = this.chart;
      const chartRect = chart._rectChart;
      const xAxis = chart.axisX;
      let plusWidth = 20;
      let minusLeft = 5;
      if(this.tab === 1) {
        plusWidth = plusWidth - 10;
        minusLeft = 0;
      }
      return  {
        position: 'absolute',
        bottom:  LABEL_PADDING_X - LABEL_PADDING_Y  + 'px',
        left: xAxis._rects[index]?.left - plusWidth + minusLeft - 14 + 'px',
        width: xAxis._rects[index]?.width + plusWidth  + 'px',
        height: chartRect.height - 4 + 'px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.1s ease-in-out',
        border: '1px dashed #A9A04B',
      }
    },
    formatNumberCustome(num) {
      if(num === 0) {
        return '-'
      }
      return addThoundSandCommaWithTwoDigit(num, '', false, true);
    },
    customTooltip(ht) {
      const item = ht.item;
      if (!item.year || window.innerWidth < TABLET_WIDTH) return '';
      return `<div style='display: flex;gap: 4px;flex-direction: column;padding: 4px; min-width: 131px; width: 100%;font-family: 'Source Han Sans';'>
                <div style="color: #404D50;letter-spacing: 0.03em;line-height: 18px;font-size: 11px;font-weight: 700;">${this.chartTitle(
                  item,
                )}</div>
                <div style="display: flex;justify-center: space-between; align-items: center; gap: 8px;">
                  <div style="display: flex; align-items: flex-start; flex-direction: column; gap: 8px;">
                    <span style='font-weight: 700;font-size: 11px;line-height: 18px;letter-spacing: 0.03em;color: #404D50'>${this.$t("dashboard_main.label_tooltip_ratio_score")}</span>
                    <span style='font-weight: 700;font-size: 11px;line-height: 18px;letter-spacing: 0.03em;color: #404D50'>${this.ratioTitle()}</span>
                  </div>
                  <div style="display: flex; align-items: flex-start; flex-direction: column; gap: 8px">
                    <span style='font-weight: 500;font-size: 11px;line-height: 18px;letter-spacing: 0.03em;color: #404D50;'>${this.formatNumberCustome(
                      item?.total_dq_ratio,
                    )}</span>
                    <span style='font-weight: 500;font-size: 11px;line-height: 18px;letter-spacing: 0.03em;color: #404D50;'>${this.changeYearEveryValue(
                      item,
                    )}</span>
                  </div>  
                </div>
              </div>`;
    },
    chartTitle(item) {
      return chartTitleMonthYear(this.tab, item.yearTime, item.order, item.month)
    },
    ratioTitle() {
      return getTitle(this.tab);
    },
    changeYearEveryValue (item) {
      let ratioValue = item?.ratio ?? '-';
      let unit = '';
      if(ratioValue !== '-') {
        unit = '%';
        if(item?.up === 1) {
          unit+='↑';
        }else {
          unit+='↓';
        }
      }
      return ratioValue + unit;
    },
    updateYAxisRange() {

    },
    addSelectedClass(index) {
      const axisXElement = this.$el.querySelectorAll('.wj-axis-x .wj-label');
      for (let i = 0; i < axisXElement.length; i++) {
        axisXElement[i]?.classList.remove('active-select');
      }
      axisXElement[index]?.classList.add('active-select');
    },
    updateAxisYChart(dataCalc) {
      const calcAxisY = [0, 5, 0.625];
      this.chart.axisY.majorUnit = calcAxisY[2];
      this.chart.axisY.min = calcAxisY[0];
      this.chart.axisY.max = calcAxisY[1];
      this.max = calcAxisY[1];
  
      this.chart.axisY.itemFormatter = (engine, label) => {
        let value = label.val.toFixed(2);
        if(value === '0.00') {
          value = 0;
        }
        label.text = value.toString();
        return label;
      };
    },
    handleResize() {
      this.updateSelected();
      this.rotateLabelXAxis();
    }
  },
  watch: {
    productsMasterList() {
      this.chart.refresh(true);
      this.innitDataVisualize();
    },
    branchIds() {
      this.innitDataVisualize();
    },
    tab() {
      this.nextIconUrl = '';
      this.prevIconUrl = '';
    },
    chartData: {
        handler(val) {
          if(this.chartData.length) {
            const dataTransfer = this.chartData.map(item => {
            let duration = item.year.toString();
            return {
              ...item,
              total_dq_ratio: +item.total_dq_ratio,
              year: duration
            }
            });
            this.data = dataTransfer;
            this.breakDownData = dataTransfer;
            // this.prepareChartDataValue();
            this.statusBtn();
            this.selectedFirstChartItem();
          }
        },
        deep: true
        // immediate: true,
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/views/dashboard/components/emissions-by-period/yearly/chart-2/index.scss';
.line-chart-actions-top {
  margin-bottom: 16px;
}

.line-chart-actions-bottom .btn-next {
  position: absolute;
  right: -77px !important;
  bottom: 27px;
}
.line-chart-wrapper {
  width: calc(100% - 326px);
  position: relative;
  margin-top: 0 !important;
  .line-chart {
    margin-bottom: 10px;
  }
}
@media (max-width: 1448px) {
  .line-chart-wrapper {
    width: calc(100% - 258px);
    .line-chart-actions-top {
      display: flex;
      justify-content: space-between;
      // margin-left: 28px;
      &.spacer {
        height: 0 !important;
      }
    }
    .line-chart-actions-bottom {
      display: none !important;
    }
  }
}
@media (max-width: 1024px) { 
  .line-chart-wrapper {
    width: 100%;
  }
}
</style>
<style lang="scss">
@import '@/components/products/charts/period/line-chart/index.scss';
.remove-symbol-size {
  .wj-series-group g ellipse:nth-child(2) {
    rx: 0;
    ry: 0;
  }
}
@media only screen and (min-width: 1025px) {
  .quality-score-chart {
    &.line-chart-wrapper {
      .line-chart {
        .wj-flexchart {
          .wj-axis-x {
            .wj-label {
              fill: $monoDark !important;
              &.active-select {
                fill: $monoBlack !important;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .quality-score-chart {
    &.line-chart-wrapper {
      .line-chart {
        .wj-flexchart {
          .wj-axis-x {
            .wj-label {
              fill: $monoBlack !important;
            }
          }
        }
      }
    }
  }
}
.quality-score-chart {
  .line-chart {
    .wj-flexchart {
      margin-left: -10px;
    }
  }
  .remove-symbol-size .wj-axis-x g:first-child text {
    font-size: 11px !important;
  }
}
@media only screen and (min-width: 1366px) {
  .quality-score-chart {
    .line-chart .wj-flexchart {
      min-height: 400px;
    }
  }
}
</style>
