<template>
  <div class="chart-details" :class="{'chart-popup': isPopup }">
    <div class="chart-details-duration">
      <div class="chart-details-duration__label">{{ chartTitle }}</div>
      <div class="chart-details-duration__detail">{{ $t('dashboard_main.label_data_quality_score_weighted_average') }}</div>
    </div>

    <div class="chart-details-content">
      <div class="chart-details-content_emission">
        <div class="chart-details-content_emission__value">
          {{ getValueEmissions === 0 ? '-' : formatNumber(getValueEmissions) }}
        </div>
      </div>

      <div class="chart-details-content_variation">
        <div class="chart-details-content_variation__label">{{ratioTitle}}</div>
        <div class="chart-details-content_variation__content">
          <span class="chart-details-content_variation__content--value">{{ formatNumber(getRatio, 'chart-detail-ratio') }}</span>
          <span v-if="getRatio && getRatio !== '-'" class="chart-details-content_variation__content--percent">%</span>
          <div v-if="getRatio !== '-'" class="chart-title_image">
            <img :src="imgSrc" alt="" />
          </div>
        </div>
      </div>
    </div> 
  </div>
</template>

<script>
import { formatBigNumber } from '@/concerns/newRegisterData/wijmo.helper';
import { addThoundSandCommaWithTwoDigit } from '@/utils/convertNumber';
import { getTitle, chartTitleMonthYear } from '@/utils/getTextWithCondition';

export default {
  name: 'selectedChartData',
  computed: {
    chartTitle() {
      let year = this.selectedChartData.yearTime;
      if (this.tab === 1 || this.tab === 2 ) {
        year = this.selectedChartData.year_order
      }
      return chartTitleMonthYear(this.tab, year, this.selectedChartData.order, this.selectedChartData.month)
    },
    imgSrc() {
      const imageSrc = this.selectedChartData.up === 1 ? 'up.svg' : 'down.svg';
      return require(`@/assets/images/pcaf/amount/${imageSrc}`);
    },
    getDuration() {
      return this.selectedChartData?.time_range;
    },
    getValueEmissions() {
      return this.selectedChartData?.total_dq_ratio;
    },
    isUpEmissions() {
      return this.selectedChartData?.up === 1 ? true : false;
    },
    getRatio() {
      return this.selectedChartData?.ratio || "-";
    },
    ratioTitle() {
      return getTitle(this.tab);
    },
  },
  props: {
    selectedChartData: {
      type: Object,
      default: () => {},
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    formatNumber(num, type) {
      if(num === 0) {
        return '---'
      }
      return addThoundSandCommaWithTwoDigit(num, type, false, true)
    },
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.chart-details.chart-popup {
    max-width: 100%;
    background: transparent;
}
.chart-details {
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  background: $monoWhite;
  border-radius: 8px;
  height: 400px;
  margin-top: 12px;
  width: 234px;
  border: 1px solid $monoLight;
  display: none;
  &.chart-popup {
    width: 100%;
    gap: 8px;
    max-width: 100%;
    background: transparent;
    border: unset;
    display: block;
  }
  &-duration {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 6px;
    &__label {
      font-size: 11px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.03em;
      text-align: left;
      color: $monoBlack;
    }
    &__detail {
      font-size: 11px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.03em;
      text-align: left;
      color: $monoBlack;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    &_emission {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px 0px 16px;
      gap: 8px;
      // border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      width: 100%;
      &__label {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: $monoBlack;
      }
      &__value {
        font-weight: 400;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0.03em;
        color: $monoBlack;
        word-break: break-all;
        font-family: "Century Gothic Pro";
        &--unit {
          font-size: 14px;
          line-height: 24px;
          margin-left: 8px;
          display: inline-block;
        }
      }
    }

    &_variation {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 0px;
      gap: 8px;
      width: 100%;
      // border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      &__label {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: $monoBlack;
      }
      &__content {
        font-weight: 400;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0.03em;
        color: $monoBlack;
        display: flex;
        align-items: center;
        gap: 8px;
        // height: 22px;
        &--value {
          word-break: break-all;
          font-family: "Century Gothic Pro";
        }
        &--percent {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.05em;
          margin-top: 12px;
        }
      }
    }

    &_ratio {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 0px;
      gap: 8px;
      &__label {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: $monoBlack;
      }
      &__content {
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: 0.05em;
        color: $goldMid;
        display: flex;
        align-items: center;
        gap: 8px;
        height: 22px;
        &--icon {
          cursor: pointer;
        }
        &--percent {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.05em;
        }
      }
    }

    &_product {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      width: 100%;
      &_item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 8px;
        width: 100%;
        &--dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          min-width: 10px;
        }
        &--name {
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          letter-spacing: 0.05em;
          color: $monoBlack;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

@include desktop {
  .chart-details {
    display: flex;
  }
}

@media (max-width: 1448px) {
  .chart-details{
    height: calc(459px - 27px);
  }
  
}
@include desktop {
  .chart-details {
    &-duration {
      flex-direction: column;
    }
  }
}
</style>