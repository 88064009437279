<template>
  <div>
    <ColumnChart v-if="keyRender" :branchIds="branchIds" :assetId="assetId" :startMonth="startMonth" />
    <PieChart v-if="keyRender" :branchIds="branchIds" :assetId="assetId" :startMonth="startMonth" />
    <TrendLineChart v-if="keyRender" :branchIds="branchIds" :assetId="assetId" :startMonth="startMonth" />
    <PieIndustryChart v-if="keyRender" :branchIds="branchIds" :assetId="assetId" :startMonth="startMonth" />
    <TrendIndustryChart v-if="keyRender" :branchIds="branchIds" :assetId="assetId" :startMonth="startMonth" />
    <QualityScoreLineChart v-if="keyRender" :branchIds="branchIds" :assetId="assetId" :startMonth="startMonth" />
  </div>
</template>
<script>
import ColumnChart from '@/components/dashboard/home-pcaf/column-chart/index';
import PieChart from '@/components/dashboard/home-pcaf/pie-chart/index';
import PieIndustryChart from '@/components/dashboard/home-pcaf/pie-industry-chart/index';
import TrendLineChart from '@/components/dashboard/home-pcaf/trend-line-chart/index';
import QualityScoreLineChart from '@/components/dashboard/home-pcaf/quality-score-line-chart/index';
import TrendIndustryChart from '@/components/dashboard/home-pcaf/trend-industry-chart/index';
import { getStartMonth } from '@/api/duration';
export default {
  components: { ColumnChart, PieChart, PieIndustryChart, TrendLineChart, QualityScoreLineChart, TrendIndustryChart },
  props: {
    branchIds: {
      type: Array,
      default: () => [],
    },
    assetId: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      keyRender: false,
      startMonth: 4,
    };
  },
  created() {
    getStartMonth(this.$store.state.userData.contractor).then(res => {
      this.startMonth = res.data.start_month;
      this.keyRender = true;
    })
  }
};
</script>
  