<template>
  <div class="chart__wrapper chart-column chart-dashboard" ref="chartPeriod">
    <div class="chart">
      <!-- header -->
      <div class="chart__header">
        <div class="chart__header--left">
          <button
            type="button"
            class="chart-btn-action"
            :disabled="isDisabledPrev"
            @click="prev"
            @mouseover="handleMouseOverPrevBtn"
            @mouseleave="isMouseOverPrev = false"
            @mousedown="isBlurPrev = true"
            @mouseup="isBlurPrev = false"
            @touchstart="handleTouchStartPrevBtn"
            @touchend="handleTouchEndPrevBtn"
          >
            <img :src="prevIconUrl || prevIcon" alt="Prev" />
          </button>
        </div>

        <div class="chart__header--center">
          <div class="chart-legend">
            <div class="legend scope1">
              <div class="bar-color"></div>
              <div class="bar-name">{{ $t('dashboard_main.label_scope') }} 1</div>
            </div>

            <div class="legend scope2">
              <div class="bar-color"></div>
              <div class="bar-name">{{ $t('dashboard_main.label_scope') }} 2</div>
            </div>

            <div class="legend scope3" v-if="getIsEnabledScope3">
              <div class="bar-color"></div>
              <div class="bar-name">{{ $t('dashboard_main.label_scope') }} 3</div>
            </div>
          </div>
          <div class="chart-icon-legend" >
            <img src="@/assets/icons/dashboard/standard/column-chart.svg" alt="" />
            <span>{{ $t('dashboard_main.label_column_chart_scope4') }}</span>
          </div>
        </div>
        <div class="show-pastDurations-change" v-if="isShowCompareCheckBox">
          <check-box-home-standard 
            @change="handleChangeCheckBoxPastDurations" 
            v-model="pastDurationChangeStatus" 
            :label="getTitleCompareValueFullText"
          />
        </div>
        <div class="chart__header--right">
          <button
            type="button"
            class="chart-btn-action"
            :disabled="isDisabledNext"
            @click="next"
            @mouseover="handleMouseOverNextBtn"
            @mouseleave="isMouseOverNext = false"
            @mousedown="isBlurNext = true"
            @mouseup="isBlurNext = false"
            @touchstart="handleTouchStartNextBtn"
            @touchend="handleTouchEndNextBtn"
          >
            <img :src="nextIconUrl || nextIcon" alt="Next" />
          </button>
        </div>
      </div>
      <div class="show-pastDurations-change change-mobile" v-if="isShowCompareCheckBox">
        <check-box-home-standard 
          @change="handleChangeCheckBoxPastDurations" 
          v-model="pastDurationChangeStatus" 
          :label="getTitleCompareValueFullText"
        />
      </div>
      <!-- body -->
      <div class="chart__body">
        <div class="chart__body--left" ref="yearLeft" :style="{ 'min-width': minWidth || '60px' }">
          <div class="axisY">
            <div
              class="axis-item"
              v-for="(item, index) in axisYItemsSource"
              :key="index + '-axis-y'"
              :class="{ unit: index === 7 }"
              :style="{ bottom: `${item.percent}%` }"
            >
              {{ item.text }}
            </div>
          </div>
        </div>

        <div class="chart__body--center" ref="bodyCenter" @scroll="syncScroll('body')">
          <div class="chart-body-content">
          <div class="transition" :style="{ width: transitionWidth }"></div>
          <div
            class="chart-bar"
            @click="onClickChartItem(item)"
            @mouseover="onMouseOver(item)"
            @mouseleave="onMouseLeave()"
            :class="{ selected: selected(item.time_range), hovered: item.time_range === hoverTimeRange }"
            v-for="(item, index) in chartValue"
            :key="index + '-bar-chart'"
          >
            <div class="active"></div>
            <div class="hover"></div>
            <div v-if="hasNegativeNumber" class="number-wrapper">
              <div
                class="positive-number"
                :style="{
                  height: `calc(100% - ${item.zero_value_percent})`,
                  bottom: item.zero_value_percent,
                  ...getStylesColumn(item, 'margin-left'),
                }"
              >
              <div
                  class="bar bar--scope4 bar--others animation"
                  :style="stylesScope4(item, 'top')"
                  v-if="item.emission_scope4 >= 0"
                  @mouseover="toolTipOn(item)"
                  @mouseleave="toolTipOff"
                  @mousemove="updateTooltipPosition"
                ></div>
                <div
                  class="bar bar--scope3 animation"
                  :style="{ height: item.emission_scope3_percent }"
                  v-if="getIsEnabledScope3 && item.emission_scope3 >= 0"
                  @mouseover="toolTipOn(item)"
                  @mouseleave="toolTipOff"
                  @mousemove="updateTooltipPosition"
                ></div>
                <div
                  class="bar bar--scope2 animation"
                  :style="{ height: item.emission_scope2_percent }"
                  v-if="item.emission_scope2 >= 0"
                  @mouseover="toolTipOn(item)"
                  @mouseleave="toolTipOff"
                  @mousemove="updateTooltipPosition"
                ></div>
                <div
                  class="bar bar--scope1 animation"
                  :style="{ height: item.emission_scope1_percent }"
                  v-if="item.emission_scope1 >= 0"
                  @mouseover="toolTipOn(item)"
                  @mouseleave="toolTipOff"
                  @mousemove="updateTooltipPosition"
                ></div>
              </div>
              <div
                v-if="pastDurationChangeStatus && item.pastDurations"
                class="positive-number transparent-number"
                :style="{
                  height: `calc(100% - ${item.zero_value_percent})`,
                  bottom: item.zero_value_percent,
                  ...getStylesColumn(item, 'margin-right'),
                }"
              >
              <div
                  class="bar bar--scope4 bar--others animation"
                  :style="stylesScope4(item.pastDurations, 'top')"
                  v-if="item.pastDurations.emission_scope4 >= 0"
                  @mouseover="toolTipOn(item)"
                  @mouseleave="toolTipOff"
                  @mousemove="updateTooltipPosition"
                ></div>
                <div
                  class="bar bar--scope3 animation"
                  :style="{ height: item.pastDurations.emission_scope3_percent }"
                  v-if="getIsEnabledScope3 && item.pastDurations.emission_scope3 >= 0"
                ></div>
                <div
                  class="bar bar--scope2 animation"
                  :style="{ height: item.pastDurations.emission_scope2_percent }"
                  v-if="item.pastDurations.emission_scope2 >= 0"
                ></div>
                <div
                  class="bar bar--scope1 animation"
                  :style="{ height: item.pastDurations.emission_scope1_percent }"
                  v-if="item.pastDurations.emission_scope1 >= 0"
                ></div>
              </div>
              <div class="zero-crossline" :style="{ height: item.zero_value_percent }"></div>
              <div
                class="negative-number"
                :style="{
                  height: item.zero_value_percent,
                  ...getStylesColumn(item, 'margin-left'),
                }"
              >
              <div
                  class="bar bar--scope4 bar--others animation"
                  :style="stylesScope4(item, 'bottom')"
                  v-if="item.emission_scope4 < 0"
                  @mouseover="toolTipOn(item)"
                  @mouseleave="toolTipOff"
                  @mousemove="updateTooltipPosition"
                ></div>
                <div
                  class="bar bar--scope3 animation"
                  :style="{ height: item.emission_scope3_percent }"
                  v-if="getIsEnabledScope3 && item.emission_scope3 < 0"
                  @mouseover="toolTipOn(item)"
                  @mouseleave="toolTipOff"
                  @mousemove="updateTooltipPosition"
                ></div>
                <div
                  class="bar bar--scope2 animation"
                  :style="{ height: item.emission_scope2_percent }"
                  v-if="item.emission_scope2 < 0"
                  @mouseover="toolTipOn(item)"
                  @mouseleave="toolTipOff"
                  @mousemove="updateTooltipPosition"
                ></div>
                <div
                  class="bar bar--scope1 animation"
                  :style="{ height: item.emission_scope1_percent }"
                  v-if="item.emission_scope1 < 0"
                  @mouseover="toolTipOn(item)"
                  @mouseleave="toolTipOff"
                  @mousemove="updateTooltipPosition"
                ></div>
              </div>
              <div
                v-if="pastDurationChangeStatus && item.pastDurations"
                class="negative-number transparent-number"
                :style="{
                  height: item.zero_value_percent,
                  ...getStylesColumn(item, 'margin-right'),
                }"
              >
              <div
                  class="bar bar--scope4 bar--others animation"
                  :style="stylesScope4(item.pastDurations, 'bottom')"
                  v-if=" item.pastDurations.emission_scope4 < 0"
                  @mouseover="toolTipOn(item)"
                  @mouseleave="toolTipOff"
                  @mousemove="updateTooltipPosition"
                ></div>
                <div
                  class="bar bar--scope3 animation"
                  :style="{ height: item.pastDurations.emission_scope3_percent }"
                  v-if="getIsEnabledScope3 && item.pastDurations.emission_scope3 < 0"
                ></div>
                <div
                  class="bar bar--scope2 animation"
                  :style="{ height: item.pastDurations.emission_scope2_percent }"
                  v-if="item.pastDurations.emission_scope2 < 0"
                ></div>
                <div
                  class="bar bar--scope1 animation"
                  :style="{ height: item.pastDurations.emission_scope1_percent }"
                  v-if="item.pastDurations.emission_scope1 < 0"
                ></div>
              </div>
            </div>
            <!-- <div >

            </div> -->
            <div v-else class="number-wrapper">
              <div
                class="number-positive-wrapper"
                :style="{ ...getStylesColumn(item, 'margin-left') }"
              >
              <div
                  class="bar bar--scope4 bar--others animation"
                  :style="stylesScope4(item, 'top')"
                  @mouseover="toolTipOn(item)"
                  @mouseleave="toolTipOff"
                  @mousemove="updateTooltipPosition"
                ></div>
                <div
                  class="bar bar--scope3 animation"
                  :style="{ height: item.emission_scope3_percent }"
                  v-if="getIsEnabledScope3"
                  @mouseover="toolTipOn(item)"
                  @mouseleave="toolTipOff"
                  @mousemove="updateTooltipPosition"
                ></div>
                <div
                  class="bar bar--scope2 animation"
                  :style="{ height: item.emission_scope2_percent }"
                  @mouseover="toolTipOn(item)"
                  @mouseleave="toolTipOff"
                  @mousemove="updateTooltipPosition"
                ></div>
                <div
                  class="bar bar--scope1 animation"
                  :style="{ height: item.emission_scope1_percent }"
                  @mouseover="toolTipOn(item)"
                  @mouseleave="toolTipOff"
                  @mousemove="updateTooltipPosition"
                ></div>
              </div>
              <div
                v-if="pastDurationChangeStatus && item.pastDurations"
                class="number-positive-wrapper transparent-number"
                :style="{ ...getStylesColumn(item, 'margin-right') }"
              >
              <div
                  class="bar bar--scope4 bar--others animation"
                  :style="stylesScope4(item.pastDurations, 'top')"
                  @mouseover="toolTipOn(item)"
                  @mouseleave="toolTipOff"
                  @mousemove="updateTooltipPosition"
                ></div>
                <div
                  class="bar bar--scope3 animation"
                  :style="{ height: item.pastDurations.emission_scope3_percent }"
                  v-if="getIsEnabledScope3"
                ></div>
                <div
                  class="bar bar--scope2 animation"
                  :style="{ height: item.pastDurations.emission_scope2_percent }"
                ></div>
                <div
                  class="bar bar--scope1 animation"
                  :style="{ height: item.pastDurations.emission_scope1_percent }"
                ></div>
              </div>
            </div>

            <!-- tooltip -->
            <div
              id="tooltip-column"
              class="tooltip"
              :style="{ left: tooltipPosition.x + 'px', top: tooltipPosition.y + 'px' }"
              v-if="isToolTipOn(item)"
            >
              <div class="tooltip-container">
                <p class="tooltip-title">{{ chartTitle }}</p>
                <div class="tooltip-content">
                  <div class="tooltip-content-left">
                    <span>{{ $t("dashboard_main.label_total_emissions")}}</span>
                    <span>{{$t("dashboard_main.label_offset_amount")}}</span>
                    <span>{{getTitleRatio}}</span>
                    <span v-if="pastDurationChangeStatus">{{ getTitleCompareValue }}</span>
                    <span class="scope1">{{ $t('dashboard_main.label_scope') }} 1</span>
                    <span class="scope2">{{ $t('dashboard_main.label_scope') }} 2</span>
                    <span class="scope3">{{ $t('dashboard_main.label_scope') }} 3</span>
                  </div>
                  <div class="tooltip-content-right">
                    <div class="total-emission">
                      <span class="data mr-2">{{ totalEmissions }}</span>
                      <span class="unit">{{ getUnitName }}</span>
                    </div>
                    <div class="total-emission">
                      <span class="data mr-2">{{ totalEmissionsS4 }}</span>
                      <span class="unit">{{ getUnitName }}</span>
                    </div>
                    <span>{{ changeYearEveryValue('core') }}</span>
                    <span v-if="pastDurationChangeStatus">{{ changeYearEveryValue('compare') }}</span>
                    <span>{{ totalEmissionsScope('emission_scope1') }}</span>
                    <span>{{ totalEmissionsScope('emission_scope2') }}</span>
                    <span>{{ totalEmissionsScope('emission_scope3') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="chart__body--right"></div>
        </div>
      </div>
      </div>
      <!-- footer -->
      <div class="chart__footer">
        <div class="chart__footer--left" :style="{ minWidth: footerWidth + 'px' }">
          <button
            type="button"
            class="chart-btn-action"
            :disabled="isDisabledPrev"
            @click="prev"
            @mouseover="handleMouseOverPrevBtn"
            @mouseleave="isMouseOverPrev = false"
            @mousedown="isBlurPrev = true"
            @mouseup="isBlurPrev = false"
            @touchstart="handleTouchStartPrevBtn"
            @touchend="handleTouchEndPrevBtn"
          >
            <img :src="prevIconUrl || prevIcon" alt="Prev" />
          </button>
        </div>

        <div class="chart__footer--center" ref="footerCenter" @scroll="syncScroll('footer')">
          <div class="axisX">
            <div
              class="axis-item"
              :class="{ active: selected(item.value) }"
              v-for="(item, index) in axisXItemsSource"
              :key="index + '-axis-x'"
            >
              <!-- {{ item.label }} -->
              <span>{{ item.label }}</span>
              <!-- <span>&nbsp;-&nbsp;</span>
              <span>{{ item.end_at }}</span> -->
            </div>
          </div>
        </div>

        <div class="chart__footer--right">
          <button
            type="button"
            class="chart-btn-action"
            :disabled="isDisabledNext"
            @click="next"
            @mouseover="handleMouseOverNextBtn"
            @mouseleave="isMouseOverNext = false"
            @mousedown="isBlurNext = true"
            @mouseup="isBlurNext = false"
            @touchstart="handleTouchStartNextBtn"
            @touchend="handleTouchEndNextBtn"
          >
            <img :src="nextIconUrl || nextIcon" alt="Next" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  formatValue,
  $_helper_isNumberType,
  $_helper_isNegativeNumber,
  math,
  formatBigNumber,
} from '@/concerns/newRegisterData/wijmo.helper';
import { mapGetters, mapActions, mapState } from 'vuex';
import { addThoundSandCommaWithTwoDigit } from '@/utils/convertNumber';
import { numberFormat } from '@/filters/number';
import { getTitle, getTitleCompare, getTitleCompareFullText, chartTitleMonthYear , getRatioValue } from '@/utils/getTextWithCondition';
import { MOBILE_WIDTH, TABLET_WIDTH } from '@/constants/screen-size';
import CheckBoxHomeStandard from '@/components/dashboard/home-standard/checkbox-standard';
import { formatDataList } from '@/utils/pcaf';
import moment from 'moment';
import { prepareChartAxisY, getMaxChartItemsCount } from '@/concerns/chart';
import { getWidthYAxisByTextContent } from '@/utils/calcTextWidth';
import debounce from 'lodash/debounce';
import { DASHBOARD_DISPLAY_UNIT } from '@/constants/dashboard';

const AXIS_Y_ITEM_COUNT = 7;

export default {
  components: { CheckBoxHomeStandard },
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    isEnabledScope3: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: Number,
      default: 0,
    },
    branchIds: {
      type: Array,
      default: () => [],
    },
    isGetAllFilter: {
      type: Boolean,
      default: true,
    },
    startMonth: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      indexAtFirstBar: 0,
      min: 0,
      max: 500,
      axisYItemsSource: [
        {
          value: 0,
          text: '0',
          percent: 0,
        },
        {
          value: 100,
          text: '100',
          percent: 12.5,
        },
        {
          value: 200,
          text: '200',
          percent: 25,
        },
        {
          value: 300,
          text: '300',
          percent: 37.5,
        },
        {
          value: 400,
          text: '400',
          percent: 50,
        },
        {
          value: 500,
          text: '500',
          percent: 62.5,
        },
        {
          value: 600,
          text: '600',
          percent: 75,
        },
        {
          value: 700,
          text: '700',
          percent: 87.5,
        },
        {
          value: 800,
          text: 't-CO2',
          percent: 100,
        },
      ],
      axisXItemsSource: [],
      chartValue: [],
      selectedTimeRange: '',
      hoverTimeRange: '',
      isDisabledPrev: false,
      isDisabledNext: false,
      isMouseOverNext: false,
      isMouseOverPrev: false,
      isBlurNext: false,
      isBlurPrev: false,
      isFirstRender: true,
      transitionWidth: '100%',
      footerWidth: 0,
      minWidth: 0,
      tooltipPosition: { x: 0, y: 0 },
      rangeTooltipData: null,
      pastDurationChangeStatus: false,
      processedData: [],
      maxChartItem: 12,
      nextIconUrl: '',
      prevIconUrl: '',
    };
  },
  async mounted() {
    this.getStatusCheckBox();
    if (this.indexAtFirstBar + this.maxChartItem >= this.processedData.length) {
      this.isDisabledNext = true;
    } else {
      this.isDisabledNext = false;
    }

    if (this.indexAtFirstBar <= 0) {
      this.isDisabledPrev = true;
    } else {
      this.isDisabledPrev = false;
    }
    window.addEventListener('scroll', this.handleScroll);
    const debouncedHandleResize = debounce(() => {
      this.handleResize();
    }, 1000);
    window.addEventListener('resize', debouncedHandleResize);
    this.footerWidth = this.$refs.yearLeft.clientWidth;
    // get last element from maxChartItem & set default data
    formatDataList(moment, this.startMonth, 'year').slice(-this.maxChartItem).forEach(year => {
      this.axisXItemsSource.push({
        label: year.year,
        value: year.year,
        year: year.year,
      })
    })
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('scroll', this.handleResize);
  },
  computed: {
    ...mapGetters({
      getIndexAtFirstBarByPeriodWithYearly: 'dashboard/getIndexAtFirstBarByPeriodWithYearly',
      contractor: 'userData/getContractor',
    }),
    ...mapState('dashboard', ['selectedEmissionsByPeriodDashboard']),
    ...mapState('settingsDashboard', ['itemsSettings']),
    getUnitName() {
      return this.itemsSettings[this.contractor]?.unitName || 't-CO2';
    },
    isTco2Unit() {
      return this.getUnitName === DASHBOARD_DISPLAY_UNIT.tCO2Text;
    },
    stylesScope4() {
      return (item, position) => {
        let styles = {
          height: `${item.emission_scope4_percent}`
        }
        const totalPercentNormal = parseFloat(item.emission_scope1_percent) + parseFloat(item.emission_scope2_percent) + parseFloat(item.emission_scope3_percent);
        const totalOriginal = parseFloat(item.original.emission_scope1) + parseFloat(item.original.emission_scope2) + parseFloat(item.original.emission_scope3)
        let positionScope = `calc(100% - ${parseFloat(item.emission_scope1_percent) + parseFloat(item.emission_scope2_percent) + parseFloat(item.emission_scope3_percent)}%)`;
        let sumOfNegatives = 0;
        let sumOfPositives = 0;
        let percentPositives = 0;
        let percentNegatives = 0;
        
        for (let [key, value] of Object.entries(item.original)) {
          if (key !== 'emission_scope4' && key !== 'duration_id' && key !== 'total_emission') {
            const parsedValue = parseFloat(value);
            if (parsedValue < 0) {
              sumOfNegatives += parsedValue;
              percentNegatives += parseFloat(item[`${key}_percent`]);
            } else if (parsedValue > 0) {
              sumOfPositives += parsedValue;
              percentPositives += parseFloat(item[`${key}_percent`]);
            }
          }
        }

        if(sumOfNegatives < 0 && sumOfPositives > 0) {
          positionScope = `calc(100% - ${ +item.original.emission_scope4 > 0 ? percentPositives : percentNegatives}%)`
        }

        if(sumOfPositives > 0 && !sumOfNegatives && item.original.emission_scope4 < 0) {
          styles.left =  '50%';
          styles.transform = 'translateX(-50%)';
        }
        
        if(sumOfNegatives > item.original.emission_scope4) {
          styles.top = 0;
          styles.bottom = 'auto';
        }

        if (totalPercentNormal > parseFloat(item.emission_scope4_percent)) { // not true then set position default
          styles[position] = positionScope
        }

        if(totalOriginal < 0 && parseFloat(item.original.emission_scope4) > 0 || sumOfPositives < +item.original.emission_scope4) {
          styles.bottom = 0;
          styles.top = 'auto';
        }
        return styles; 
      }
    },
    isShowCompareCheckBox() {
      return this.tab === 2 || this.tab === 3;
    },
    getTitleCompareValue() {
      return getTitleCompare(this.tab);
    },
    getTitleCompareValueFullText() {
      return getTitleCompareFullText(this.tab);
    },
    getTitleRatio() {
      return getTitle(this.tab);
    },
    isToolTipOn() {
      return (item) => {
        return this.rangeTooltipData?.time_range === item.time_range;
      };
    },
    changeYearEveryValue() {
      return (type) => {
        let item = this.rangeTooltipData?.detail_ratio;
        let isGetCompare = false;
        if (type === 'compare') {
          isGetCompare = true;
        }
        let ratioValue = getRatioValue(this.tab, item, isGetCompare);
        ratioValue = ratioValue ? addThoundSandCommaWithTwoDigit(ratioValue, "", false, true) : '-';
        let unit = '';
        if (ratioValue !== '-') {
          unit = '%';
          let itemUp = item?.up_same;
          if (type === 'compare') {
            itemUp = item.up;
          }
          if (itemUp === 1) {
            unit += '↑';
          } else {
            unit += '↓';
          }
        }
        return ratioValue + unit;
      };
    },
    chartTitle() {
      return chartTitleMonthYear(
        this.tab,
        this.rangeTooltipData.year,
        this.rangeTooltipData.order,
        this.rangeTooltipData.month,
      );
    },
    totalEmissions() {
      const total = this.rangeTooltipData?.total_emission;
      return this.calculateEmissions(total);
    },
    totalEmissionsS4() {
      const total = this.rangeTooltipData?.s4Original?.total_emission;
      return this.calculateEmissions(total);
    },
    selected() {
      return (value) => {
        return value === this.selectedTimeRange;
      };
    },

    totalEmissionsScope() {
      return (scopeName) => {
        return this.rangeTooltipData[scopeName] !== null || this.rangeTooltipData[scopeName] !== undefined
          ? this.calculateEmissions(this.rangeTooltipData[scopeName]) + ` ${this.getUnitName}`
          : '-';
      };
    },
    getIsEnabledScope3() {
      return this.isEnabledScope3;
    },
    nextIcon() {
      if (this.isDisabledNext) {
        this.isBlurNext = false;
        this.isMouseOverNext = false;
        return 'img/icons/next-disabled.svg';
      }

      if (this.isBlurNext) {
        return 'img/icons/next.svg';
      }

      if (this.isMouseOverNext) {
        return 'img/icons/next-hover.svg';
      }

      return 'img/icons/next.svg';
    },
    prevIcon() {
      if (this.isDisabledPrev) {
        this.isBlurPrev = false;
        this.isMouseOverPrev = false;
        return 'img/icons/prev-disabled.svg';
      }

      if (this.isBlurPrev) {
        return 'img/icons/prev.svg';
      }

      if (this.isMouseOverPrev) {
        return 'img/icons/prev-hover.svg';
      }

      return 'img/icons/prev.svg';
    },
    hasNegativeNumber() {
      const existNegativeNumber = this.chartValue.find(
        (item) =>
          item.emission_scope1 < 0 ||
          item.emission_scope2 < 0 ||
          item.emission_scope3 < 0 ||
          item.emission_scope4 < 0 ||
          item?.pastDurations && 
          (item.pastDurations.emission_scope1 < 0 ||
          item.pastDurations.emission_scope2 < 0 ||
          item.pastDurations.emission_scope3 < 0 || 
          item.pastDurations.emission_scope4 < 0) 
      );
      return existNegativeNumber ? true : false;
    },
  },
  watch: {
    chartData() {
      this.handleChangeCheckBoxPastDurations(this.pastDurationChangeStatus);
    },
    tab() {
      this.nextIconUrl = '';
      this.prevIconUrl = '';
      this.getStatusCheckBox();
    },
    getUnitName() {
      this.updateAxisYItemsSourceFollowUnit();
      this.getWidthOfText();
      this.$nextTick(() => {
        this.footerWidth = this.$refs.yearLeft.clientWidth;
      });
    },
  },

  methods: {
    ...mapActions('dashboard', ['updateSelectedEmissionsByPeriodDashboard']),
    calculateEmissions(emission, isAxisYChart) {
      if (emission === undefined || emission === null || isNaN(emission)) {
        return '-';
      }

      const unit = this.itemsSettings[this.contractor]?.selectedUnit;
      const isKgCO2 = unit === DASHBOARD_DISPLAY_UNIT.kgCO2;
      const valueEmission = math.bignumber(formatValue(emission));
      const emissionValueResult = isKgCO2
        ? formatBigNumber(math.multiply(valueEmission, 1000), isAxisYChart ? 0 : 50)
        : formatBigNumber(valueEmission, isAxisYChart ? 0 : 50);

      return addThoundSandCommaWithTwoDigit(emissionValueResult, '', false, true);
    },
    updateAxisYItemsSourceFollowUnit() {
      this.axisYItemsSource = this.axisYItemsSource.map((item) => ({
        ...item,
        text: this.isTco2Unit
          ? item.textClone
          : item.text === DASHBOARD_DISPLAY_UNIT.tCO2Text || item.text === DASHBOARD_DISPLAY_UNIT.kgCO2Text
          ? this.getUnitName
          : this.calculateEmissions(item.value, true),
      }));
    },
    syncScroll(origin) {
      if (origin === 'body') {
        this.$refs.footerCenter.scrollLeft = this.$refs.bodyCenter.scrollLeft;
      } else if (origin === 'footer') {
        this.$refs.bodyCenter.scrollLeft = this.$refs.footerCenter.scrollLeft;
      }
    },
    handleScroll() {
      if (this.rangeTooltipData) {
        this.toolTipOff();
      }
    },
    handleResize() {
      this.getStylesColumn();
      this.prepareChartDataValue();
      if (this.indexAtFirstBar + this.maxChartItem >= this.processedData.length) {
        this.isDisabledNext = true;
      } else {
        this.isDisabledNext = false;
      }

      if (this.indexAtFirstBar <= 0) {
        this.isDisabledPrev = true;
      } else {
        this.isDisabledPrev = false;
      }
    },
    getStylesColumn(item, styleName) {
      let width = 37.96;

      if (this.tab !== 0) {
        width = 25.3;
      }
      if (window.innerWidth < MOBILE_WIDTH) {
        width = 20;
      }
      if (this.pastDurationChangeStatus) {
        width = 15;
      }
      const barItemListElement = document.querySelectorAll('.chart-dashboard .bar');
      barItemListElement.forEach((element) => {
        element.style.width = width + 'px';
      });
      return {
        [styleName]: item?.pastDurations ? width * 2 + 'px' : '',
      };
    },
    getStatusCheckBox() {
      switch (this.tab) {
        case 0:
        case 1:
          this.pastDurationChangeStatus = false;
          break;
        case 2: // quarter
          this.pastDurationChangeStatus = this.selectedEmissionsByPeriodDashboard.checkBoxQuarterYearStatus;
          break;
        case 3: // month year
          this.pastDurationChangeStatus = this.selectedEmissionsByPeriodDashboard.checkBoxMonthYearStatus;
          break;
        default:
          break;
      }
    },
    presentExistData(item) {
      return item?.emission_scope1 + item?.emission_scope2 + item?.emission_scope3 !== 0;
    },
    isDisabledCheckBox() {
      return this.tab === 0 || this.tab === 1; // Year and half year tab
    },
    pastExistData(item) {
      return (
        item?.pastDurations?.emission_scope1 +
          item?.pastDurations?.emission_scope2 +
          item?.pastDurations?.emission_scope3 !==
        0
      );
    },
    handleChangeCheckBoxPastDurations(status) {
      this.pastDurationChangeStatus = status;
      this.updateCheckStatusToStore(status);
      if (status) {
        const dataHasPastDurations = this.chartData.map((itemCurrent) => {
          if (itemCurrent.year !== 2017) {
            let pastDurationsData;
            if (this.tab === 3) {
              const [newYear, newMonth] = itemCurrent.time_range.split('/');
              pastDurationsData = this.chartData.find((item) => {
                const [oldYear, oldMonth] = item.time_range.split('/');
                return (Number(newYear) - 1).toString() === oldYear && newMonth === oldMonth;
              });
            } else {
              pastDurationsData = this.chartData.find(
                (item) => itemCurrent.order === item.order && item.year === itemCurrent.year - 1,
              );
            }
            return pastDurationsData ? { ...itemCurrent, pastDurations: pastDurationsData } : itemCurrent;
          }
          return itemCurrent;
        });
        this.processedData = dataHasPastDurations;
      } else {
        this.processedData = this.chartData;
      }
      this.transformChartData();
      this.selectedFirstChartItem();
    },
    updateCheckStatusToStore(status) {
      switch (this.tab) {
        case 0:
        case 1:
          break;
        case 2:
          this.updateSelectedEmissionsByPeriodDashboard({
            ...this.selectedEmissionsByPeriodDashboard,
            checkBoxQuarterYearStatus: status,
          });
          break;
        case 3:
          this.updateSelectedEmissionsByPeriodDashboard({
            ...this.selectedEmissionsByPeriodDashboard,
            checkBoxMonthYearStatus: status,
          });
          break;
        default:
          break;
      }
    },
    updateTooltipPosition(event) {
      const SIDEBAR_WIDTH = 236;
      const element = document.getElementById('tooltip-column');
      const width = element?.clientWidth;
      const height = element?.clientHeight;

      const halfTooltipBlock = width / 2;
      const spacerFromColumnToLeft = event.clientX;
      const spacerFromColumnToRight = window.innerWidth - spacerFromColumnToLeft;
      const spacerFromColumnToSidebar = spacerFromColumnToLeft - SIDEBAR_WIDTH;

      if (spacerFromColumnToRight < halfTooltipBlock + 20) {
        this.tooltipPosition.x = spacerFromColumnToLeft - width + spacerFromColumnToRight - 20;
      } else if (spacerFromColumnToSidebar < halfTooltipBlock) {
        this.tooltipPosition.x = spacerFromColumnToLeft - spacerFromColumnToSidebar + 4;
      } else {
        this.tooltipPosition.x = spacerFromColumnToLeft - halfTooltipBlock;
      }

      let spacer = height;
      if (this.pastDurationChangeStatus) {
        spacer = height + 20;
      }
      this.tooltipPosition.y = event.clientY - spacer - 40;
    },
    toolTipOn(item) {
      if (window.innerWidth < TABLET_WIDTH) {
        if (this.rangeTooltipData) {
          this.toolTipOff();
        }
      } else {
        this.rangeTooltipData = item;
      }
    },
    toolTipOff() {
      this.rangeTooltipData = '';
    },
    transformChartData() {
      const summary = this.processedData.map((item) => {
        let emissionScopes = [
            item?.emission_scope1 ?? 0,
            item?.emission_scope2 ?? 0,
            item?.emission_scope3 ?? 0,
            item?.emission_scope4 ?? 0
        ];

        if (item?.pastDurations && this.pastDurationChangeStatus) {
            const emissionScopesPastData = [
                item.pastDurations?.emission_scope1 ?? 0,
                item.pastDurations?.emission_scope2 ?? 0,
                item.pastDurations?.emission_scope3 ?? 0,
                item.pastDurations?.emission_scope4 ?? 0
            ];
            emissionScopes = [...emissionScopes, ...emissionScopesPastData];
        }
        if (emissionScopes.every((scope) => $_helper_isNumberType(scope))) {
          let negativeTotal = 0;
          let positiveTotal = 0;

          emissionScopes.forEach((scope) => {
            const value = formatValue(scope);
            $_helper_isNegativeNumber(scope)
              ? (negativeTotal = formatBigNumber(
                  math.evaluate(`${math.bignumber(formatValue(negativeTotal))} + ${math.bignumber(value)}`),
                ))
              : (positiveTotal = formatBigNumber(
                  math.evaluate(`${math.bignumber(formatValue(positiveTotal))} + ${math.bignumber(value)}`),
                ));
          });

          return [negativeTotal, positiveTotal];
        }

        return emissionScopes.reduce((acc, scope) => acc + scope, 0);
      });
      [this.axisYItemsSource, this.min, this.max] = this.calcYAxis(summary);
      this.axisYItemsSource = this.axisYItemsSource.map((item) => ({
        ...item,
        textClone: item.text,
      }));
      this.updateAxisYItemsSourceFollowUnit();
      this.getWidthOfText();
      let index = this.findIndexSelected();
      if (index < 0) {
        const latestDurationIndex = this.processedData.length - 1;
        index = latestDurationIndex;
      }
      const listIndex = this.processedData.length - 1;
      if (index >= 0) {
        if (listIndex <= this.maxChartItem - 1 || index <= this.maxChartItem - 1) {
          this.indexAtFirstBar = 0;
        } else if (index >= listIndex - this.maxChartItem + 1) {
          this.indexAtFirstBar = listIndex - this.maxChartItem + 1;
        } else {
          this.indexAtFirstBar = index - 1;
        }
      } else {
        this.indexAtFirstBar = 0;
      }

      this.prepareChartDataValue();
    },
    selectedFirstChartItem() {
      if (!this.processedData.length) {
        return;
      }
      let index = this.findIndexSelected();

      if (index >= 0) {
        const item = this.processedData[index];
        this.$emit('onUpdateStateDetail', item, this.pastDurationChangeStatus);
        this.selectedTimeRange = item.time_range;
      } else {
        const latestDurationIndex = this.processedData.length - 1;
        const item = this.processedData[latestDurationIndex];
        this.$emit('onUpdateStateDetail', item, this.pastDurationChangeStatus);
        this.selectedTimeRange = item.time_range;
      }
      if (this.indexAtFirstBar + this.maxChartItem >= this.processedData.length) {
        this.isDisabledNext = true;
      } else {
        this.isDisabledNext = false;
      }

      if (this.indexAtFirstBar <= 0) {
        this.isDisabledPrev = true;
      } else {
        this.isDisabledPrev = false;
      }
    },
    findIndexSelected() {
      let index = -1;
      if (this.tab === 0) {
        const oldSelectedYear = this.$store.state.dashboard.selectedEmissionsByPeriodDashboard.selectedYear;
        index = this.processedData.findIndex((item) => {
          return item && item.time_range === oldSelectedYear;
        });
        if (index < 0) {
          const currentYear = new Date().getFullYear();
          index = this.processedData.findIndex((item) => {
            return item.year === currentYear;
          });
        }
      } else if (this.tab === 1) {
        const oldSelectedHalfYear = this.$store.state.dashboard.selectedEmissionsByPeriodDashboard.selectedHalfYear;
        index = this.processedData.findIndex((item) => {
          return item && item.time_range === oldSelectedHalfYear;
        });
        if (index < 0) {
          index = this.processedData.length - 1;
        }
      } else if (this.tab === 2) {
        const oldSelectedQuarterYear =
          this.$store.state.dashboard.selectedEmissionsByPeriodDashboard.selectedQuarterYear;
        index = this.processedData.findIndex((item) => {
          return item && item.time_range === oldSelectedQuarterYear;
        });
        if (index < 0) {
          index = this.processedData.length - 1;
        }
      } else if (this.tab === 3) {
        const oldSelectedMonthYear = this.$store.state.dashboard.selectedEmissionsByPeriodDashboard.selectedMonthYear;
        index = this.processedData.findIndex((item) => {
          return item && item.time_range === oldSelectedMonthYear;
        });
        if (index < 0) {
          index = this.processedData.length - 1;
        }
      }
      return index;
    },
    getMaxChart() {
      const maximumColumnsToShow = this.tab === 0 ? 8 : 12;
      if (window.innerWidth < MOBILE_WIDTH) {
        this.maxChartItem = maximumColumnsToShow;
        return;
      }
      const props = {
        maximumColumnsToShow,
        processedData: this.processedData,
        indexAtFirstBar: this.indexAtFirstBar,
        axisYItemsSource: this.axisYItemsSource,
        chartContainerRef: this.$refs.chartPeriod,
        MOBILE_WIDTH,
        getSummary: this.getSummary,
        axisYItemsCount: AXIS_Y_ITEM_COUNT
      }
      this.maxChartItem = getMaxChartItemsCount(props);
    },
    prepareChartDataValue() {
      if (!this.processedData.length) {
        return;
      }
      let index = 0;
      let chartValueOriginal = [];
      this.getMaxChart();
      do {
        chartValueOriginal.push(this.processedData[index + this.indexAtFirstBar]);
        index++;
      } while (index < this.maxChartItem);
      chartValueOriginal = chartValueOriginal.filter((item) => !!item);
      this.axisXItemsSource = chartValueOriginal.map((item) => {
        return {
          label: item?.time_range,
          value: item?.time_range,
          year: item?.year,
        };
      });
      this.chartValue = chartValueOriginal.map((item) => {
        let pastDurationsData = {};
        if (item.pastDurations && this.pastDurationChangeStatus) {
          pastDurationsData = {
            pastDurations: {
              ...item.pastDurations,
              emission_scope1_percent: '0%',
              emission_scope2_percent: '0%',
              emission_scope3_percent: '0%',
            },
          }; // object
        }
        return {
          ...item,
          emission_scope1_percent: '0%',
          emission_scope2_percent: '0%',
          emission_scope3_percent: '0%',
          ...pastDurationsData,
        };
      });
      const timeout = this.isFirstRender ? 1000 : 0;
      setTimeout(() => {
        const summary = this.getSummary(chartValueOriginal);
        [this.axisYItemsSource, this.min, this.max] = this.calcYAxis(summary);
        this.axisYItemsSource = this.axisYItemsSource.map((item) => ({
          ...item,
          textClone: item.text,
        }));
        this.updateAxisYItemsSourceFollowUnit();
        this.getWidthOfText();
        this.chartValue = chartValueOriginal.map((item) => {
          let pastDurationsData = {};
          if (item.pastDurations && this.pastDurationChangeStatus) {
            pastDurationsData = {
              pastDurations: {
                ...item.pastDurations,
                emission_scope1_percent: this.calcScopeEmission(item.pastDurations.emission_scope1),
                emission_scope2_percent: this.calcScopeEmission(item.pastDurations.emission_scope2),
                emission_scope3_percent: this.calcScopeEmission(item.pastDurations.emission_scope3),
                emission_scope4_percent: this.calcScopeEmission(item.pastDurations?.emission_scope4),
              },
            }; // object
          }
          return {
            ...item,
            emission_scope1_percent: this.calcScopeEmission(item.emission_scope1),
            emission_scope2_percent: this.calcScopeEmission(item.emission_scope2),
            emission_scope3_percent: this.calcScopeEmission(item.emission_scope3),
            emission_scope4_percent: this.calcScopeEmission(item?.emission_scope4),
            ...pastDurationsData,
            zero_value_percent: Math.abs(Number(this.min) / (this.max - this.min)) * 100 + '%',
          };
        });
      }, timeout);

      setTimeout(() => {
        this.transitionWidth = 0;

        this.footerWidth = this.$refs.yearLeft.clientWidth;
      }, timeout + 500);

      if (this.isFirstRender) {
        setTimeout(() => {
          this.isFirstRender = false;
        }, timeout + 2000);
      }
    },
    getSummary(chartValueOriginal) {
      const summary = chartValueOriginal.map((item) => {
        let emissionScopes = [
            item?.emission_scope1 ?? 0,
            item?.emission_scope2 ?? 0,
            item?.emission_scope3 ?? 0,
            item?.emission_scope4 ?? 0
        ];

        if (item?.pastDurations && this.pastDurationChangeStatus) {
            const emissionScopesPastData = [
                item.pastDurations?.emission_scope1 ?? 0,
                item.pastDurations?.emission_scope2 ?? 0,
                item.pastDurations?.emission_scope3 ?? 0,
                item.pastDurations?.emission_scope4 ?? 0
            ];
            emissionScopes = [...emissionScopes, ...emissionScopesPastData];
        }
        if (emissionScopes.every((scope) => $_helper_isNumberType(scope))) {
          let negativeTotal = 0;
          let positiveTotal = 0;

          emissionScopes.forEach((scope) => {
            const value = formatValue(scope);
            $_helper_isNegativeNumber(scope)
              ? (negativeTotal = formatBigNumber(
                  math.evaluate(`${math.bignumber(formatValue(negativeTotal))} + ${math.bignumber(value)}`),
                ))
              : (positiveTotal = formatBigNumber(
                  math.evaluate(`${math.bignumber(formatValue(positiveTotal))} + ${math.bignumber(value)}`),
                ));
          });

          return [negativeTotal, positiveTotal];
        }

        return emissionScopes.reduce((acc, scope) => acc + scope, 0);
      });
      return summary;
    },
    onClickChartItem(item) {
      this.selectedTimeRange = item.time_range;
      this.$emit('onUpdateStateDetail', item, this.pastDurationChangeStatus, window.innerWidth < TABLET_WIDTH);
    },
    onMouseOver(item) {
      this.hoverTimeRange = item.time_range;
    },
    onMouseLeave() {
      this.hoverTimeRange = '';
    },
    handleMouseOverNextBtn() {
      this.isMouseOverNext = true;
      this.nextIconUrl = '';
    },
    handleTouchStartNextBtn() {
      this.prevIconUrl = '';
      this.nextIconUrl = 'img/icons/next-hover.svg';
    },
    handleTouchEndNextBtn() {
      setTimeout(() => {
        if (this.isDisabledNext) {
          this.nextIconUrl = 'img/icons/next-disabled.svg';
        } else {
          this.nextIconUrl = 'img/icons/next.svg';
        }
      }, 200);
    },
    next() {
      this.prevIconUrl = '';
      if (this.indexAtFirstBar + this.maxChartItem >= this.processedData.length) {
        this.isDisabledNext = true;
        return;
      }

      this.isDisabledNext = false;
      this.indexAtFirstBar = this.indexAtFirstBar + 1;
      this.$store.dispatch('dashboard/changeIndexAtFirstBarByPeriodWithYearly', this.indexAtFirstBar);

      this.prepareChartDataValue();
      if (this.indexAtFirstBar + this.maxChartItem >= this.processedData.length) {
        this.isDisabledNext = true;
      }

      if (this.indexAtFirstBar > 0) {
        this.isDisabledPrev = false;
      } else {
        this.isDisabledPrev = true;
      }
    },
    handleMouseOverPrevBtn() {
      this.isMouseOverPrev = true;
      this.prevIconUrl = '';
    },
    handleTouchStartPrevBtn() {
      this.nextIconUrl = '';
      this.prevIconUrl = 'img/icons/prev-hover.svg';
    },
    handleTouchEndPrevBtn() {
      setTimeout(() => {
        if (this.isDisabledPrev) {
          this.prevIconUrl = 'img/icons/prev-disabled.svg';
        } else {
          this.prevIconUrl = 'img/icons/prev.svg';
        }
      }, 200);
    },
    prev() {
      this.nextIconUrl = '';
      if (this.indexAtFirstBar <= 0) {
        this.isDisabledPrev = true;
        return;
      }

      this.isDisabledPrev = false;

      this.indexAtFirstBar = this.indexAtFirstBar - 1;
      this.$store.dispatch('dashboard/changeIndexAtFirstBarByPeriodWithYearly', this.indexAtFirstBar);
      this.prepareChartDataValue();
      if (this.indexAtFirstBar <= 0) {
        this.isDisabledPrev = true;
      }

      if (this.indexAtFirstBar + this.maxChartItem < this.processedData.length) {
        this.isDisabledNext = false;
      }
    },
    isPositiveNumber(item) {
      return item.emission_scope1 >= 0 && item.emission_scope2 >= 0 && item.emission_scope3 >= 0;
    },
    calcScopeEmission(emissionsValue) {
      if (emissionsValue < 0) {
        return Math.abs(Number(emissionsValue) / this.min) * 100 + '%';
      } else {
        return Math.abs(Number(emissionsValue) / this.max) * 100 + '%';
      }
    },
    findValueZero(chartAxisY) {
      const existValueZero = chartAxisY.find((item) => item.value === 0);
      return existValueZero ? true : false;
    },
    formatNumber(num) {
      return num === 0 ? num : Number(formatValue(num));
    },
    removeDuplicate(array, key) {
      return array.filter((obj, index, self) => index === self.findIndex((t) => t[key] === obj[key]));
    },
    calcYAxis(totalList) {
      return prepareChartAxisY(totalList, AXIS_Y_ITEM_COUNT, this.getUnitName);
    },
    getWidthOfText() {
      const text =
        this.axisYItemsSource[0]?.text.length > addThoundSandCommaWithTwoDigit(this.axisYItemsSource[6]?.text.toString(), "", false, true).length
          ? this.axisYItemsSource[0]?.text
          : this.axisYItemsSource[6]?.text?.toString();
      // const width = Math.round(text.length * 60 / 8);
      const width = this.getWidthByTextContent(text);
      this.minWidth = width < 60 ? '60px' : width + 1 + 'px';
    },
    getWidthByTextContent(content) {
      let text = document.createElement('span');
      document.body.appendChild(text);
      text.style.width = 'auto';
      text.style.position = 'absolute';
      text.style.whiteSpace = 'no-wrap';
      text.style.font = 'Source Han Sans';
      text.style.fontSize = '11px';
      text.style.fontWeight = '500';
      text.style.letterSpacing = '0.03em';
      text.style.lineHeight = '18px';
      text.innerHTML = content;
      const width = text.clientWidth;
      document.body.removeChild(text);
      return width + 16; //16 is padding right
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/views/dashboard/components/emissions-by-period/yearly/chart-2/index.scss';

.chart__wrapper {
  .chart__header {
    .chart__header--left {
      padding: 0 0 16px;
    }

    .chart__header--center {
      padding-bottom: 16px;
    }

    .chart__header--right {
      padding: 0 0 16px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .chart__wrapper .chart__footer .chart__footer--center .axisX .axis-item span {
    color: $monoBlack;
  }
  .chart-column {
    &.chart__wrapper {
      .chart__footer {
        .chart__footer--center {
          // padding-right: 0;
          .axisX .axis-item {
            color: $monoBlack;
            word-break: break-all;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1365px) {
  .chart__wrapper {
    .chart__footer {
      .chart__footer--left {
        * {
          display: none;
        }
      }
      .chart__footer--center {
        padding-right: 8px;
      }
      .chart__footer--right {
        * {
          display: none;
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .chart__wrapper {
    .chart__body {
      .chart__body--center {
        .chart__body--right {
          display: block;
        }
      }
    }
  }
}

.chart-column {
  padding: 0;
  padding-top: 16px;
  &.chart__wrapper {
    .chart__body {
      .chart__body--left {
        z-index: 0;
      }
      .chart__body--center {
        .chart-bar {
          .bar {
            &.bar--scope1 {
              background: $bgDbScope1;
            }
            &.bar--scope2 {
              background: $bgDbScope2;
            }
            &.bar--scope3 {
              background: $bgDbScope3;
            }
          }
        }
      }
    } 
    .chart__header {
      .chart__header--center {
        .chart-legend {
          .legend {
            &.scope1 {
              .bar-color {
                background-color: $blueMid;
              }
            }
            &.scope2 {
              .bar-color {
                background-color: $bgDbScope2;
              }
            }
            &.scope3 {
              .bar-color {
                background-color: $bgDbScope3;
              }
            }
          }
        }
      }
    }
  }
}
.chart__wrapper {
  &.chart-column {
    .chart__body {
      display: flex;
      min-height: 368px;
      .chart__body--center {
        .chart-bar {
          min-height: 368px;
        }
      }
    }
  }
}
.tooltip {
  position: fixed;
  background-color: $backDark;
  color: $monoWhite;
  font-size: 12px;
  z-index: 101;
}
.tooltip-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid rgba(42, 42, 48, 0.1);
  background: $monoWhite;
  box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24),
    0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17),
    0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13),
    0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11),
    0px 36px 33px 0px rgba(160, 181, 186, 0.07);
  color: $backDark;
  padding: 5px;
  min-width: max-content;
}
.tooltip-title {
  color: $bgDark;
  font-size: 11px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 4px;
}
.tooltip-content {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
.tooltip-content-left,
.tooltip-content-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  .total-emission {
    display: flex;
    .unit {
      margin-left: -3px;
    }
  }
  span {
    color: $monoBlack;
    font-size: 11px;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.33px;
  }
}
.tooltip-content-left {
  span {
    font-weight: 700;
  }
  .scope1 {
    color: $bgDbScope1;
  }
  .scope2 {
    color: $bgDbScope2;
  }
  .scope3 {
    color: $bgDbScope3;
  }
}
.tooltip-content-right {
  span {
    font-weight: 500;
  }
}
.chart__wrapper {
  .chart__footer {
    .chart__footer--center {
      .axisX {
        .axis-item {
          height: 72px;
          color: $monoBlack;
          // margin: 0 4px;
          min-width: 57px;
        }
      }
    }
  }
  .chart__body {
    .chart__body--left {
      min-width: 53px;
      z-index: 0;
      .axisY {
        justify-content: unset;
        position: relative;
        .axis-item {
          position: absolute;
          right: 0;
        }
      }
    }
    .chart__body--center {
      .transition {
        background-color: $monoWhite;
      }
      .chart-bar {
        min-width: 57px;
        .bar {
          width: 16.9px;
        }
        @include large-desktop {
          .bar {
            width: 17.5px;
          }
        }
      }
      .number-wrapper {
        width: 100%;
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        position: relative;
        .positive-number {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-direction: column;
          position: absolute;
        }
        .number-positive-wrapper {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-direction: column;
          position: absolute;
          height: 100%;
          width: 20%;
          // .bar {
          //   width: 100% !important;
          // }
        }
        .negative-number {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          position: absolute;
          .bar--scope4 {
            position: absolute;
          }
        }
        .zero-crossline {
          border-top: 1px solid $whiteDark;
          height: 1px;
          width: 100%;
          position: absolute;
        }
      }
    }
  }
  .transparent-number {
    opacity: 0.2;
  }
}
@include desktop {
  .chart-column {
    padding: 16px;
  }
}
@media (max-width: 575px) {
  .chart-column {
    padding: 0px;
  }
}
</style>
<style lang="scss">
.show-pastDurations-change {
  padding-right: 16px;
  .v-label {
    color: $monoBlack;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.42px;
  }
}
</style>

