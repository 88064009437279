<template>
  <div
    class="filter-dashboard fix-top"
    id="headFix"
    v-if="isGotData"
    :style="{ width: checkMobile() ? '100%' : `calc(100vw - ${headerWidth})`, left: headerLeft}"
  >
    <div class="container pl-40-px list-filter" id="listFilter">
      <div class="row filter-items">
        <mix-label-pull-down-menu
          v-if="tab === 1"
          type="asset_select"
          :label="$t('dashboard_main.pulldown_select_asset_class')"
          v-model="asset_select"
          :items="assets"
          :initialValue="initialValueAsset"
          :isSelectFirst="true"
          :placeHolderText="$t('dashboard_main.label_all')"
          width="100%"
          class-list="one-line border-right border-left"
          :parentScrolled="parentScrolled"
          @updateData="updateData"
          idBox='0'
        />
        <MultipleFilter
          type="organizational_division"
          v-model="dataFilter.organizational_division"
          :label="$t('dashboard_main.pulldown_select_organization_division')"
          :items="organizationalDivisions"
          :isSelectFirst="true"
          :placeHolderText="$t('dashboard_main.label_all')"
          width="100%"
          class-list="one-line border-left border-right"
          maxWidth='400px'
          :parentScrolled="parentScrolled"
          @updateData="updateData"
          idBox='1'
        />
        <MultipleFilter
          type="company_name"
          :label="$t('dashboard_main.pulldown_select_corporate_name')"
          v-model="dataFilter.company_name"
          :items="companies"
          :isSelectFirst="true"
          :placeHolderText="$t('dashboard_main.label_all')"
          width="100%"
          class-list="one-line border-right"
          :parentScrolled="parentScrolled"
          @updateData="updateData"
          idBox='2'
        />
        <MultipleFilter
          type="business_name"
          v-if="listLayersVisible.includes('business_name')"
          :label="listLayers[listLayersVisible.indexOf('business_name')].layer_name ? listLayers[listLayersVisible.indexOf('business_name')].layer_name : '&nbsp;'"
          v-model="dataFilter.business_name"
          :items="business"
          :isSelectFirst="true"
          :placeHolderText="$t('dashboard_main.label_all')"
          width="100%"
          class-list="one-line border-right"
          :parentScrolled="parentScrolled"
          @updateData="updateData"
          idBox='3'
        />
        <MultipleFilter
          v-if="listLayersVisible.includes('country')"
          type="country"
          :label="listLayers[listLayersVisible.indexOf('country')].layer_name ? listLayers[listLayersVisible.indexOf('country')].layer_name : '&nbsp;'"
          v-model="dataFilter.country"
          :items="countries"
          :isSelectFirst="true"
          :placeHolderText="$t('dashboard_main.label_all')"
          width="100%"
          class-list="one-line border-right"
          :parentScrolled="parentScrolled"
          @updateData="updateData"
          idBox='4'
        />
        <MultipleFilter
          v-if="listLayersVisible.includes('layer_3')"
          type="layer3"
          :label="listLayers[listLayersVisible.indexOf('layer_3')].layer_name ? listLayers[listLayersVisible.indexOf('layer_3')].layer_name : '&nbsp;'"
          v-model="dataFilter.layer3"
          :items="listLayer3"
          :isSelectFirst="true"
          :placeHolderText="$t('dashboard_main.label_all')"
          width="100%"
          class-list="one-line border-right"
          :parentScrolled="parentScrolled"
          @updateData="updateData"
          idBox='5'
        />
        <MultipleFilter
          v-if="listLayersVisible.includes('layer_4')"
          type="layer4"
          :label="listLayers[listLayersVisible.indexOf('layer_4')].layer_name ? listLayers[listLayersVisible.indexOf('layer_4')].layer_name : '&nbsp;'"
          v-model="dataFilter.layer4"
          :items="listLayer4"
          :isSelectFirst="true"
          :placeHolderText="$t('dashboard_main.label_all')"
          width="100%"
          class-list="one-line border-right"
          :parentScrolled="parentScrolled"
          @updateData="updateData"
          idBox='6'
        />
        <MultipleFilter
          v-if="listLayersVisible.includes('layer_5')"
          type="layer5"
          :label="listLayers[listLayersVisible.indexOf('layer_5')].layer_name ? listLayers[listLayersVisible.indexOf('layer_5')].layer_name : '&nbsp;'"
          v-model="dataFilter.layer5"
          :items="listLayer5"
          :isSelectFirst="true"
          :placeHolderText="$t('dashboard_main.label_all')"
          width="100%"
          class-list="one-line border-right"
          :parentScrolled="parentScrolled"
          @updateData="updateData"
          idBox='7'
        />
        <MultipleFilter
          v-if="listLayersVisible.includes('layer_6')"
          type="layer6"
          :label="listLayers[listLayersVisible.indexOf('layer_6')].layer_name ? listLayers[listLayersVisible.indexOf('layer_6')].layer_name : '&nbsp;'"
          v-model="dataFilter.layer6"
          :items="listLayer6"
          :isSelectFirst="true"
          :placeHolderText="$t('dashboard_main.label_all')"
          width="100%"
          class-list="one-line border-right"
          :parentScrolled="parentScrolled"
          @updateData="updateData"
          idBox='8'
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import MixLabelPullDownMenu from "@/components/pulldown/MixLabelPullDownMenu";
import MultipleFilter from "@/components/products/multiple-filter/pulldown";
import { getDashboardBranch } from "@/api/branch";
import { ORGANIZATIONAL_DATA, TEXT_NULL } from "@/constants/dashboard";
import { ASSET_ITEMS } from '@/constants/pcaf';
export default {
  components: { MixLabelPullDownMenu, MultipleFilter },
  props: {
    tab: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      dataFilter: {
        organizational_division : [],
        company_name : [],
        business_name : [],
        country : [],
        layer3 : [],
        layer4 : [],
        layer5 : [],
        layer6 : [],
      },
      organizationalDivisions : [],
      companies : [],
      business : [],
      countries : [],
      listLayer3 : [],
      listLayer4 : [],
      listLayer5 : [],
      listLayer6 : [],
      filterDataList : [],
      selectedFirst : null,
      filterIndex : [],
      filterTypes : [
        'organizational_division',
        'company_name',
        'business_name',
        'country',
        'layer3',
        'layer4',
        'layer5',
        'layer6',
      ],
      flag : true,
      listLayersVisible : [],
      listLayers : [],
      isGotData : false,
      parentScrolled : false,
      assets: [],
      asset_select: "",
      initialValueAsset: "",
    };
  },
  async mounted() {
    this.flag           = false;
    const contractor_id = this.$store.state.userData.contractor;
    this.getAssetItems();
    await this.getDashboardBranch(contractor_id);
    const listFilter = document.getElementById("listFilter");
    let self         = this;
    let timer        = null;
    listFilter?.addEventListener("scroll", function() {
      self.parentScrolled = true;
      if (timer !== null) {
        clearTimeout(timer);
      }
      timer = setTimeout(function() {
        self.parentScrolled = false;
      }, 50);
    });
  },
  computed: {
    ...mapState('commonApp', ['isExpand']),
    headerWidth() {
      const expandedSideBarWidth = '236px'
      const collapseSideBarWidth = '73px'
      return this.isExpand ? expandedSideBarWidth : collapseSideBarWidth
    },
    headerLeft() {
      let leftWidth = '0px';
      if(!this.checkMobile()) {
        leftWidth = this.isExpand ? '236px' : '73px';
      }
      return leftWidth
    }
  },
  methods : {
    ...mapActions('dashboard', ['changeBranchIds']),
    checkMobile() {
      return window.innerWidth < 1024;
    },
    updateData(data, type) {
      // handle update asset data
      if (type === 'asset_select') {
        this.$emit('updateAssetId', data);
        return
      }
      // just open/close and do nothing
      if (type === 'asset_select') {
        this.$emit('updateAssetId', data ? data : 0);
        return;
      }
      if (data.length === 0 && this.dataFilter[type].length === 0) return;
      // dont update data
      // if (data.length === this.dataFilter[type].length) return;
      // data change
      if (data.length > 0) {
        this.dataFilter[type] = data;
        this.addFilterIndex(type);
        this.updateFilter(type);
      } else {
      // nếu unchecked all data
        this.dataFilter[type] = [];
        this.addFilterIndex(type, true);
        this.updateFilter(type);
      }
      Promise.all([
        this.removeDuplicateData(this.organizationalDivisions, 'organizationalDivisions'),
        this.removeDuplicateData(this.companies, 'companies'),
        this.removeDuplicateData(this.business, 'business'),
        this.removeDuplicateData(this.countries, 'countries'),
        this.removeDuplicateData(this.listLayer3, 'listLayer3'),
        this.removeDuplicateData(this.listLayer4, 'listLayer4'),
        this.removeDuplicateData(this.listLayer5, 'listLayer5'),
        this.removeDuplicateData(this.listLayer6, 'listLayer6'),
      ]);
      Promise.all([
        this.sortItemToBottom(this.business, 'business'),
        this.sortItemToBottom(this.countries, 'countries'),
        this.sortItemToBottom(this.listLayer3, 'listLayer3'),
        this.sortItemToBottom(this.listLayer4, 'listLayer4'),
        this.sortItemToBottom(this.listLayer5, 'listLayer5'),
        this.sortItemToBottom(this.listLayer6, 'listLayer6'),
      ])
      this.organiListSort();
    },
    getAssetItems() {
      this.assets.push({
        name: this.$t("dashboard_main.label_all"),
        value: 0,
      })
      ASSET_ITEMS.forEach(item => {
        this.assets.push({
          name: item.name,
          value: item.id,
        })
      });
    },
    async getDashboardBranch(contractor_id) {
      await getDashboardBranch(contractor_id).then((response) => {
        this.prepareFilterData(response.data);
        this.isGotData         = true;
        this.listLayers        = response.layer;
        this.listLayersVisible = response.layer.map((item) => item.layer_field);
        this.updateFilterDataToStore(true);
      });
    },
    async prepareFilterData(data) {
      this.resetFilter();
      let dataIdSort = [];
      let dataSort   = [];
      data.forEach((organiItems) => {
        this.addOrganizational(organiItems);
        organiItems.child?.forEach((company) => {
          company?.child.forEach((companyItem) => {
            if (companyItem?.child === undefined) {
              dataIdSort.push(companyItem.id);
              dataSort.push(companyItem);
            }
            companyItem?.child?.forEach((businessItem) => {
              if (businessItem?.child === undefined) {
                dataIdSort.push(businessItem.id);
                dataSort.push(businessItem);
              }
              businessItem?.child?.forEach((layer3Item) => {
                if (layer3Item?.child === undefined) {
                  dataIdSort.push(layer3Item.id);
                  dataSort.push(layer3Item);
                }
                layer3Item?.child?.forEach((layer4Item) => {
                  if (layer4Item?.child === undefined) {
                    dataIdSort.push(layer4Item.id);
                    dataSort.push(layer4Item);
                  }
                  layer4Item?.child?.forEach((layer5Item) => {
                    if (layer5Item?.child === undefined) {
                      dataIdSort.push(layer5Item.id);
                      dataSort.push(layer5Item);
                    }
                    layer5Item?.child?.forEach((layer6Item) => {
                      if (layer6Item?.child === undefined) {
                        dataIdSort.push(layer6Item.id);
                        dataSort.push(layer6Item);
                      }
                      layer6Item?.child?.forEach((item) => {
                        dataIdSort.push(item.id);
                        dataSort.push(item);
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
      dataIdSort
        .sort((a, b) => {
          return a - b;
        })
        .forEach((id) => {
        dataSort.forEach((item, key) => {
          if (item.id === id) {
            ['business_name', 'country', 'layer_3', 'layer_4', 'layer_5', 'layer_6'].forEach((key) => {
              item[key] = item[key] === null ? this.$t("register_data.checkbox_blank") : item[key]
            })
            this.addCompany({ company: item.company_name });
            this.addBusiness({ business_name: item.business_name });
            this.addCountry({ country: item.country });
            this.addLayer3({ layer_3: item.layer_3 });
            this.addLayer4({ layer_4: item.layer_4 });
            this.addLayer5({ layer_5: item.layer_5 });
            this.addLayer6({ layer_6: item.layer_6 });
            
            this.filterDataList.push(item);
            dataSort.splice(key, 1);
          }
        });
      });
      Promise.all([
        this.removeDuplicateData(this.organizationalDivisions, 'organizationalDivisions'),
        this.removeDuplicateData(this.companies, 'companies'),
        this.removeDuplicateData(this.business, 'business'),
        this.removeDuplicateData(this.countries, 'countries'),
        this.removeDuplicateData(this.listLayer3, 'listLayer3'),
        this.removeDuplicateData(this.listLayer4, 'listLayer4'),
        this.removeDuplicateData(this.listLayer5, 'listLayer5'),
        this.removeDuplicateData(this.listLayer6, 'listLayer6'),
      ]);
      Promise.all([
        this.sortItemToBottom(this.business, 'business'),
        this.sortItemToBottom(this.countries, 'countries'),
        this.sortItemToBottom(this.listLayer3, 'listLayer3'),
        this.sortItemToBottom(this.listLayer4, 'listLayer4'),
        this.sortItemToBottom(this.listLayer5, 'listLayer5'),
        this.sortItemToBottom(this.listLayer6, 'listLayer6'),
      ])
      this.organiListSort();
      this.flag = true;
    },
    addOrganizational(organiItems) {
      if (this.selectedFirst === "organizational_division") return;
      this.organizationalDivisions.push({
        name : organiItems.organizational_division,
        value: organiItems.id
      });
    },
    addCompany(company) {
      if (this.selectedFirst === "company_name") return;
      this.companies.push({
        name : company.company,
        value: company.company
      });
    },
    addBusiness(business) {
      if (this.selectedFirst === "business_name") return;
      this.business.push({
        name : business?.business_name || TEXT_NULL,
        value: business?.business_name || "null"
      });
    },
    addCountry(country) {
      if (this.selectedFirst === "country") return;
      this.countries.push({
        name : country.country || TEXT_NULL,
        value: country.country || "null"
      });
    },
    addLayer3(layer_3) {
      if (this.selectedFirst === "layer3") return;
      this.listLayer3.push({
        name : layer_3.layer_3 || TEXT_NULL,
        value: layer_3.layer_3 || "null"
      });
    },
    addLayer4(layer_4) {
      if (this.selectedFirst === "layer4") return;
      this.listLayer4.push({
        name : layer_4.layer_4 || TEXT_NULL,
        value: layer_4.layer_4 || "null"
      });
    },
    addLayer5(layer_5) {
      if (this.selectedFirst === "layer5") return;
      this.listLayer5.push({
        name : layer_5.layer_5 || TEXT_NULL,
        value: layer_5.layer_5 || "null"
      });
    },
    addLayer6(layer_6) {
      if (this.selectedFirst === "layer6") return;
      this.listLayer6.push({
        name : layer_6.layer_6 || TEXT_NULL,
        value: layer_6.layer_6 || "null"
      });
    },
    sortItemToBottom(data, type) {
      this[type] = [...new Map(data.map((item) => [item["value"], item])).values()].sort((a, b) => {
        return (a.value === this.$t("register_data.checkbox_blank")) - (b.value === this.$t("register_data.checkbox_blank"));
      });
    },
    updateFilter(type) {
      if (this.filterIndex.length === 0) {
        this.updateGetAllData();
        this.selectedFirst = null;
        this.$emit('update', [], true);
        return;
      }
      this.filterCondition(type);
      if (!this.selectedFirst) {
        this.selectedFirst = type;
      }
      // get branch id
      this.getBranchIdByFilterData();
    },
    resetFilter(type, isRemove = false) { 
      let items = [];
      if (this.filterIndex.length === 0) {
        items = [
          'organizational_division',
          'country',
          'company_name',
          'business_name',
          'layer3',
          'layer4',
          'layer5',
          'layer6'
        ];
      } else {
        const filterIndexLength = this.filterIndex.indexOf(type) < 0 ? this.filterIndex.length - 1 : this.filterIndex.indexOf(type);
        for (let i = 0; i <= filterIndexLength; i++) {
          items.push(this.filterIndex[i]);
        }
      }
      if (isRemove) {
        items = this.filterTypes.filter((item) => !items.includes(item)); // get elements not in filterTypes
      }
      let firstSelectItem = {
        name : this.$t("product_dashboard_main.label_all"),
        value: this.$t("product_dashboard_main.label_all")
      };
      items.forEach((key) => {
        if (key === 'organizational_division' && this.selectedFirst !== "organizational_division") {
          this.organizationalDivisions.length = 0;
          this.organizationalDivisions.push(firstSelectItem);
        }
        if (key === 'company_name' && this.selectedFirst !== "company_name") {
          this.companies.length = 0;
          this.companies.push(firstSelectItem);
        }
        if (key === 'business_name' && this.selectedFirst !== "business_name") {
          this.business.length = 0;
          this.business.push(firstSelectItem);
        }
        if (key === 'country' && this.selectedFirst !== "country") {
          this.countries.length = 0;
          this.countries.push(firstSelectItem);
        }
        if (key === 'layer3' && this.selectedFirst !== "layer3") {
          this.listLayer3.length = 0;
          this.listLayer3.push(firstSelectItem);
        }
        if (key === 'layer4' && this.selectedFirst !== "layer4") {
          this.listLayer4.length = 0;
          this.listLayer4.push(firstSelectItem);
        }
        if (key === 'layer5' && this.selectedFirst !== "layer5") {
          this.listLayer5.length = 0;
          this.listLayer5.push(firstSelectItem);
        }
        if (key === 'layer6' && this.selectedFirst !== "layer6") {
          this.listLayer6.length = 0;
          this.listLayer6.push(firstSelectItem);
        }
      });
    },
    updateGetAllData() {
      this.resetFilter();
      this.filterDataList.forEach((item) => {
        this.addOrganizational({
          id: item.organizational_division,
          organizational_division: ORGANIZATIONAL_DATA[item.organizational_division]
        });
        this.addCompany({ company: item.company_name });
        this.addBusiness({ business_name: item.business_name });
        this.addCountry({ country: item.country });
        this.addLayer3({ layer_3: item.layer_3 });
        this.addLayer4({ layer_4: item.layer_4 });
        this.addLayer5({ layer_5: item.layer_5 });
        this.addLayer6({ layer_6: item.layer_6 });
      });
    },
    removeDuplicateData(arr, type) {
      this[type] = [...new Map(arr.map((item) => [item['value'], item])).values()];
    },
    addFilterIndex(type, isRemove = false) {
      const existIndex = this.filterIndex.findIndex((item) => {
        return item === type;
      });
      if (isRemove) {
        this.filterIndex.splice(existIndex);
      }
      if (existIndex < 0) {
        this.filterIndex.push(type);
      }
    },
    filterOrganizational(item) {
      return (this.dataFilter.organizational_division.length !== 0 && this.dataFilter.organizational_division.includes(item.organizational_division)) || this.dataFilter.organizational_division.length === 0;
    },
    filterCompanies(item) {
      return (this.dataFilter.company_name.length !== 0 && this.dataFilter.company_name.includes(item.company_name)) || this.dataFilter.company_name.length === 0;
    },
    filterBusiness(item) {
      return ((this.dataFilter.business_name.length !== 0 && this.dataFilter.business_name.includes(item.business_name)) ||
      this.dataFilter.business_name.length === 0 ||
      (this.dataFilter.business_name.includes(TEXT_NULL)  && !item.business_name)
      )
    },
    filterCountries(item) {
      return (
        (this.dataFilter.country.length !== 0 && this.dataFilter.country.includes(item.country)) ||
        this.dataFilter.country.length === 0 ||
        (this.dataFilter.country.includes(TEXT_NULL)  && !item.country)
      );
    },
    filterListLayer3(item) {
      return (
        (this.dataFilter.layer3.length !== 0 && this.dataFilter.layer3.includes(item.layer_3)) ||
        this.dataFilter.layer3.length === 0 ||
        (this.dataFilter.layer3.includes(TEXT_NULL) && !item.layer_3)
      );
    },
    filterListLayer4(item) {
      return (
        (this.dataFilter.layer4.length !== 0 && this.dataFilter.layer4.includes(item.layer_4)) ||
        this.dataFilter.layer4.length === 0 ||
        (this.dataFilter.layer4.includes(TEXT_NULL) && !item.layer_4)
      );
    },
    filterListLayer5(item) {
      return (
        (this.dataFilter.layer5.length !== 0 && this.dataFilter.layer5.includes(item.layer_5)) ||
        this.dataFilter.layer5.length === 0 ||
        (this.dataFilter.layer5.includes(TEXT_NULL) && !item.layer_5)
      );
    },
    filterListLayer6(item) {
      return (
        (this.dataFilter.layer6.length !== 0 && this.dataFilter.layer6.includes(item.layer_6)) ||
        this.dataFilter.layer6.length === 0 ||
        (this.dataFilter.layer6.includes(TEXT_NULL) && !item.layer_6)
      );
    },
    filterCondition(type) {
      if (this.filterIndex.length === 0) return;
      this.resetFilter(type, true);
      this.filterDataList.forEach((item) => {
        this.filterLayer(type, item);
      });
      this.resetSelectedData(type);
    },
    filterLayer(type, item) {
      let filterDataConditionList = [];
      const filterIndexLength = this.filterIndex.indexOf(type) < 0 ? this.filterIndex.length - 1 : this.filterIndex.indexOf(type);
      for (let i = 0; i <= filterIndexLength; i++) {
        filterDataConditionList.push(this.filterIndex[i]);
      }
      for (let i = 0; i <= filterDataConditionList.length - 1; i++) {
        if (!this.filterDataByIndex(this.filterIndex[i], item)) {
          return;
        }
      }
      switch (this.filterIndex[this.filterIndex.indexOf(type)]) {
        case "organizational_division":
          if (!this.filterOrganizational(item)) return;
          break;
        case "company_name":
          if (!this.filterCompanies(item)) return;
          break;
        case "business_name":
          if (!this.filterBusiness(item)) return;
          break;
        case "country":
          if (!this.filterCountries(item)) return;
          break;
        case "layer3":
          if (!this.filterListLayer3(item)) return;
          break;
        case "layer4":
          if (!this.filterListLayer4(item)) return;
          break;
        case "layer5":
          if (!this.filterListLayer5(item)) return;
          break;
        case "layer6":
          if (!this.filterListLayer6(item)) return;
          break;
      }
      this.addFilterDataLoop(type, item);
    },
    resetSelectedData(type) {
      let types = [];
      let notResetSelectedData = [];
      let dataList = [];
      const filterIndexLength = this.filterIndex.indexOf(type) < 0 ? this.filterIndex.length - 1 : this.filterIndex.indexOf(type);
      for (let i = 0; i <= filterIndexLength; i++) {
        notResetSelectedData.push(this.filterIndex[i]);
      }
      types = this.filterTypes.filter((item) => !notResetSelectedData.includes(item)); // reset selected data in filterTypes
      types.forEach((type) => {
        switch (type) {
          case "organizational_division":
            if (this.dataFilter[notResetSelectedData[notResetSelectedData.length - 1]].includes(this.$t("product_dashboard_main.label_all"))) {
              this.dataFilter.organizational_division.length = 0;
            } else {
              dataList = this.organizationalDivisions.map(item => item.value);
              this.dataFilter.organizational_division.map(item => {
                if (!dataList.includes(item)) {
                  const indexItem = this.dataFilter.organizational_division.indexOf(item);
                  this.dataFilter.organizational_division.splice(indexItem, 1);
                }
              })
            }
            break;
          case "company_name":
            // handle for the last filterIndex select "All"
            if (this.dataFilter[notResetSelectedData[notResetSelectedData.length - 1]].includes(this.$t("product_dashboard_main.label_all"))) {
              this.dataFilter.company_name.length = 0;
            } else {
              dataList = this.companies.map(item => item.value);
              this.dataFilter.company_name.map(item => {
                if (!dataList.includes(item)) {
                  const indexItem = this.dataFilter.company_name.indexOf(item);
                  this.dataFilter.company_name.splice(indexItem, 1);
                }
              })
            }
            break;
          case "business_name":
            if (this.dataFilter[notResetSelectedData[notResetSelectedData.length - 1]].includes(this.$t("product_dashboard_main.label_all"))) {
              this.dataFilter.business_name.length = 0;
            } else {
              dataList = this.business.map(item => item.value);
              this.dataFilter.business_name.forEach(item => {
                if (!dataList.includes(item)) {
                  const indexItem = this.dataFilter.business_name.indexOf(item);
                  this.dataFilter.business_name.splice(indexItem, 1);
                }
              })
            }
            break;
          case "country":
            if (this.dataFilter[notResetSelectedData[notResetSelectedData.length - 1]].includes(this.$t("product_dashboard_main.label_all"))) {
              this.dataFilter.country.length = 0;
            } else {
              dataList = this.countries.map(item => item.value);
              this.dataFilter.country.map(item => {
                if (!dataList.includes(item)) {
                  const indexItem = this.dataFilter.country.indexOf(item);
                  this.dataFilter.country.splice(indexItem, 1);
                }
              })
            }
            break;
          case "layer3":
            if (this.dataFilter[notResetSelectedData[notResetSelectedData.length - 1]].includes(this.$t("product_dashboard_main.label_all"))) {
              this.dataFilter.layer3.length = 0;
            } else {
              dataList = this.listLayer3.map(item => item.value);
              this.dataFilter.layer3.map(item => {
                if (!dataList.includes(item)) {
                  const indexItem = this.dataFilter.layer3.indexOf(item);
                  this.dataFilter.layer3.splice(indexItem, 1);
                }
              })
            }
            break;
          case "layer4":
            if (this.dataFilter[notResetSelectedData[notResetSelectedData.length - 1]].includes(this.$t("product_dashboard_main.label_all"))) {
              this.dataFilter.layer4.length = 0;
            } else {
              dataList = this.listLayer4.map(item => item.value);
              this.dataFilter.layer4.map(item => {
                if (!dataList.includes(item)) {
                  const indexItem = this.dataFilter.layer4.indexOf(item);
                  this.dataFilter.layer4.splice(indexItem, 1);
                }
              })
            }
            break;
          case "layer5":
            if (this.dataFilter[notResetSelectedData[notResetSelectedData.length - 1]].includes(this.$t("product_dashboard_main.label_all"))) {
              this.dataFilter.layer5.length = 0;
            } else {
              dataList = this.listLayer5.map(item => item.value);
              this.dataFilter.layer5.map(item => {
                if (!dataList.includes(item)) {
                  const indexItem = this.dataFilter.layer5.indexOf(item);
                  this.dataFilter.layer5.splice(indexItem, 1);
                }
              })
            }
            break;
          case "layer6":
            if (this.dataFilter[notResetSelectedData[notResetSelectedData.length - 1]].includes(this.$t("product_dashboard_main.label_all"))) {
              this.dataFilter.layer6.length = 0;
            } else {
              dataList = this.listLayer6.map(item => item.value);
              this.dataFilter.layer6.map(item => {
                if (!dataList.includes(item)) {
                  const indexItem = this.dataFilter.layer6.indexOf(item);
                  this.dataFilter.layer6.splice(indexItem, 1);
                }
              })
            }
            break;
        }
      });
    },
    filterDataByIndex(type, item) {
      switch (type) {
        case "organizational_division":
          return this.filterOrganizational(item);
        case "company_name":
          return this.filterCompanies(item);
        case "business_name":
          return this.filterBusiness(item);
        case "country":
          return this.filterCountries(item);
        case "layer3":
          return this.filterListLayer3(item);
        case "layer4":
          return this.filterListLayer4(item);
        case "layer5":
          return this.filterListLayer5(item);
        case "layer6":
          return this.filterListLayer6(item);
        default:
          return true;
      }
    },
    addFilterDataLoop(type, item) {
      let types = [];
      let filterDataList = [];
      const filterIndexLength = this.filterIndex.indexOf(type) < 0 ? this.filterIndex.length - 1 : this.filterIndex.indexOf(type);
      for (let i = 0; i <= filterIndexLength; i++) {
        filterDataList.push(this.filterIndex[i]);
      }
      types = this.filterTypes.filter((item) => !filterDataList.includes(item)); // get elements not in filterTypes
      types.forEach((type) => {
        switch (type) {
          case "organizational_division":
            this.addOrganizational({
              id                     : item.organizational_division,
              organizational_division: ORGANIZATIONAL_DATA[item.organizational_division]
            });
            break;
          case "company_name":
            this.addCompany({ company: item.company_name });
            break;
          case "business_name":
            this.addBusiness({ business_name: item.business_name });
            break;
          case "country":
            this.addCountry({ country: item.country });
            break;
          case "layer3":
            this.addLayer3({ layer_3: item.layer_3 });
            break;
          case "layer4":
            this.addLayer4({ layer_4: item.layer_4 });
            break;
          case "layer5":
            this.addLayer5({ layer_5: item.layer_5 });
            break;
          case "layer6":
            this.addLayer6({ layer_6: item.layer_6 });
            break;
        }
      });
    },
    organiListSort() {
      this.organizationalDivisions.sort((a, b) => {
        return a.value - b.value;
      })
    },
    async updateFilterDataToStore(isGetAllFilter = false) {
      this.getBranchIdByFilterData(isGetAllFilter);
    },
    getBranchIdByFilterData(isGetAllFilter = false) {
      let branchIds = [];
      if (isGetAllFilter) {
        this.filterDataList.forEach(item => {
          branchIds.push(item.id);
        });
      } else {
        this.filterDataList.forEach(item => {
          for (let i = 0; i < this.filterIndex.length; i++) {
            if (!this.filterDataByIndex(this.filterIndex[i], item)) {
              return;
            }
          }
          branchIds.push(item.id);
        })
      }
      this.changeBranchIds(branchIds);
      this.$emit('update', branchIds, isGetAllFilter);
    },
  }
};
</script>

<style lang="scss" scoped>
#headFix {
  transition: 0.1s ease-out;
  transition-duration: 0.1s;
}

#headFix.fix-top {
  display: block;
  z-index: 99;
  position: fixed;
  top: 84px;
  background: $monoOffWhite;
  left: 0;
  max-width: 100%;
  width: 100%;
  border-top: 1px solid rgba(42, 42, 48, 0.1);
  box-shadow: 0 4.322566509246826px 7.315112113952637px 0 rgba(160, 181, 186, 0.19),
  0 14.518600463867188px 24.5699405670166px 0 rgba(160, 181, 186, 0.11),
  0 65px 110px 0 rgba(160, 181, 186, 0.08);
  padding-left: 20px;
  padding-right: 20px;
  .border-right {
    border-right: 1px solid rgba(42, 42, 48, 0.1);
  }

  .border-left {
    border-left: 1px solid rgba(42, 42, 48, 0.1);
  }

  .list-filter {
    overflow: auto;
    white-space: nowrap;
    overflow-x: auto !important;
  }

  .list-filter::-webkit-scrollbar {
    height: 0px;
  }

  .list-filter::-webkit-scrollbar-thumb:horizontal {
    border-radius: 10px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background: #bfd4d9;
  }

  /* Track */
  .list-filter::-webkit-scrollbar-track:horizontal {
    background: #e3eded;
  }

  /* Handle on hover */
  .list-filter::-webkit-scrollbar-thumb:horizontal:hover {
    background: #7a8b8d;
  }

  .filter-items {
    display: inline-block;
    vertical-align: top;
    height: 43px;
  }

  .one-line {
    display: inline-block;
  }
}

@include desktop {
  #headFix.fix-top {
    padding-left: 0;
    border-top: unset;
  }

  .border-left {
    border-left: 1px solid rgba(42, 42, 48, 0.1);
  }

  .pl-40-px {
    padding-left: 40px;
  }

  .list-filter::-webkit-scrollbar {
    height: 7px !important;
  }

  .list-filter {
    overflow: hidden !important;

    &:hover {
      overflow-x: auto !important;
    }
  }
}
</style>
