<template>
  <div class="chart__wrapper chart-column chart-dashboard-category" ref="chartContainer">
    <div class="show-pastDurations-change" v-if="selectedTab !== 0 ">
      <check-box-home-standard
        @change="handleChangeCheckBoxPastDurations"
        v-model="pastDurationChangeStatus"
        :label="getTitleCompareCategory"
      />
    </div>
    <div class="chart">
      <!-- body -->
      <div class="chart__body">
        <div class="chart__body--left" ref="yearLeft" :style="{ 'min-width': minWidth }">
          <div class="axisY">
            <div
              class="axis-item"
              v-for="(item, index) in axisYItemsSource"
              :key="index + '-axis-y'"
              :class="{ unit: index === axisYItemsCount }"
              :style="{ bottom: `${item.percent}%` }"
            >
              {{ item.text }}
            </div>
          </div>
        </div>
        <div class="chart__body--center" ref="bodyCenter" @scroll="syncScroll('body')" :style="{ 'max-width': maxWidth }">
          <div class="chart-body-content">
          <div class="transition" :style="{ width: transitionWidth }"></div>
          <div
            class="chart-bar"
            @click="selectedCategory(item, index)"
            @mouseover="onMouseOver(item)"
            @mouseleave="hoverTimeRange = ''"
            :class="{ selected: item.rangeX === selectedTimeRange, hovered: item.rangeX === hoverTimeRange }"
            v-for="(item, index) in chartValue"
            :key="index + '-bar-chart'"
          >
          <div class="active" :style="heightSelect"></div>
          <div class="hover" :style="heightSelect"></div>
            <div v-if="hasNegativeNumber" class="number-wrapper">
              <!-- current value -->
              <div
                v-if="!isNegativeTotalData(item)"
                class="positive-number"
                :style="{
                  height: `calc(100% - ${item.zero_value_percent})`,
                  bottom: item.zero_value_percent,
                  ...getStylesColumn(item, 'margin-left'),
                }"
              >
                <div
                  class="bar bar--scope1 animation"
                  :style="{ height: item.emission_percent, background: getBgColor(item.scope, item.category) }"
                  v-if="item.value > 0"
                  @mouseover="toolTipOn(item)"
                  @mouseleave="toolTipOff"
                  @mousemove="updateTooltipPosition"
                ></div>
              </div>

              <!-- pass value -->
              <div
                v-if="pastDurationChangeStatus && item.pastDurations"
                class="positive-number transparent-number"
                :style="{
                  height: `calc(100% - ${item.zero_value_percent})`,
                  bottom: item.zero_value_percent,
                  ...getStylesColumn(item, 'margin-right'),
                }"
              >
                <div
                  class="bar bar--scope1 animation"
                  :style="{ height: item.past_emission_percent, background: getBgColor(item.pastDurations.scope, item.category) }"
                  v-if="item.pastDurations.value > 0"
                ></div>
              </div>
              <div class="zero-crossline" :style="{ height: item.zero_value_percent, width: '100%' }"></div>
              <!-- current value -->
              <div
                v-if="isNegativeTotalData(item)"
                class="negative-number"
                :style="{ height: item.zero_value_percent, ...getStylesColumn(item, 'margin-left') }"
              >
                <div
                  class="bar bar--scope1 animation"
                  :style="{ height: item.emission_percent, background: getBgColor(item.scope, item.category) }"
                  v-if="item.value < 0"
                  @mouseover="toolTipOn(item)"
                  @mouseleave="toolTipOff"
                  @mousemove="updateTooltipPosition"
                ></div>
              </div>

              <!-- pass value -->
              <div
                v-if="pastDurationChangeStatus && item.pastDurations"
                class="negative-number transparent-number"
                :style="{
                  height: item.zero_value_percent,
                  ...getStylesColumn(item, 'margin-right'),
                }"
              >
                <div
                  class="bar bar--scope1 animation"
                  :style="{ height: item.past_emission_percent, background: getBgColor(item.pastDurations.scope, item.category) }"
                  v-if="item.pastDurations.value < 0"
                ></div>
              </div>
            </div>
            <div v-else class="number-wrapper">
              <!-- current value -->
              <div
                class="bar bar--scope1 animation"
                :style="{
                  height: item.emission_percent,
                  background: getBgColor(item.scope, item.category),
                  ...getStylesColumn(item, 'margin-left'),
                }"
                @mouseover="toolTipOn(item)"
                @mouseleave="toolTipOff"
                @mousemove="updateTooltipPosition"
              ></div>

              <!-- pass value -->
              <div
                v-if="pastDurationChangeStatus && item.pastDurations"
                class="number-positive-wrapper transparent-number"
              >
                <div
                  class="bar bar--scope1 animation"
                  :style="{
                    height: item.past_emission_percent,
                    background: getBgColor(item.pastDurations.scope, item.category),
                    ...getStylesColumn(item, 'margin-right'),
                  }"
                ></div>
              </div>
            </div>
            <!-- tooltip -->
            <div
              id="tooltip-column"
              class="tooltip"
              :style="{ left: tooltipPosition.x + 'px', top: tooltipPosition.y + 'px' }"
              v-if="isToolTipOn(item)"
            >
              <div class="tooltip-container">
                <p class="tooltip-title">{{ scopeName }}</p>
                <div class="tooltip-content">
                  <div class="tooltip-content-left">
                    <span>{{ $t('dashboard_main.label_total_emissions') }}</span>
                    <span>{{ getTitleRatio }}</span>
                    <span v-if="pastDurationChangeStatus">{{ getTitleCheckBoxCompare }}</span>
                  </div>
                  <div class="tooltip-content-right">
                    <span>{{ totalEmissions }} {{ getUnit }}</span>
                    <span>{{ changeYearEveryValue('core') }}</span>
                    <span v-if="pastDurationChangeStatus">{{ changeYearEveryValue('compare') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="chart__body--right"></div>
        </div>
      </div>
      </div>
      <!-- footer -->
      <div class="chart__footer">
        <div class="chart__footer--left" :style="{ minWidth: footerWidth + 'px' }">
          <button
            type="button"
            class="chart-btn-action"
            :disabled="isDisabledPrev"
            @click="prev"
            @mouseover="isMouseOverPrev = true"
            @mouseleave="isMouseOverPrev = false"
            @mousedown="isBlurPrev = true"
            @mouseup="isBlurPrev = false"
          >
            <img :src="prevIcon" alt="Prev" />
          </button>
        </div>

        <div class="chart__footer--center" ref="footerCenter" @scroll="syncScroll('footer')">
          <div class="axisX">
            <div
              class="axis-item"
              :class="{ active: item.rangeX === selectedTimeRange, hover: item.rangeX === hoverTimeRange }"
              v-for="(item, index) in axisXItemsSource"
              :key="index + '-axis-x'"
            >
              <span class="axis-item-scope">{{ getLabelX(item) }}</span>
              <v-tooltip min-width="250px" max-width="300px" top content-class="custom-tooltip-scopename">
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="question"
                    v-bind="attrs"
                    v-on="on"
                    v-show="!['scope1', 'scope2'].includes(item.scopeName)"
                  >
                    <div
                      @mouseover="changeTooltipImage($event)"
                      @mouseleave="changeTooltipImage($event, 'mouseleave')"
                      @click.stop="changeTooltipImage($event)"
                      class="category"
                    >
                     {{ item.category === 16 ? 1 : item.category }}
                    </div>
                  </div>
                </template>

                <span v-html="getScopeName(item)"></span>
              </v-tooltip>
            </div>
          </div>
        </div>

        <div class="chart__footer--right">
          <button
            type="button"
            class="chart-btn-action"
            :disabled="isDisabledNext"
            @click="next"
            @mouseover="isMouseOverNext = true"
            @mouseleave="isMouseOverNext = false"
            @mousedown="isBlurNext = true"
            @mouseup="isBlurNext = false"
          >
            <img :src="nextIcon" alt="Next" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { TABLET_WIDTH, LARGE_DESKTOP } from '@/constants/screen-size';
import {
  formatValue,
  $_helper_isNumberType,
  $_helper_isNegativeNumber,
  math,
  formatBigNumber,
} from '@/concerns/newRegisterData/wijmo.helper';
import { addThoundSandCommaWithTwoDigit } from '@/utils/convertNumber';
import { getTitle, getRatioByCategory, getTitleCompare } from '@/utils/getTextWithCondition';
import { getWidthYAxisByTextContent } from '@/utils/calcTextWidth';
import { prepareChartAxisY } from '@/concerns/chart';
import { BG_SCOPE } from '@/constants/products/define-data.js';
import CheckBoxHomeStandard from '@/components/dashboard/home-standard/checkbox-standard';
import { mapActions, mapState } from 'vuex';
import debounce from 'lodash/debounce';

export default {
  components: { CheckBoxHomeStandard },
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    selectedTab: {
      type: Number,
      default: 0,
    },
    chartDataCompare: {
      type: Object,
      default: () => {},
    },
    axisYByCategory: {
      type: Object,
      default: () => {},
    },
    branchIds: {
      type: Array,
      default: () => [],
    },
    filterUpdatedKeys: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      indexAtFirstBar: 0,
      min: 0,
      max: 800,
      axisYItemsSource: [
        {
          value: 0,
          text: '0',
          percent: 0,
        },
        {
          value: 100,
          text: '100',
          percent: 12.5,
        },
        {
          value: 200,
          text: '200',
          percent: 25,
        },
        {
          value: 300,
          text: '300',
          percent: 37.5,
        },
        {
          value: 400,
          text: '400',
          percent: 50,
        },
        {
          value: 500,
          text: '500',
          percent: 62.5,
        },
        {
          value: 600,
          text: '600',
          percent: 75,
        },
        {
          value: 700,
          text: '700',
          percent: 87.5,
        },
        {
          value: 800,
          text: 't-CO2',
          percent: 100,
        },
      ],
      axisXItemsSource: [],
      chartValue: [],
      selectedTimeRange: '',
      hoverTimeRange: '',
      isDisabledPrev: false,
      isDisabledNext: false,
      isMouseOverNext: false,
      isMouseOverPrev: false,
      isBlurNext: false,
      isBlurPrev: false,
      isFirstRender: true,
      transitionWidth: '100%',
      footerWidth: 0,
      minWidth: 60,
      originChartValue: [],
      tooltipPosition: { x: 0, y: 0 },
      rangeTooltipData: null,
      axisYItemsCount: 7,
      pastDurationChangeStatus: false,
      isLoading: false,
      maxChartItem: 20,
      heightSelect: null,
      wasMobile: null,
      isMobileResize: false,
      maxWidth: null,
    };
  },
  async mounted() {
    this.isLoading = true;
    if (window.innerWidth < TABLET_WIDTH) {
      this.touchScroll(this.$refs.slider, this.$refs.sliderXAxis);
    }
    const debouncedHandleResize = debounce(() => {
      this.handleResize();
    }, 1000);
    window.addEventListener('resize', debouncedHandleResize);
    window.addEventListener('scroll', this.handleScroll);
    this.isMobileResize = window.innerWidth < TABLET_WIDTH;
  },
  computed: {
    ...mapState('dashboard', ['selectedEmissionsByCategoryDashboard', 'isEnableScope3']),
    ...mapState('settingsDashboard', ['itemsSettings']),
    getUnit() {
      return this.itemsSettings[this.$store.state.userData.contractor]?.unitName || 't-CO2';
    },
    totalEmissions() {
      const total = this.rangeTooltipData?.value;
      return total === null ? '-' : addThoundSandCommaWithTwoDigit(total, '', false, true);
    },
    getLabelX() {
      return (item) => {
        if (item.category === 16) return 'OTHER 1';
        return item.label;
      }
    },
    isMobile() {
      return window.innerWidth < TABLET_WIDTH
    },
    getTitleRatio() {
      return getTitle(this.selectedTab);
    },
    isToolTipOn() {
      return (item) => {
        return this.rangeTooltipData?.rangeX === item.rangeX;
      };
    },
    scopeName() {
      const scopeNameLabel = this.rangeTooltipData?.scopeName;

      let category = this.rangeTooltipData?.scopeName == 'scope3' ? '-' + this.rangeTooltipData?.category : '';
      let title = '';

      if (this.rangeTooltipData?.scopeName === 'scope4') {
        title = this.rangeTooltipData?.creditHover
          ? `${this.rangeTooltipData?.creditHover?.name} - ${this.rangeTooltipData?.creditHover?.type}`
          : `${this.$t('list_emissions.title_scope_others')}. ${this.rangeTooltipData?.title}`;
      } else {
        title = scopeNameLabel
          ? this.$t('dashboard_main.label_scope') +
            scopeNameLabel.charAt(scopeNameLabel.length - 1) +
            category +
            '. ' +
            this.rangeTooltipData?.title
          : '';
      }
      return title;
    },
    ratioTitle() {
      return getTitle(this.tab);
    },
    getTypeText() {
      return this.typeText;
    },
    changeYearEveryValue() {
      return (type) => {
        if (!this.rangeTooltipData) {
          return;
        }
        if (!this.pastDurationChangeStatus) {
          type = 'core';
        }
        let ratioData = getRatioByCategory(
          this.selectedTab,
          this.rangeTooltipData.creditHover
            ? this.rangeTooltipData?.creditHover.detail_ratio
            : this.rangeTooltipData?.detail_ratio,
        );
        let ratioValue = null;
        if (type === 'compare' || this.selectedTab === 0) {
          ratioValue = ratioData.rate_last;
        } else if (type === 'core') {
          ratioValue = ratioData.rate_last_same;
        }
        ratioValue = ratioValue ?? '-';
        let unit = '';
        if (ratioValue !== '-') {
          unit = '%';
          let ratioUp = ratioData.up_same;
          if (type === 'compare' || this.selectedTab === 0) {
            ratioUp = ratioData.up;
          }
          if (ratioUp === 1) {
            unit += '↑';
          } else {
            unit += '↓';
          }
        }
        return ratioValue + unit;
      };
    },
    getTitleCheckBoxCompare() {
      return getTitleCompare(this.selectedTab);
    },
    nextIcon() {
      if (this.isDisabledNext) {
        this.isBlurNext = false;
        this.isMouseOverNext = false;
        return 'img/icons/next-disabled.svg';
      }

      if (this.isBlurNext) {
        return 'img/icons/next.svg';
      }

      if (this.isMouseOverNext) {
        return 'img/icons/next-hover.svg';
      }

      return 'img/icons/next.svg';
    },
    prevIcon() {
      if (this.isDisabledPrev) {
        this.isBlurPrev = false;
        this.isMouseOverPrev = false;
        return 'img/icons/prev-disabled.svg';
      }

      if (this.isBlurPrev) {
        return 'img/icons/prev.svg';
      }

      if (this.isMouseOverPrev) {
        return 'img/icons/prev-hover.svg';
      }

      return 'img/icons/prev.svg';
    },
    hasNegativeNumber() {
      return this.originChartValue.find((item) => item.value < 0 || (item?.pastDurations && item.pastDurations.value < 0));
    },
    watchData() {
      return this.branchIds + this.selectedTab + this.filterUpdatedKeys + this.isLoading;
    },
    getTitleCompareCategory() {
      let titleCompare = this.$t('dashboard_main.label_year_on_year_comparison_category');
      switch (this.selectedTab) {
        case 1:
        case 2:
          titleCompare = this.$t('dashboard_main.label_year_on_year_comparison_category');
          break;
        case 3:
          titleCompare = this.$t('dashboard_main.label_compared_to_the_same_month_last_year_category');
          break;
        default:
          break;
      }
      return titleCompare;
    },
    getWidthNextButton() {
      return this.isMobileResize ? '0px' : '52px'; // 52 is width of the next button, 0 is button disabled
    },
   
  },
  beforeDestroy() {
    const elem = this.$refs.slider;
    if (elem) {
      elem.removeEventListener("touchstart", this.touchStart);
      elem.removeEventListener("touchmove", this.touchMove);
      elem.removeEventListener("touchend", this.touchEnd);
    }
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions('dashboard', ['updateSelectedEmissionsByCategoryDashboard']),
    getMaxWidthBody() {
      const bodyCenter = this.$refs.bodyCenter;
      const isOverflowing = bodyCenter?.scrollWidth > bodyCenter?.clientWidth;
      if (isOverflowing) {
        this.maxWidth = `calc(100% - ${this.minWidth} - ${this.getWidthNextButton})`;
      } else {
        this.maxWidth = '100%';
      }
    },
    syncScroll(origin) {
      if (origin === 'body') {
        this.$refs.footerCenter.scrollLeft = this.$refs.bodyCenter.scrollLeft;
      } else if (origin === 'footer') {
        this.$refs.bodyCenter.scrollLeft = this.$refs.footerCenter.scrollLeft;
      }
    },
    touchScroll(elem, elemXAis) {
      if(!elem) return;
      let touchStartX = 0;
      let scrollStartX = 0;
      this.touchStart = (e) => {
        touchStartX = e.touches[0].pageX;
        scrollStartX = elem.scrollLeft;
        elem.style.cursor = 'grabbing';
      };

      this.touchMove = (e) => {
        const touchCurrentX = e.touches[0].pageX;
        const moveX = touchCurrentX - touchStartX;
        elem.scrollLeft = scrollStartX - moveX;
        elemXAis.scrollLeft = scrollStartX - moveX;
      };

      this.touchEnd = () => {
        elem.style.cursor = 'grab';
      };

      elem.addEventListener("touchstart", this.touchStart, false);
      elem.addEventListener("touchmove", this.touchMove, false);
      elem.addEventListener("touchend", this.touchEnd, false);
    },
    handleChangeCheckBoxPastDurations(status) {
      this.pastDurationChangeStatus = status;
      this.updateCheckStatusToStore(status);

      if (status) {
        if (this.selectedTab === 0) {
          this.$emit('updateCompareEmissionsByCategory', false);
          return;
        }
        this.$emit('updateCompareEmissionsByCategory', true);
      } else {
        this.$emit('updateCompareEmissionsByCategory', false);
      }
    },
    updateCheckStatusToStore(status) {
      switch (this.selectedTab) {
        case 0:
        case 1:
          this.updateSelectedEmissionsByCategoryDashboard({
            ...this.selectedEmissionsByCategoryDashboard,
            checkBoxHalfYearStatus: status,
          });
          break;
        case 2:
          this.updateSelectedEmissionsByCategoryDashboard({
            ...this.selectedEmissionsByCategoryDashboard,
            checkBoxQuarterYearStatus: status,
          });
          break;
        case 3:
          this.updateSelectedEmissionsByCategoryDashboard({
            ...this.selectedEmissionsByCategoryDashboard,
            checkBoxMonthYearStatus: status,
          });
          break;
        default:
          break;
      }
    },
    getBgColor(scope, category) {
      if (category === 16 || scope === 'credit' ) scope = 4;
      return `${BG_SCOPE[scope]} !important`;
    },
    getStylesColumn(item, styleName) {
      const barItemSelectedElement = document.querySelector('.chart-dashboard-category .chart-bar');
      let widthBarSelected = barItemSelectedElement?.offsetWidth ?? 25.4;
      let width = this.pastDurationChangeStatus ? widthBarSelected / 5 : widthBarSelected / 3; // Numbers 5 and 3 are the percent width value of the range selected
      const barItemListElement = document.querySelectorAll('.chart-dashboard-category .bar');
      barItemListElement.forEach((element) => {
        element.style.width = width + 'px';
      });
      return {
        [styleName]: this.pastDurationChangeStatus ? width * 2 + 'px' : '',
      };
    },
    formatValueAndPast(value, pastDurations) {
      const isTco2Unit = this.getUnit === 't-CO2';
      const valueEmission = math.bignumber(formatValue(value));
      let valuePast = pastDurations?.value || 0;
      if (this.pastDurationChangeStatus && pastDurations) {
        const valueEmissionPast = math.bignumber(formatValue(pastDurations.value));
        valuePast = isTco2Unit ? pastDurations.value : formatBigNumber(math.multiply(valueEmissionPast, 1000), 50); // calculate to kg
      }
      return {
        value: isTco2Unit ? value : formatBigNumber(math.multiply(valueEmission, 1000), 50), // calculate to kg
        valuePast,
      };
    },
    handleMounted() {
      this.originChartValue = [];
      this.chartData.forEach((item) => {
        let timeRange = item.label;
        const { value, valuePast } = this.formatValueAndPast(item.value, item.pastDurations);
        this.originChartValue.push({
          ...item,
          time_range: timeRange,
          value,
          pastDurations: {
            ...item.pastDurations,
            value: valuePast,
          },
        });
        if (item?.credit) {
          item?.credit.forEach((credit, index) => { 
            const { value: creditValue, valuePast: creditValuePast } = this.formatValueAndPast(credit.total_emission,credit.pastDurations);
            this.originChartValue.push({
              ...credit,
              time_range: 'OTHER 2',
              rangeX: 'OTHER 2_' + credit.type_credit_id,
              category: index + 2,
              value: creditValue,
              scope: 'credit',
              creditHover: credit,
              scopeName: 'scope4',
              pastDurations: {
                ...credit.pastDurations,
                value: creditValuePast,
              },
            });
          });
        }
      });
      this.transformChartData();

      if (this.indexAtFirstBar + this.maxChartItem >= this.originChartValue.length) {
        this.isDisabledNext = true;
      } else {
        this.isDisabledNext = false;
      }

      if (this.indexAtFirstBar <= 0) {
        this.isDisabledPrev = true;
      } else {
        this.isDisabledPrev = false;
      }

      this.footerWidth = this.$refs.yearLeft.clientWidth;
    },
    handleScroll() {
      if (this.rangeTooltipData) {
        this.toolTipOff();
      }
    },
    handleResize() {
      if (window.innerWidth < TABLET_WIDTH) {
        this.touchScroll(this.$refs.slider, this.$refs.sliderXAxis);
      }
        this.handleMounted();

      // this.isMobileResize = window.innerWidth < TABLET_WIDTH;
      // if (this.isMobileResize !== this.wasMobile) {
      //   this.handleMounted();
      // }
      // // Update the previous state when resize
      // this.wasMobile = this.isMobileResize;
    },
    formatNumber(num) {
      return num === 0 ? num : Number(formatValue(num));
    },
    formatNumberWithComma(value) {
      if ($_helper_isNumberType(value)) {
        return addThoundSandCommaWithTwoDigit(value?.toString(), '', false, true);
      }
      return value;
    },
    updateTooltipPosition(event) {
      const SIDEBAR_WIDTH = 236;
      const element = document.getElementById('tooltip-column');
      const width = element?.clientWidth;
      const height = element?.clientHeight;

      const halfTooltipBlock = width / 2;
      const spacerFromColumnToLeft = event.clientX;
      const spacerFromColumnToRight = window.innerWidth - spacerFromColumnToLeft;
      const spacerFromColumnToSidebar = spacerFromColumnToLeft - SIDEBAR_WIDTH;

      if (spacerFromColumnToRight < halfTooltipBlock + 20) {
        this.tooltipPosition.x = spacerFromColumnToLeft - width + spacerFromColumnToRight - 20;
      } else if (spacerFromColumnToSidebar < halfTooltipBlock) {
        this.tooltipPosition.x = spacerFromColumnToLeft - spacerFromColumnToSidebar + 4;
      } else {
        this.tooltipPosition.x = spacerFromColumnToLeft - halfTooltipBlock;
      }

      let spacer = height;
      if (this.pastDurationChangeStatus) {
        spacer = height + 20;
      }
      this.tooltipPosition.y = event.clientY - spacer - 40;
    },
    toolTipOn(item) {
      if (window.innerWidth < TABLET_WIDTH) {
        if (this.rangeTooltipData) {
          this.toolTipOff();
        }
      } else {
        this.rangeTooltipData = item;
      }
    },
    toolTipOff() {
      this.rangeTooltipData = '';
    },
    updateToStore(item) {
      let store = {};
      switch (this.selectedTab) {
        case 0:
          store = {
            selectedYearDetail: {
              scope: item.scopeName.slice(5, 6),
              category: item.category,
              row_num: item.original?.row_num,
              indexCredit: item.indexCredit,
              type_credit_id: item.type_credit_id,
            },
          };
          break;
        case 1:
          store = {
            selectedHalfYearDetail: {
              scope: item.scopeName.slice(5, 6),
              category: item.category,
              row_num: item.original?.row_num,
              indexCredit: item.indexCredit,
              type_credit_id: item.type_credit_id,
            },
          };
          break;
        case 2:
          store = {
            selectedQuarterYearDetail: {
              scope: item.scopeName.slice(5, 6),
              category: item.category,
              row_num: item.original?.row_num,
              indexCredit: item.indexCredit,
              type_credit_id: item.type_credit_id,
            },
          };
          break;
        case 3:
          store = {
            selectedMonthYearDetail: {
              scope: item.scopeName.slice(5, 6),
              category: item.category,
              row_num: item.original?.row_num,
              indexCredit: item.indexCredit,
              type_credit_id: item.type_credit_id,
            },
          };
          break;
        default:
          break;
      }
      this.updateSelectedEmissionsByCategoryDashboard({
        ...this.selectedEmissionsByCategoryDashboard,
        ...store,
      });
    },
    transformChartData() {
      if (this.originChartValue.length === 0) {
        this.chartValue = [];
        this.axisXItemsSource = [];
        this.getWidthOfText();
        return;
      }
      const summary = this.calcSummary(this.originChartValue);
      let index = -1;
      this.selectedExistOrFirstCategory();
      index = this.originChartValue.findIndex((item) => item.rangeX === this.selectedTimeRange);
      const listIndex = this.originChartValue.length - 1;
      if (index >= 0) {
        if (listIndex <= this.maxChartItem - 1 || index <= this.maxChartItem - 1) {
          this.indexAtFirstBar = 0;
        } else if (index >= listIndex - this.maxChartItem + 1) {
          this.indexAtFirstBar = listIndex - this.maxChartItem + 1;
        } else {
          this.indexAtFirstBar = index - 1;
        }
      } else {
        this.indexAtFirstBar = 0;
      }
      [this.axisYItemsSource, this.min, this.max] = prepareChartAxisY(summary, this.axisYItemsCount, this.getUnit);
      this.prepareChartDataValue();
    },
    changeTooltipImage(event, type) {
      if (type === 'mouseleave') {
        event.target.src = 'img/icons/question.svg';
      } else {
        event.target.src = 'img/icons/question-active.svg';
      }
    },
    getMaxChartItemsCount() { 
      const text =
        this.axisYItemsSource[0]?.text.length >
        addThoundSandCommaWithTwoDigit(this.axisYItemsSource[6]?.value.toString().length, '', false, true).length
          ? this.axisYItemsSource[0]?.text
          : this.axisYItemsSource[6]?.text?.toString();
      
      const width = getWidthYAxisByTextContent(text);

      const minWidthYAxis = width < 60 ? 60 : width + 2;

      const widthBodyCenter = this.$refs.chartContainer?.clientWidth;
      // Calculate the maximum number of chart items to display
      // 80 is padding + width of the next button, 57 is the minimum width of a column
      this.maxChartItem = this.isMobileResize 
                        ? this.originChartValue.length 
                        : Math.floor((widthBodyCenter - minWidthYAxis - 80) / 57);
    },
    prepareChartDataValue() {
      if (!this.originChartValue.length) {
        return;
      }
      let index = 0;
      let chartValueOriginal = [];
      this.getMaxChartItemsCount();
      do {
        chartValueOriginal.push(this.originChartValue[index + this.indexAtFirstBar]);
        index++;
      } while (index < this.maxChartItem);
      chartValueOriginal = chartValueOriginal.filter((item) => !!item);
      this.axisXItemsSource = chartValueOriginal.map((item) => {
        return {
          ...item,
          label: item.time_range,
          value: item.time_range,
          tooltip: item.tooltip,
        };
      });
      this.chartValue = chartValueOriginal.map((item) => {
        return {
          ...item,
          total_emission: '0',
          total_amount: '0',
          emission_percent: '0%',
          past_emission_percent: '0%',
        };
      });
      const timeout = this.isFirstRender ? 1000 : 0;
      setTimeout(() => {
        const summary = this.calcSummary(chartValueOriginal);
        // const [x, y, z] = prepareChartAxisY(summary, this.axisYItemsCount);
        // this.axisYItemsSource = x;
        // this.min = parseInt(y);
        // this.max = parseInt(z);
        this.getWidthOfText();
        this.getMaxWidthBody();
        this.chartValue = chartValueOriginal.map((item) => {
          return {
            ...item,
            emission_percent: this.calcScopeEmission(item.value),
            past_emission_percent: this.calcScopeEmission(item.pastDurations?.value),
            zero_value_percent: Math.abs(Number(this.min) / (this.max - this.min)) * 100 + '%',
          };
        });
      }, timeout);

      setTimeout(() => {
        this.transitionWidth = 0;
        this.footerWidth = this.$refs.yearLeft?.clientWidth;
      }, timeout + 500);

      if (this.isFirstRender) {
        setTimeout(() => {
          this.isFirstRender = false;
          this.getHeightSelectBar();
        }, timeout + 2000);
      }
    },
    calcSummary(chartValueOriginal) {
      const summary = chartValueOriginal.map((item) => {
        let negativeTotal = 0;
        let positiveTotal = 0;
        let emissionScopes = [item.value];
        if (item.pastDurations && this.pastDurationChangeStatus) {
          emissionScopes = [...emissionScopes, item.pastDurations.value];
        }
        if (emissionScopes.every((value) => $_helper_isNumberType(value))) {
          emissionScopes.forEach((scope) => {
            const value = formatValue(scope);
            $_helper_isNegativeNumber(scope)
              ? (negativeTotal = formatBigNumber(
                  math.evaluate(`${math.bignumber(formatValue(negativeTotal))} + ${math.bignumber(value)}`),
                ))
              : (positiveTotal = formatBigNumber(
                  math.evaluate(`${math.bignumber(formatValue(positiveTotal))} + ${math.bignumber(value)}`),
                ));
          });
          return [negativeTotal, positiveTotal];
        }
        return emissionScopes.reduce((acc, scope) => acc + scope, 0);
      });
      return summary;
    },
    onMouseOver(item) {
      this.hoverTimeRange = item.rangeX;
    },
    next() {
      if (this.indexAtFirstBar + this.maxChartItem >= this.originChartValue.length) {
        this.isDisabledNext = true;
        return;
      }

      this.isDisabledNext = false;
      this.indexAtFirstBar = this.indexAtFirstBar + 1;

      if (this.indexAtFirstBar + this.maxChartItem >= this.originChartValue.length) {
        this.isDisabledNext = true;
      }
      this.prepareChartDataValue();

      if (this.indexAtFirstBar > 0) {
        this.isDisabledPrev = false;
      } else {
        this.isDisabledPrev = true;
      }
    },
    prev() {
      if (this.indexAtFirstBar <= 0) {
        this.isDisabledPrev = true;
        return;
      }

      this.isDisabledPrev = false;

      this.indexAtFirstBar = this.indexAtFirstBar - 1;
      if (this.indexAtFirstBar <= 0) {
        this.isDisabledPrev = true;
      }
      this.prepareChartDataValue();

      if (this.indexAtFirstBar + this.maxChartItem < this.originChartValue.length) {
        this.isDisabledNext = false;
      }
    },
    calcScopeEmission(emissionsValue) {
      if (emissionsValue < 0) {
        return Math.abs(Number(emissionsValue) / this.min) * 100 + '%';
      } else {
        return Math.abs(Number(emissionsValue) / this.max) * 100 + '%';
      }
    },
    getWidthOfText() {
      let maxWidth = 0;
      this.axisYItemsSource.forEach((item) => {
        const textWidth = getWidthYAxisByTextContent(item?.text || '');
        if (textWidth > maxWidth) {
          maxWidth = textWidth;
        }
      });
      this.minWidth = maxWidth < 60 ? '60px' : maxWidth + 2 + 'px';
    },
    getHeightSelectBar() {
      const chartFooter = document.querySelector('.chart-dashboard-category .chart__footer--center .axis-item');
      const additionalHeight = chartFooter ? chartFooter.clientHeight : 72;
      this.heightSelect = `height: calc(100% + ${additionalHeight}px); bottom: -${additionalHeight}px; width: 100%;`;
    },
    selectedCategory(category, index) {
      let prefix,
        scopeName = category.scopeName;
      switch (scopeName) {
        case 'scope1':
          prefix = '-scope-1';
          break;
        case 'scope2':
          prefix = '-scope-2';
          break;
        case 'scope3':
          prefix = '-scope-3';

          break;
        case 'scope4':
          prefix = '-scope-4';

          break;
        default:
          break;
      }
      this.selectedTimeRange = category.rangeX;
      if (category.scope === 'credit') {
        category = { ...category, ...{ indexCredit: index, type_credit_id: category.type_credit_id } };
      }
      this.updateToStore(category);
      this.$emit(
        'onSelectCategory',
        category,
        window.innerWidth < TABLET_WIDTH,
        this.pastDurationChangeStatus,
        this.chartDataCompare[scopeName][index],
        prefix === '-scope-4-credit' ? index : null,
      );
    },
    selectedExistOrFirstCategory() {
      if (Object.keys(this.originChartValue).length === 0) {
        return;
      }
      let selectedEmissionsByCategory = {};
      switch (this.selectedTab) {
        case 0:
          selectedEmissionsByCategory = this.selectedEmissionsByCategoryDashboard.selectedYearDetail;
          break;
        case 1:
          selectedEmissionsByCategory = this.selectedEmissionsByCategoryDashboard.selectedHalfYearDetail;
          break;
        case 2:
          selectedEmissionsByCategory = this.selectedEmissionsByCategoryDashboard.selectedQuarterYearDetail;
          break;
        case 3:
          selectedEmissionsByCategory = this.selectedEmissionsByCategoryDashboard.selectedMonthYearDetail;
          break;
        default:
          break;
      }
      if (selectedEmissionsByCategory && selectedEmissionsByCategory.scope && selectedEmissionsByCategory.category) {
        let category = this.originChartValue.find(
          (item) =>
            item.scope == selectedEmissionsByCategory.scope && item.category == selectedEmissionsByCategory.category,
        );
        if (category) {
          this.selectedTimeRange = category.rangeX;
          if (category && Object.prototype.hasOwnProperty.call(category, 'original')) {
            category['categoryName'] = category.title;
            this.$emit(
              'onSelectCategory',
              category,
              false,
              this.pastDurationChangeStatus,
              undefined,
              selectedEmissionsByCategory.indexCredit,
            );
            this.updateToStore(category);
          }
        }
        else {
         this.setDefaultSelected();
        }
      } else {
        this.setDefaultSelected();
      }
    },
    setDefaultSelected() {
      let category = this.originChartValue[0];
      category['categoryName'] = category.title;
      this.selectedTimeRange = category.rangeX;
      this.$emit('onSelectCategory', category, false, this.pastDurationChangeStatus);
      this.updateToStore(category);
    },
    isNegativeTotalData(item) {
      return item.value < 0;
    },
    getScopeName(item) {
      const scopeNameLabel = item?.scopeName;

      let category = item?.scopeName == 'scope3' ? ' ' + item?.category : '';
      let title = '';

      if (item?.scopeName === 'scope4') {
        title = item?.creditHover
          ? `${item?.creditHover?.name} - ${item?.creditHover?.type}`
          : `${this.$t('list_emissions.title_scope_others')}. ${item?.title}`;
      } else {
        title = scopeNameLabel
          ? this.$t('dashboard_main.label_scope') +
            scopeNameLabel.charAt(scopeNameLabel.length - 1).toUpperCase() +
            category +
            '. ' +
            item?.title
          : '';
      }
      return title;
    },
  },
  watch: {
    watchData: {
      handler() {
        switch (this.selectedTab) {
          case 0: // year
            this.pastDurationChangeStatus = false;
            break;
          case 1: // half
            this.pastDurationChangeStatus = this.selectedEmissionsByCategoryDashboard.checkBoxHalfYearStatus;
            break;
          case 2: // quarter
            this.pastDurationChangeStatus = this.selectedEmissionsByCategoryDashboard.checkBoxQuarterYearStatus;
            break;
          case 3: // month year
            this.pastDurationChangeStatus = this.selectedEmissionsByCategoryDashboard.checkBoxMonthYearStatus;
            break;
          default:
            break;
        }
        setTimeout(() => {
          this.handleChangeCheckBoxPastDurations(this.pastDurationChangeStatus);
        }, 500);
      },
      deep: true,
    },
    chartData: {
      handler() {
        this.handleMounted();
      },
      deep: true,
    },
    itemsSettings: {
      handler() {
        this.handleMounted();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/views/dashboard/components/emissions-by-period/yearly/chart-2/index.scss';

.chart-column {
  .chart-body-content {
    display: flex;
    align-items: center;
    width: 100%;
    align-self: stretch;
  }
  .chart {
    margin-top: 0;
  }
  @include desktop {
    .chart {
      margin-top: 24px;
    }
    &.chart__wrapper {
      .chart__footer {
      .chart__footer--left *,
      .chart__footer--right {
          display: flex;
        }
      }
      .chart__body {
        .chart__body--right {
          display: block;
        }
        .chart__body--center,
        .chart__footer--center {
          overflow: initial;
        }
      }
    }
  }
  .chart__body--center,
  .chart__footer--center { 
    overflow: hidden;
    touch-action: auto;
    user-select: none;
  }
  .tooltip-container {
    max-width: 500px;
    min-width: max-content;
    .tooltip-title {
      color: $goldMid;
    }
    .tooltip-content {
      display: flex;
      gap: 8px;
      align-items: stretch;
    }
    .tooltip-content-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      justify-content: space-between;
    }
  }
  .transparent-number {
  opacity: 0.2;
  }
  .show-pastDurations-change {
    display: block !important;
  }
  .chart__header--left {
    padding: 0 !important;
  }
  .chart__footer--center {
    .axisX {
      align-self: stretch;
      .axis-item {
        display: flex;
        color: $monoBlack;
        flex-direction: column;
        gap: 4px;
        height: 100%;
        justify-content: start !important;
        min-height: 74px;
        position: relative;
        padding: 8px !important;
        min-width: 57px;
        max-width: 120px;
        .axis-item-scope {
          white-space: nowrap;
        }
        @media (max-width: $tablet) {
          .axis-item-scope {
            white-space: break-spaces;
            letter-spacing: 0.33px !important;
          }
        }
        .question {
          .category {
            color: $monoDark;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            width: 20px;
            height: 20px;
            background: rgba(121, 134, 134, 0.12);
            border-radius: 50%;
          }
          
        }
      }
    }
  }
}
.chart-column {
  &.chart__wrapper {
    .chart__body {
      min-height: 374px;
      .chart-bar {
        min-height: 374px;
        min-width: 57px;
        max-width: 120px;
        .active {
          position: absolute;
          right: 1px;
        }
        .hover {
          position: absolute;
          right: 1px;
        }
      }
      .chart__body--left {
        z-index: 0;
      }
    }
  }
}
.chart__wrapper {
  padding: 16px 0;
  .chart__header {
    .chart-legend {
      .bar-color {
        background: $goldMid !important;
      }
    }
  }
  .chart__body {
    .chart__body--left {
      min-width: 53px;
      .axisY {
        justify-content: unset;
        position: relative;
        .axis-item {
          position: absolute;
          right: 0;
        }
      }
    }
    .chart__body--center {
      .number-wrapper {
        width: 100%;
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        position: relative;
        .positive-number {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-direction: column;
          position: absolute;
        }
        .number-positive-wrapper {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-direction: column;
          position: absolute;
          height: 100%;
          width: 20%;
        }
        .negative-number {
          flex: 1;
          position: absolute;
        }
        .zero-crossline {
          border-top: 1px solid #ddd;
          height: 1px;
          width: 100%;
          position: absolute;
        }
        .bar--scope1 {
          background: $goldMid !important;
        }
      }
    }
  }
}
.custom-tooltip-scopename {
  display: flex;
  padding: 8px 12px;
  color: $bgDark;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.33px;
  border-radius: 4px;
  border: 1px solid rgba(42, 42, 48, 0.10);
  background: $monoWhite;
  box-shadow: 0px 36px 33px 0px rgba(160, 181, 186, 0.07), 0px 13.141px 12.046px 0px rgba(160, 181, 186, 0.11), 0px 6.38px 5.848px 0px rgba(160, 181, 186, 0.13), 0px 3.127px 2.867px 0px rgba(160, 181, 186, 0.17), 0px 1.237px 1.134px 0px rgba(160, 181, 186, 0.24);
}
@media (max-width: $desktop) {
  .chart__footer--center,
  .chart__body--center {
    overflow-x: scroll !important;
    white-space: nowrap;
    overflow-y: hidden !important;
  }

  .chart__body--center::-webkit-scrollbar {
    display: none;
  }
  .chart__footer--center::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 6px solid rgba(236, 220, 220, 0);
    background-clip: padding-box;
    background-color: rgba(22, 24, 35, 0.06);
  }
  .chart__footer--center::-webkit-scrollbar {
    width: 18px;
  }
}
</style>
