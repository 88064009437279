<template>
  <div class="duration-wrapper filter-duration">
    <div class="prev" :class="{'year-only': !isHasMonth}">
      <common-button class="prev__pc" @action="prev(false)" :disabled="isDisablePrev" :label="getNextAndPrevTitle.prev">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
          <path d="M4.01563 6L11.0156 11L11.0156 1L4.01563 6Z" stroke="currentColor" stroke-linejoin="round"/>
          <path d="M7.98438 6L14.9844 11L14.9844 1L7.98438 6Z" stroke="currentColor" stroke-linejoin="round"/>
          <path d="M1.01563 1L1.01562 11" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </common-button>
      <button
        type="button"
        class="prev__sp"
        :disabled="isDisablePrev"
        @click="prev(true)"
        @mouseover="handleMouseOverPrevBtn"
        @mouseleave="isMouseOverPrev = false"
        @mousedown="isBlurPrev = true"
        @mouseup="isBlurPrev = false"
        @touchstart="handleTouchStartPrevBtn"
        @touchend="handleTouchEndPrevBtn"
      >
        <img :src="prevIconUrl || prevIcon" alt="Prev" />
      </button>
    </div>

    <div class="durations-desktop" :class="{'year-only': !isHasMonth}">
      <div class="year" v-if="isHasMonth">
        <span
          v-for="(year, index) in yearsDurations"
          :key="index + '-year'"
          :style="{ flex: `0 0 ${(year.itemsCount / monthsDurations.length) * 100}%` }"
          class="text"
          :class="{ selected: selectedYear(year) }"
        >
          {{ year.value}}
        </span>
      </div>

      <div class="month" :class="{'end-tab': tab === 3 }" v-if="isHasMonth">
        <span
          v-for="(item, index) in monthsDurations"
          :key="index + '-month'"
          :class="{ selected: item === selectedMonthData }"
          @click="onSelectedMonth(item)"
          class="text"
          :style="{ width: `${100 / maxItemsYears}%` }"
          >{{ handleTitleDuration(item) }}</span
        >
      </div>
      <div v-if="!isHasMonth" :class="{'first-tab': tab === 0 }" class="only-year">
        <span
          v-for="(item, index) in years"
          :key="index + '-month'"
          class="year-item"
          @click="onSelectedYears(item)"
          :class="{ active: yearSelected === item }"
          >{{ handleTitleDuration(item) }}</span
        >
      </div>
    </div>

    <div class="durations-mobile" v-if="!isHasMonth">
      <span
        v-for="(item, index) in years"
        :key="index + '-month'"
        class="durations-mobile__text"
        @click="onSelectedYears(item)"
        :class="{ active: yearSelected === item }"
        >{{ handleTitleDurationMobile(item) }}</span
      >
    </div>
    <div class="durations-mobile" v-if="isHasMonth">
      <span
          v-for="(item, index) in monthsDurations"
          :key="index + '-month'"
          :class="{ selected: item === selectedMonthData }"
          @click="onSelectedMonth(item)"
          class="durations-mobile__text"
          :style="{ width: `100%` }"
        >{{ handleTitleDurationMobile(item) }}</span
      >
    </div>

    <div class="next" :class="{'year-only': !isHasMonth}">
      <common-button class="prev__pc" :isDisable="isDisableNext" @action="next(false)" :label="getNextAndPrevTitle.next">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
          <path d="M11.9844 6L4.98438 1V11L11.9844 6Z" stroke="currentColor" stroke-linejoin="round"/>
          <path d="M8.01562 6L1.01562 1V11L8.01562 6Z" stroke="currentColor" stroke-linejoin="round"/>
          <path d="M14.9844 11V1" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </common-button>
      <button
        type="button"
        class="chart-btn-action prev__sp"
        :disabled="isDisableNext"
        @click="next(true)"
        @mouseover="handleMouseOverNextBtn"
        @mouseleave="isMouseOverNext = false"
        @mousedown="isBlurNext = true"
        @mouseup="isBlurNext = false"
        @touchstart="handleTouchStartNextBtn"
        @touchend="handleTouchEndNextBtn"
      >
        <img :src="nextIconUrl || nextIcon" alt="Next" />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'mobile';
@import 'desktop';
@media only screen and (max-width: 1420px) {
  .duration-wrapper .durations-desktop .month .text {
    width: 37px;
  }
}
</style>
<style lang="scss">
.filter-duration {
  .common-btn .v-btn__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 11px;
  }
}
</style>
<script>
import { TABLET_WIDTH } from '@/constants/screen-size';
import CommonButton from '@/components/utils/button.vue';
import { mapActions } from 'vuex';
import { getNextPrevTitle } from '@/utils/getTextWithCondition';
import moment from 'moment';
import { formatDataList } from '@/utils/pcaf';
import { HALF_YEAR, QUARTER_YEAR } from '@/constants/pcaf';
import { convertMonthCalendar } from '@/utils/registerData';
import i18n from '@/lang/i18n.js';

const MAX_ITEMS = 12;
const MAX_ITEMS_YEAR = 3;
const MAX_ITEMS_HALF_YEAR = 5;
const MAX_ITEMS_QUARTER_YEAR = 7;
const MAX_ITEMS_MONTH_YEAR = 12;
export default {
  components: { CommonButton },
  data() {
    return {
      startIndex: 0,
      years: [],
      months: [],
      selectedMonthData: 0,
      selectedIndexYear: 0,
      isDisablePrev: false,
      isDisableNext: false,
      durationCategoryMonth: [],
      boxWidth: 0,
      resizeTimer: null,
      yearSelected: null,
      yearsData: [],
      maxItemsYears: 3,
      yearsDurations: [],
      monthsDurations: [],
      yearsDurationsData: [],
      monthsDurationsData: [],
      windowWidth: 0,
      isMouseOverPrev: false,
      isMouseOverNext: false,
      isBlurPrev: false,
      isBlurNext: false,
      prevIconUrl: '',
      nextIconUrl: '',
    };
  },
  props: {
    branchIds: {
      type: Array,
      default: () => [],
    },
    tab: {
      type: Number,
      default: 0,
    },
    startMonth: {
      type: Number,
      default: 3
    }
  },
  computed: {
    MAX_ITEMS: () => MAX_ITEMS,
    getNextAndPrevTitle() {
      return getNextPrevTitle(this.tab);
    },
    selectedYear() {
      return (item) => {
        return this.selectedMonthData?.year === item.value;
      };
    },
    selectedMonthValue() {
      return this.getDurationByCategoryWithMonthly[this.startIndex];
    },
    getTitleYearMonth() {
      return (item) => {
        if(this.tab === 2) {
          return `${item.year} ${item.month}`
        }
        return `${item.year}年${item.month}`
      }
    },
    prevIcon() {
      if (this.isDisablePrev) {
        this.isBlurPrev = false;
        this.isMouseOverPrev = false;
        return 'img/icons/prev-disabled.svg';
      }

      if (this.isBlurPrev) {
        return 'img/icons/prev.svg';
      }

      if (this.isMouseOverPrev) {
        return 'img/icons/prev-hover.svg';
      }

      return 'img/icons/prev.svg';
    },
    nextIcon() {
      if (this.isDisableNext) {
        this.isBlurNext = false;
        this.isMouseOverNext = false;
        return 'img/icons/next-disabled.svg';
      }

      if (this.isBlurNext) {
        return 'img/icons/next.svg';
      }

      if (this.isMouseOverNext) {
        return 'img/icons/next-hover.svg';
      }

      return 'img/icons/next.svg';
    },
    isHasMonth() {
      return this.tab === 2 || this.tab === 3;
    },
  },
  created() {},
  async mounted() {
    if (window.innerWidth <= TABLET_WIDTH) {
      this.maxItemsYears = 1;
    }
    this.windowWidth = window.innerWidth
    this.onWindowResize();
    this.prepareYearList();
    this.selectedFirstItem();
  },
  watch: {
    tab: {
      handler() {
        this.nextIconUrl = '';
        this.prevIconUrl = '';
        this.prepareYearList();
        this.selectedFirstItem();
      },
      deep: true,
    },
    startMonth: {
      handler() {
        this.prepareYearList();
        this.selectedFirstItem();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('dashboard', ['updateSelectedEmissionsByCategoryDashboard']),
    handleTitleDuration(yearSelected){
      let title = '';
      switch (this.tab) {
        case 0:
          title = yearSelected.year;
          break;
        case 1:
          if(i18n.locale === 'vi') {
            title = `${HALF_YEAR.find(item => item.id === yearSelected.order).value} ${yearSelected.year} `;
          } else {
            title = `${yearSelected.year} ${HALF_YEAR.find(item => item.id === yearSelected.order).value}`;
          }
          break;
        case 2:
          title = `${QUARTER_YEAR.find(item => item.id === yearSelected.order).value}`;
          break;
          case 3:
          title = this.$t('register_data.month_select', { month: convertMonthCalendar(parseInt(yearSelected.selected_range.split('/')[1]))})
          break;
        default:
          break;
      }
      return title;
    },
    handleTitleDurationMobile(yearSelected){
      let title = '';
      switch (this.tab) {
        case 0:
          title = yearSelected.year;
          break;
        case 1:
          title = `${yearSelected.year} ${HALF_YEAR.find(item => item.id === yearSelected.order).value}`;
          break;
        case 2:
          title = `${yearSelected.year} ${QUARTER_YEAR.find(item => item.id === yearSelected.order).value}`;
          break;
          case 3:
          title =  this.$t("register_data.title_select_month_year_emission", { selectedYear: yearSelected.year, selectedMonth: parseInt(yearSelected.selected_range.split('/')[1]) });
          break;
        default:
          break;
      }
      return title;
    },
    setDefaultDisableNextPrev() {
      if (window.innerWidth >= TABLET_WIDTH) {
        if (this.selectedIndexYear <= 0) {
          this.isDisableNext = true;
        }
        if (this.selectedIndexYear >= this.getDurationByCategoryWithYearly.length - 1) {
          this.isDisablePrev = true;
        }
      } else {
        if (this.startIndex >= this.getDurationByCategoryWithMonthly.length - 1) {
          this.isDisableNext = true;
        }
        if (this.startIndex <= 0) {
          this.isDisablePrev = true;
        }
      }
    },
    selectedFirstItem() {
      if (!this.startMonth) {
        return;
      }
      let index = -1;
      if (this.tab === 0) {

        const oldYearSelected = this.$store.state.dashboard.selectedEmissionsByCategoryDashboard.selectedYear;
        index = this.yearsData.findIndex((item) => item?.year === oldYearSelected?.year);
        if (index < 0) {
          let currentYear = new Date().getFullYear();
          const currentMonth = new Date().getMonth() + 1;
          if (this.startMonth > currentMonth) {
            currentYear = currentYear - 1;
          }
          index = this.yearsData.findIndex((item) => {
            return item.year === currentYear;
          });
          this.updateToStore(this.yearsData[index]);
        }
      } else if (this.tab === 1) {
        const oldHalfYearSelected = this.$store.state.dashboard.selectedEmissionsByCategoryDashboard.selectedHalfYear;
        index = this.yearsData.findIndex((item) => item && item.order === oldHalfYearSelected?.order && item.year === oldHalfYearSelected?.year);
        if (index < 0) {
          index = this.yearsData.length - 1;
          this.updateToStore(this.yearsData[index]);
        }
      } else if (this.tab === 2) {
        const oldQuarterYearSelected =
          this.$store.state.dashboard.selectedEmissionsByCategoryDashboard.selectedQuarterYear;
        index = this.monthsDurationsData.findIndex(
          (item) => item && item.year === oldQuarterYearSelected?.year && item.order === oldQuarterYearSelected?.order,
        );
        if (index < 0) {
          index = this.monthsDurationsData.length - 1;
          this.updateToStore(this.monthsDurationsData[index]);
        }
      } else if (this.tab === 3) {
        const oldMonthYearSelected = this.$store.state.dashboard.selectedEmissionsByCategoryDashboard.selectedMonthYear;
        index = this.monthsDurationsData.findIndex(
          (item) => item && item.selected_range === oldMonthYearSelected?.selected_range
        );
        if (index < 0) {
          index = this.monthsDurationsData.length - 1;
          this.updateToStore(this.monthsDurationsData[index]);
        }
      }
      let listIndex = 0;

      if (this.tab === 0 || this.tab === 1) {
        this.yearSelected = this.yearsData[index];
        listIndex = this.yearsData.length - 1;
      } else {
        this.selectedMonthData = this.monthsDurationsData[index];
        listIndex = this.monthsDurationsData.length - 1;
      }

      if (index >= 0) {
        if (listIndex <= this.maxItemsYears - 1 || index <= this.maxItemsYears - 1) {
          this.selectedIndexYear = 0;
        } else if (index >= listIndex - this.maxItemsYears + 1) {
          this.selectedIndexYear = listIndex - this.maxItemsYears + 1;
        } else {
          this.selectedIndexYear = index - 1;
        }
      } else {
        this.selectedIndexYear = 0;
      }
      if (this.selectedIndexYear + this.maxItemsYears >= listIndex + 1) {
        this.isDisableNext = true;
      } else {
        this.isDisableNext = false;
      }

      if (this.selectedIndexYear <= 0) {
        this.isDisablePrev = true;
      } else {
        this.isDisablePrev = false;
      }
      this.prepareDurationsDataValue(window.innerWidth <= TABLET_WIDTH);
    },
    prepareYearList() {
      if (!this.startMonth) {
        return;
      }

      this.years = [];
      this.yearsDurationsData = [];
      this.monthsDurationsData = [];
      if (this.tab === 0) {
        this.yearsData = formatDataList(moment, this.startMonth, 'year');
        this.maxItemsYears = MAX_ITEMS_YEAR;
      } else if (this.tab === 1) {
        this.maxItemsYears = MAX_ITEMS_HALF_YEAR;
        this.yearsData = formatDataList(moment, this.startMonth, 'half-year');
      } else if (this.tab === 2) {
        this.yearsDurationsData = formatDataList(moment, this.startMonth, 'quarter-duration-month');
        this.monthsDurationsData = formatDataList(moment, this.startMonth, 'quarter-year');
        this.maxItemsYears = MAX_ITEMS_QUARTER_YEAR;
      } else if (this.tab === 3) {
        this.yearsDurationsData = formatDataList(moment, this.startMonth, 'duration-month-year');
        this.monthsDurationsData = formatDataList(moment, this.startMonth, 'month');
        this.maxItemsYears = MAX_ITEMS_MONTH_YEAR;
      }
      if (window.innerWidth <= TABLET_WIDTH) {
        this.maxItemsYears = 1;
      }
    },
    prepareDurationsDataValue(isMobile = false) {
      this.years = [];
      this.monthsDurations = [];
      this.yearsDurations = [];
      let index = 0;
      do {
        if (this.tab === 0 || this.tab === 1) {
          this.years.push(this.yearsData[index + this.selectedIndexYear]);
        } else if(this.tab === 3) {
          if (isMobile) {
            this.monthsDurations.push(this.monthsDurationsData[index + this.selectedIndexYear]);
          } else {
            this.monthsDurations.push(this.monthsDurationsData[index + Math.floor(this.selectedIndexYear/12)*12]);
          }
        } else {
          this.monthsDurations.push(this.monthsDurationsData[index + this.selectedIndexYear]);
        }
        index++;
      } while (index < this.maxItemsYears);
      if (this.monthsDurations.length) {
        let currentYear = this.monthsDurations[0]?.year;
        let itemsCount = 0;
        for (const data of this.monthsDurations) {
          if (data.year === currentYear) {
            itemsCount++;
          } else {
            this.yearsDurations.push({ value: currentYear, itemsCount });
            currentYear = data.year;
            itemsCount = 1;
          }
        }
        this.yearsDurations.push({ value: currentYear, itemsCount });
      }
    },
    onSelectedYears(item) {
      this.yearSelected = item;
      this.updateToStore(item);
      this.$emit('filterUpdated');
    },
    onSelectedMonth(item) {
      this.selectedMonthData = item;
      this.updateToStore(item);
    },
    updateToStore(item) {
      let store = {};
      switch (this.tab) {
        case 0:
          store.selectedYear = item;
          break;
        case 1:
          store.selectedHalfYear = item;
          break;
        case 2:
          store.selectedQuarterYear = item;
          break;
        case 3:
          store.selectedMonthYear = item;
          break;
        default:
          break;
      }
      this.updateSelectedEmissionsByCategoryDashboard({
        ...this.$store.state.dashboard.selectedEmissionsByCategoryDashboard,
        ...store,
      });
      this.$emit('filterUpdated');
    },
    getShowingDurations() {},
    handleMouseOverNextBtn() {
      this.isMouseOverNext = true;
      this.nextIconUrl = '';
    },
    handleTouchStartNextBtn() {
      this.prevIconUrl = '';
      this.nextIconUrl = 'img/icons/next-hover.svg';
    },
    handleTouchEndNextBtn() {
      setTimeout(() => {
        if (this.isDisableNext) {
          this.nextIconUrl = 'img/icons/next-disabled.svg';
        } else {
          this.nextIconUrl = 'img/icons/next.svg';
        }
      }, 200);
    },
    next(isMobile = false) {
      this.prevIconUrl = '';
      this.isDisablePrev = false;
      this.isDisableNext = false;
      this.nextDesktop(isMobile);
    },
    nextDesktop(isMobile = false) {
      let lengthData;
      if (this.tab === 0 || this.tab === 1) {
        lengthData = this.yearsData.length;
      } else {
        lengthData = this.monthsDurationsData.length;
      }
      if (this.selectedIndexYear + this.maxItemsYears >= lengthData) {
        this.isDisableNext = true;
        return;
      }
      this.isDisableNext = false;
      this.selectedIndexYear = this.tab === 3 ? isMobile ?  this.selectedIndexYear + 1 : this.selectedIndexYear + 12 : this.selectedIndexYear + 1;
      if (this.selectedIndexYear + this.maxItemsYears >= lengthData) {
        this.isDisableNext = true;
      }
      this.prepareDurationsDataValue(isMobile);
      if (window.innerWidth <= TABLET_WIDTH) {
        if (this.tab === 0 || this.tab === 1) {
          this.updateToStore(this.years[0]);
        } else {
          this.updateToStore(this.monthsDurations[0]);
        }
      }
      if (this.selectedIndexYear > 0) {
        this.isDisablePrev = false;
      } else {
        this.isDisablePrev = true;
      }
    },
    nextMobile() {},
    handleMouseOverPrevBtn() {
      this.isMouseOverPrev = true;
      this.prevIconUrl = '';
    },
    handleTouchStartPrevBtn() {
      this.nextIconUrl = '';
      this.prevIconUrl = 'img/icons/prev-hover.svg';
    },
    handleTouchEndPrevBtn() {
      setTimeout(() => {
        if (this.isDisablePrev) {
          this.prevIconUrl = 'img/icons/prev-disabled.svg';
        } else {
          this.prevIconUrl = 'img/icons/prev.svg';
        }
      }, 200);
    },
    prev(isMobile = false) {
      this.nextIconUrl = '';
      this.isDisableNext = false;
      this.isDisablePrev = false;
      this.prevDesktop(isMobile);
    },
    prevDesktop(isMobile = false) {
      if (this.selectedIndexYear <= 0) {
        this.isDisablePrev = true;
        return;
      }

      this.isDisablePrev = false;
      let lengthData;
      if (this.tab === 0 || this.tab === 1) {
        lengthData = this.yearsData.length;
      } else {
        lengthData = this.monthsDurationsData.length;
      }
      this.selectedIndexYear = this.tab === 3 ? isMobile ?  this.selectedIndexYear - 1 : this.selectedIndexYear - 12 : this.selectedIndexYear - 1;
      if (this.selectedIndexYear <= 0 || (this.tab === 3 && (isMobile ? this.selectedIndexYear < 1 : this.selectedIndexYear < 12))) {
        this.isDisablePrev = true;
      }
      this.prepareDurationsDataValue(isMobile);
      if (window.innerWidth <= TABLET_WIDTH) {
        if (this.tab === 0 || this.tab === 1) {
          this.updateToStore(this.years[0]);
        } else {
          this.updateToStore(this.monthsDurations[0]);
        }
      }
      if (this.selectedIndexYear + this.maxItemsYears < lengthData) {
        this.isDisableNext = false;
      }
    },
    onWindowResize() {
      window.addEventListener('resize', this.handleWindowResize);
    },
    offWindowResize() {
      window.removeEventListener('resize', this.handleWindowResize);
    },
    handleWindowResize(_) {
      if (window.innerWidth <= TABLET_WIDTH) {
        this.maxItemsYears = 1;
      }
      if(window.innerWidth != this.windowWidth) {
        this.prepareYearList();
        this.selectedFirstItem();
      }
    },
  },
};
</script>
