import i18n from '@/lang/i18n';

export const PALETTE_COLOR = [
  'rgba(0, 143, 204, 1)',
  'rgba(228, 91, 88, 1)',
  'rgba(128, 188, 98, 1)',
  'rgba(224, 163, 41, 1)',
  'rgba(159, 99, 189, 1)',
  'rgba(172, 174, 78, 1)',
  'rgba(48, 83, 170, 1)'
];

export const ASSETS_NAMES_COLORS = [
  {
    name: i18n.t('dashboard_main.label_pcaf_method_listed_stocks_bouds'),
    color: 'rgba(0, 143, 204, 1)',
    scopeName: 'emission_scope1',
    id: 5,
  },
  {
    name: i18n.t('dashboard_main.label_pcaf_method_bussiness_loans_unlisted_stocks'),
    color: 'rgba(228, 91, 88, 1)',
    scopeName: 'emission_scope2',
    id: 6,
  },
  {
    name: i18n.t('dashboard_main.label_pcaf_method_project_finance'),
    color: 'rgba(128, 188, 98, 1)',
    scopeName: 'emission_scope3',
    id: 7,
  },
  {
    name: i18n.t('dashboard_main.label_pcaf_method_commercial_estate'),
    color: 'rgba(224, 163, 41, 1)',
    scopeName: 'emission_scope4',
    id: 8,
  },
  {
    name: i18n.t('dashboard_main.label_pcaf_method_home_loan'),
    color: 'rgba(159, 99, 189, 1)',
    scopeName: 'emission_scope5',
    id: 9,
  },
  {
    name: i18n.t('dashboard_main.label_pcaf_method_car_loan'),
    color: 'rgba(172, 174, 78, 1)',
    scopeName: 'emission_scope6',
    id: 10,

  },
  {
    name: i18n.t('dashboard_main.label_pcaf_method_debt'),
    color: 'rgba(48, 83, 170, 1)',
    scopeName: 'emission_scope7',
    id: 11,
  },
];