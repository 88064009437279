export const PALETTE_COLOR = [
  'rgba(0, 143, 204, 1)',
  'rgba(228, 91, 88, 1)',
  'rgba(128, 188, 98, 1)',
  'rgba(224, 163, 41, 1)',
  'rgba(159, 99, 189, 1)',
  'rgba(172, 174, 78, 1)',
  'rgba(198, 76, 133, 1)',
  'rgba(38, 178, 191, 1)',
  'rgba(149, 87, 87, 1)',
];
export const ASSETS_NAMES_COLORS = [
  {
    name: '電気機械',
    color: 'rgba(0, 143, 204, 1)',
    scopeName: 'emission_scope1'
  },
  {
    name: '産業用電気機器',
    color: 'rgba(228, 91, 88, 1)',
    scopeName: 'emission_scope2'
  },
  {
    name: 'その他の電気機器',
    color: 'rgba(128, 188, 98, 1)',
    scopeName: 'emission_scope3'
  },
  {
    name: '情報・通信機器',
    color: 'rgba(224, 163, 41, 1)',
    scopeName: 'emission_scope4'
  },
  {
    name: '電子部品',
    color: 'rgba(159, 99, 189, 1)',
    scopeName: 'emission_scope5'
  },
  {
    name: '半導体素子・集積回路',
    color: 'rgba(172, 174, 78, 1)',
    scopeName: 'emission_scope6'
  },
  {
    name: 'その他の電子部品',
    color: 'rgba(198, 76, 133, 1)',
    scopeName: 'emission_scope7'
  },
  {
    name: '精密機械',
    color: 'rgba(38, 178, 191, 1)',
    scopeName: 'emission_scope8'
  },
  {
    name: 'その他の製造工業製品',
    color: 'rgba(149, 87, 87, 1)',
    scopeName: 'emission_scope9'
  },
];
