<template>
  <div>
    <v-dialog
      v-model="dialog"
      @click:outside="handleClickOutSite"
      scrollable
      fullscreen
      :persistent="true"
      max-width="1024"
      ref="dialog"
      content-class="home-dialog"
    >
      <v-card>
        <v-card-text dark>
          <div class="card-content"><slot></slot></div>
        </v-card-text>

        <v-card-actions class="justify-end card-footer" :class="{ 'show-button': isShowDetailButton }">
          <router-link
            v-if="isShowDetailButton"
            class="link-to-register"
            :to="{ path: getUrlToRegisterData.path, query: getUrlToRegisterData.query }"
            ><common-button
              class="detail-button"
              type="colored"
              :label="$t('dashboard_main.button_view_detail')"
            ></common-button
          ></router-link>
          <v-btn text class="btn-close" @click="handleClickOutSite">
            <img src="img/icons/close.svg" alt="Close" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.card-content {
  margin: 40px 0;
}
.v-card__actions {
  &.show-button {
    padding: 20px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    column-gap: 20px;
    .link-to-register {
      width: 100%;
      text-decoration: none;
      .detail-button {
        display: flex !important;
        height: 50px !important;
        width: 100%;
        .v-btn__content {
          color: white;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.42px;
        }
      }
    }
  }
}
.v-card__actions > .btn-close {
  width: 50px;
  height: 50px;
  border: 1px solid $goldMid;
  padding: 0;
  min-width: 50px;
}
.v-card__text {
  padding: 0 20px !important;
}

.card-footer {
  height: 90px;
  border-top: 1px solid rgba(42, 42, 48, 0.1);
}
</style>

<script>
import { mapGetters, mapState } from 'vuex';
import { ROUTES } from '@/router/constants';
import ChartDetail from '../detail-chart/index.vue';
import CommonButton from '@/components/utils/button.vue';
import { PERIOD_DASHBOARD } from "@/constants/dashboard";

export default {
  components: { ChartDetail, CommonButton },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    isShowDetailButton: {
      type: Boolean,
      default: false,
    },
    emissionsByCategoryDetail: {
      type: Object,
      default: () => {},
    },
    selectedTab: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    handleClickOutSite() {
      this.$emit('close');
    },
    getCategoryScopeOfStore() {
      let scope = '';
      let category = this.emissionsByCategoryDetail.category;
      let duration = {};
      switch (this.selectedTab) {
        case 0:
          scope = this.selectedEmissionsByCategoryDashboard.selectedYearDetail?.scope;
          duration.year = this.selectedEmissionsByCategoryDashboard.selectedYear?.year;
          duration.period = PERIOD_DASHBOARD.PERIOD_YEAR
          break;
        case 1:
          scope = this.selectedEmissionsByCategoryDashboard.selectedHalfYearDetail?.scope;
          const oldHalfYear = this.selectedEmissionsByCategoryDashboard?.selectedHalfYear;
          duration.year = oldHalfYear?.year;
          duration.order = oldHalfYear?.order;
          duration.period = PERIOD_DASHBOARD.PERIOD_HALF_YEAR
          break;
        case 2:
          scope = this.selectedEmissionsByCategoryDashboard.selectedQuarterYearDetail?.scope;
          const oldQuarterYear = this.selectedEmissionsByCategoryDashboard?.selectedQuarterYear;
          duration.year = oldQuarterYear?.year;
          duration.order = oldQuarterYear?.order;
          duration.period = PERIOD_DASHBOARD.PERIOD_QUARTER_YEAR
          break;
        case 3:
          scope = this.selectedEmissionsByCategoryDashboard.selectedMonthYearDetail?.scope;
          const oldMonthYearSelected = this.selectedEmissionsByCategoryDashboard?.selectedMonthYear;
          const [oldYear, oldMonth] = oldMonthYearSelected?.selected_range?.split('/');
          duration.year = oldYear;
          duration.month = oldMonth;
          duration.period = PERIOD_DASHBOARD.PERIOD_MONTH_YEAR
          break;
        default:
          break;
      }
      return [scope, category, duration];
    },
  },
  computed: {
    ...mapGetters("emission", ["getQuery", "getPath"]),
    ...mapState('dashboard', ['selectedEmissionsByCategoryDashboard']),
    getUrlToRegisterData() {
      const [scope, category, duration] = this.getCategoryScopeOfStore();
      let path =
        ROUTES.EMISSIONS +
        '/view' +
        '/' +
        (scope === undefined ? '1' : scope) +
        '/' +
        (category === undefined ? '1' : category);
      let query = {
        ...duration,
      };

      return { path, query };
    },
  }
};
</script>
