<template>
  <div class="trend-line-chart">
    <PageTitle :label="labelSection" iconPath="column-chart.svg" :isPcafSetting="true" />
    <TypeFilter :type="type" @update:type="handleUpdateType" :currency="currency" @update:currency="handleUpdateCurrencyType" />
    <TabFilter :tab="tab" @update:tab="handleUpdateTab" :isTrendLineChart="true" />
    <ChartData
      :assetId="assetId"
      :assetsHasRegister="assetsHasRegister" 
      :typeText="typeText"
      :type="type"
      :tab="tab"
      :chartData="chartData"
      :assetsNameTrend="assetsNameTrend"
      :yearsNotRegisterData="yearsNotRegisterData"
      :startMonth="startMonth"
      :currency="currency"
      @updateSelectedChartData="updateSelectedChartData"
    />
  </div>
</template>
<script>
import PageTitle from '@/components/products/common/page-title.vue';
import TypeFilter from '@/components/dashboard/home-pcaf/type-filter';
import TabFilter from '@/components/dashboard/home-pcaf/tab-filter';
import ChartData from '@/components/dashboard/home-pcaf/trend-line-chart/chart-data';
import { getTrendLineChartApi } from '@/api/pcaf';
import { mapActions, mapState } from 'vuex';
import { ASSETS_NAMES_COLORS } from '@/components/dashboard/home-pcaf/trend-line-chart/line-chart/define';
import { getPcafPayload, isTotalEmission } from '@/utils/pcaf';

export default {
  props: {
    branchIds: {
      type: Array,
      default: () => [],
    },
    assetId: {
      type: Number,
      default: 0,
    },
    startMonth: {
      type: Number,
      default: 4,
    }
  },
  components: { PageTitle, TypeFilter, TabFilter, ChartData },
  data() {
    return {
      tab: 0,
      type: 2,
      assetsHasRegister: [],
      chartData: [],
      selectedChartData: {},
      typeText: this.$t("dashboard_main.radio_select_investment_and_financing_amount"),
      inputTypes: [
        this.$t("dashboard_main.radio_select_investment_and_financing_amount"),
        this.$t("dashboard_main.radio_select_ghg_emissions_"),
      ],
      assetsNameTrend: [],
      yearsNotRegisterData: [],
      currency: 1,
    };
  },
  watch: {
    propsChange: {
      handler() {
        this.getChartData()
      }
    },
    assetId: {
      handler() {
        this.getChartData()
      }
    }
  },
  created() {
    this.tab = this.dashboard.trendLineChart.selectedTab;
    this.type = this.dashboard.trendLineChart.selectedType || 2;
    this.currency = this.dashboard.trendLineChart.selectedCurrency || 1;
    this.typeText = this.inputTypes[this.type];
    this.getChartData();
  },
  computed: {
    ...mapState('pcaf', ['dashboard']),

    propsChange() {
      return this.type + this.tab + this.branchIds + this.currency;
    },
    labelSection() {
      return this.$t("dashboard_main.title_trends_by_asset_class");
    }
  },
  methods: {
    ...mapActions('pcaf', ['updateTrendLineChart']),

    handleUpdateType(value, text) {
      this.type = value;
      this.typeText = text;
      this.updateTrendLineChart({
        ...this.dashboard.trendLineChart,
        selectedType: value
      })
    },
    handleUpdateTab(value) {
      this.tab = value;
      this.updateTrendLineChart({
        ...this.dashboard.trendLineChart,
        selectedTab: value
      })
    },
    async getChartData() {
      let payload = {
        contractor_id: this.$store.state.userData.contractor,
        branch_id: this.branchIds,
      };
      if (this.assetId !== 0) {
        payload.pcaf_select = [this.assetId];
      }
      const pcafPayload = getPcafPayload(this.type, this.currency);
      payload.data_type = pcafPayload[0];
      payload.pcaf_type = pcafPayload[1];
      await getTrendLineChartApi(payload).then((res) => {
        let dataResponse = res;
        this.assetsNameTrend = dataResponse?.data.map((dataItem) => {
          if(dataItem.pcaf_name.includes('_')) {
            dataItem.pcaf_name = dataItem.pcaf_name.replace('_', '/');
          }
          return  dataItem.pcaf_name;
        })
        //filter total assets !== 0
        const dataAssets = dataResponse?.data.filter(assets => {
          const isNullData = assets?.detail.every(item => isTotalEmission(this.type) ? item.total_emission === null : item.total_amount === null )
          return !isNullData;
        })
        dataResponse.data = dataAssets;

        if(isTotalEmission(this.type)) {
          const CALCULATOR_TYPE = 'total_emission';
          if(this.tab === 0) { //line chart
            this.chartData = this.transferDataLine(CALCULATOR_TYPE, dataResponse);
          }else { //column chart
            this.chartData = this.transferDataColumn(CALCULATOR_TYPE, dataResponse);
          }
        }
        else {
          const CALCULATOR_TYPE = 'total_amount';
          if(this.tab === 0) { //line chart
            this.chartData = this.transferDataLine(CALCULATOR_TYPE, dataResponse);
          }else { //column chart
            this.chartData = this.transferDataColumn(CALCULATOR_TYPE, dataResponse);
          }
        }
      })
    },
    transferDataLine(CALCULATOR_TYPE, dataResponse) {
      if(dataResponse.data.length === 0) { 
        this.yearsNotRegisterData = dataResponse.totalEmissionGhg.map(emission => ({year: emission?.year.toString()}));
        return [];
      }
      let transformedDataLine = dataResponse.totalEmissionGhg.map((detailItem) => {
        const newItem = { year: detailItem?.year?.toString() };
        dataResponse.data.forEach((dataItem, index) => {
          const detail = dataItem.detail.find((detail) => detail.year === detailItem?.year);
          if(dataItem.pcaf_name.includes('_')) {
            dataItem.pcaf_name = dataItem.pcaf_name.replace('_', '/');
          }
          newItem[('item' + index + dataItem.pcaf_name).toString()] = detail ? +detail[CALCULATOR_TYPE] : null;
          newItem[('detail' + index + dataItem.pcaf_name).toString()] = detail;
        });
        newItem.detail = detailItem;
        return newItem;
      });
      if(transformedDataLine.length === 0) {
        const yearsAll = dataResponse.data[0].detail.map(detailItem => ({year: detailItem.year}))
        transformedDataLine = yearsAll.map(detailItem => {
          const newItem = { year: detailItem?.year?.toString() };
          dataResponse.data.forEach((dataItem, index) => {
            const detail = dataItem.detail.find((detail) => detail.year === detailItem?.year);
            if(dataItem.pcaf_name.includes('_')) {
              dataItem.pcaf_name = dataItem.pcaf_name.replace('_', '/');
            }
            if(detail) {
              newItem[('item' + index + dataItem.pcaf_name).toString()] = detail ? +detail[CALCULATOR_TYPE] : null;
              newItem[('detail' + index + dataItem.pcaf_name).toString()] = detail;
            }
            // newItem.year = detailItem?.year?.toString()
          });
          newItem.detail = detailItem;
          return newItem;
        })
      }
     return transformedDataLine;
    },
    transferDataColumn(CALCULATOR_TYPE, dataResponse) {
      let transformedDataColumn = dataResponse.totalEmissionGhg.map(item => {
        const total_emission_original = item.total_emission
        const result = {
          time_range: item?.year?.toString(),
          emission_scope1: null,
          emission_scope2: null,
          emission_scope3: null,
          emission_scope4: null,
          emission_scope5: null,
          emission_scope6: null,
          original: {
            month: item?.year?.toString(),
            year: item?.year?.toString(),
            duration_id: item.duration_id,
            // total_emission: item.CALCULATOR_TYPE,
          },
          zero_value_percent: '0%',
          year: item?.year?.toString(),
        };
        
        let total_emissionCalc = 0;
        dataResponse.data.forEach((category, index) => {
          const detailItem = category.detail.find(detail => detail.year.toString() === item?.year.toString());
          if(category.pcaf_name.includes('_')) {
            category.pcaf_name = category.pcaf_name.replace('_', '/');
          }
          detailItem['nameAssets'] = category?.pcaf_name;
          const findIndex = this.assetsNameTrend.findIndex(assetsName => assetsName === category.pcaf_name.replace('_', '/'))
          detailItem['color'] = this.assetId === 0 ? ASSETS_NAMES_COLORS[findIndex]?.color : ASSETS_NAMES_COLORS.find(color => color.id === this.assetId)?.color;
          if (detailItem) {
            result[ASSETS_NAMES_COLORS[findIndex]?.scopeName] = detailItem[CALCULATOR_TYPE]
            total_emissionCalc+=  +detailItem[CALCULATOR_TYPE]
            result['detail' + ASSETS_NAMES_COLORS[findIndex]?.scopeName] = detailItem;
          }
        });
        result.original = item;
        result.original.total_emission = total_emissionCalc;
        result.detail = item;
        result.detail.total_emission_original = total_emission_original
        return result;
      });
      if(transformedDataColumn.length === 0) {
        const yearsAll = dataResponse.data[0].detail.map(detailItem => ({year: detailItem.year}))
        transformedDataColumn = yearsAll.map(item => {
          const result = {
          time_range: item?.year?.toString(),
          original: {
            month: item?.year?.toString(),
            year: item?.year?.toString(),
            duration_id: item?.duration_id,
            // total_emission: item.CALCULATOR_TYPE,
          },
          zero_value_percent: '0%',
          year: item?.year?.toString(),
        };

        let total_emissionCalc = 0;
        dataResponse.data.forEach((category, index) => {
          const detailItem = category.detail.find(detail => detail.year.toString() === item?.year.toString());
          if(category.pcaf_name.includes('_')) {
            category.pcaf_name = category.pcaf_name.replace('_', '/');
          }
          const assetsData = ASSETS_NAMES_COLORS.find(assetsData => {
            return category.pcaf_name.replace('_', '/') === assetsData.name
          })
          detailItem['nameAssets'] = category?.pcaf_name;
          if (detailItem) {
            result[assetsData?.scopeName] = detailItem[CALCULATOR_TYPE];
            total_emissionCalc= total_emissionCalc + Number(detailItem[CALCULATOR_TYPE])
            result['detail' + assetsData?.scopeName] = detailItem;
          }
        });
        result.original[CALCULATOR_TYPE] = total_emissionCalc;
        return result;
        })
      }
      return transformedDataColumn;
    },
    updateSelectedChartData(chartData) {
      this.selectedChartData = chartData;
    },
    handleUpdateCurrencyType(value) {
      this.currency = value;
      this.updateTrendLineChart({
        ...this.dashboard.trendLineChart,
        selectedCurrency: value
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.trend-line-chart {
  padding: 16px;
  background-color: $monoWhite;
  border-radius: 8px;
  margin-top: 24px;

  .product-title {
    margin-top: 0;
  }
}

@media only screen and (max-width: $desktop) {
  .trend-line-chart {
    padding: 8px;
  }
}
</style>
