<template>
  <div>
    <v-dialog
      v-model="isDialogVisible"
      @click:outside="handleClickOutside"
      ref="dialog"
      max-width="464px"
      content-class="title-close settings-unit-electricity"
    >
      <v-card>
        <v-card-title>
          <span class="unit-title">{{ $t('location_market.title_unit_settings') }}</span>
          <div class="button-close">
            <img @click="close" src="@/assets/images/dialog/close.svg" class="button-close_close"/>
            <img @click="close" src="@/assets/images/dialog/close_active.svg" class="button-close_active"/>
          </div>
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="selectedUnit" hide-details class="type-radio-settings">
            <v-radio
              v-for="(unit, index) in unitOptions"
              :key="index"
              :label="unit.name"
              :value="unit.value"
              :ripple="false"
            />
          </v-radio-group>
          <span class="electric-title">{{ $t('location_market.title_electric_settings') }}</span>
          <v-radio-group v-model="selectedElectricity" hide-details class="type-radio-settings">
            <v-radio
              v-for="(electricity, index) in electricityOptions"
              :key="index"
              :label="electricity.name"
              :value="electricity.value"
              :ripple="false"
            />
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <CommonButton class="left-button-popup" @action="close" :label="cancelButtonLabel" />
          <CommonButton class="right-button-popup light-green-color" @action="submit" :label="$t('location_market.label_button_submit')" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CommonButton from '@/components/commonApp/Button.vue';

export default {
  components: { CommonButton },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    initialUnit: {
      type: Number,
      default: 1,
    },
    initialElectricity: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      selectedUnit: this.initialUnit,
      selectedElectricity: this.initialElectricity,
      unitOptions: [
        { name: 't-CO2', value: 1 },
        { name: 'kg-CO2', value: 2 },
      ],
      electricityOptions: [
        { name: this.$t('location_market.label_ratio_market', { standardLabel: '' }), value: 1, type: 'market' },
        { name: this.$t('location_market.label_ratio_location', { standardLabel: '' }), value: 2, type: 'location' },
      ],
    };
  },
  computed: {
    isDialogVisible: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
    cancelButtonLabel() {
      return this.$t('user_management.button_cancel');
    },
  },
  methods: {
    close() {
      this.resetSelections();
      this.$emit('close');
    },
    submit() {
      // Emit the selected values to the parent
      const unitItem = this.unitOptions.find(u => u.value === this.selectedUnit);
      const electricItem = this.electricityOptions.find(e => e.value === this.selectedElectricity);
      this.$emit('submit', {
        selectedUnit: this.selectedUnit,
        selectedElectricity: this.selectedElectricity,
        unitName: unitItem?.name,
        electricName: electricItem?.name,
        type: electricItem?.type
      });
    },
    handleClickOutside() {
      this.close();
    },
    resetSelections() {
      // Reset the selected values to the initial props
      this.selectedUnit = this.initialUnit;
      this.selectedElectricity = this.initialElectricity;
    },
  },
  watch: {
    initialUnit: {
      handler(val) {
        this.selectedUnit = val
      },
      deep: true,
    },
    initialElectricity: {
      handler(val) {
        this.selectedElectricity = val
      },
      deep: true,
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
.type-radio-settings {
  ::v-deep .v-input__slot {
    display: flex;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    padding: 16px 20px;
  }
  ::v-deep .v-input--radio-group__input {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    gap: 8px;
    flex: 1 0 0;
  }
  ::v-deep .v-radio {
    display: flex;
    align-items: center;
    flex: 1 0 0;
    label {
      color: $monoBlack;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.42px;
    }
  }
  ::v-deep .v-input__control {
    .v-input__slot {
      .v-radio {
        margin-bottom: 2px !important;
        .v-input--selection-controls__input {
          margin-top: 3px !important;
        }
      }
    }
  }
}
.electric-title {
  display: flex;
  padding: 16px 20px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  color: $monoBlack;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.42px;
  border-bottom: 1px solid rgba(42, 42, 48, 0.1);
}
.unit-title {
  color: $monoBlack;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.42px;
}
.v-dialog__content--active .v-card .v-card__text {
  padding: 0 !important;
}
.v-dialog__content--active .v-card .v-card__title {
  padding: 16px 32px;
}
.settings-unit-electricity {
  max-width: 464px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
}
@include desktop {
  .type-radio-settings {
    ::v-deep .v-input__slot {
      padding: 16px 32px;
    }
  }
  .electric-title {
    padding: 16px 32px;
  }
}
</style>
