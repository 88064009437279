<template>
  <div class="category-wrapper">
    <div class="category-header">
      <span class="category__title">
        <span> <img src="img/icons/time-2.svg" alt="time" /> </span>
        <h2 class="category__txt">{{ $t("dashboard_main.title_ghg_emissions_by_category") }}</h2>
      </span>

      <!-- <span class="category__icon"><img src="@/assets/icons/multiple.svg" /></span> -->
    </div>
    <div class="tabs-header-category">
      <v-tabs v-model="selectedTab" color="#404D50" background-color="transparent">
        <v-tab v-for="(tab, index) in tabs" :key="index">{{ tab }}</v-tab>
      </v-tabs>
    </div>

    <div class="period-combobox">
      <FilterDuration @filterUpdated="handleGetDataChartUpdated" :tab="selectedTab" :startMonth="startMonth" />
    </div>
    <div class="tabs-content">
      <EmissionCategoryChart
        :filterUpdatedKeys="filterUpdatedKeys"
        :branchIds="branchIds"
        :selectedTab="selectedTab"
        :isGetAllFilter="isGetAllFilter"
        :isLoading="isLoading"
        :startMonth="startMonth"
      />
    </div>
  </div>
</template>

<script>
import EmissionCategoryChart from '@/views/dashboard/components/emissions-by-category/emissions-category-chart/index.vue';
import FilterDuration from '@/views/dashboard/components/emissions-by-category/filter-duration/index.vue';
import { mapActions } from 'vuex';

export default {
  components: { EmissionCategoryChart, FilterDuration },
  props: {
    branchIds: {
      type: Array,
      default: () => [],
    },
    isGetAllFilter: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    startMonth: {
      type: Number,
      default: 3,
    }
  },
  data() {
    return {
      selectedTab: 0,
      selectedDuration: 0,
      // items: [],
      windowWidth: window.innerWidth,
      defaultDurationId: 0,
      defaultIsSelected: false,
      // tabs: ['cuong', '半期別', '四半期別', '月別'],
      filterUpdatedKeys: 0
    };
  },
  created() {
    this.selectedTab = this.$store.state.dashboard.selectedEmissionsByCategoryDashboard.selectedTab;
  },
  watch: {
    selectedTab(newVal) {
      this.updateSelectedEmissionsByCategoryDashboard({
        ...this.$store.state.dashboard.selectedEmissionsByCategoryDashboard,
        selectedTab: newVal,
      });
    },
    selectedDuration() {
    
    },
    getDurationByCategoryWithYearly() {
    
    },
  },
  computed: {
    tabs() {
      return [this.$t("dashboard_main.tab_by_year"), this.$t("dashboard_main.tab_semi_annual"), this.$t("dashboard_main.tab_quarterly"), this.$t("dashboard_main.tab_by_month")]
    }
  },
  methods: {
    ...mapActions('dashboard', ['updateSelectedEmissionsByCategoryDashboard']),
    defaultSelectedFirstDuration() {
    },

    getDurationData() {},
    handleGetDataChartUpdated() {
      this.filterUpdatedKeys ++;
    }
  },
};
</script>

<style lang="scss" scoped>
.tabs-content {
  .theme--light.v-tabs-items {
    background-color: transparent;
  }
}

.category-header {
  margin-top: 40px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;

  .category__title {
    font-size: 16px;
    display: flex;

    .category__txt {
      font-family: 'Source Han Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.1em;
      color: #404d50;
      padding-left: 16.5px;
      display: flex;
      align-items: center;
    }
  }
  .category__icon {
    display: flex;
  }
}

.tabs-header-category {
  margin-bottom: 16px;
  height: 38px;
}

.period-combobox {
  width: 100%;
}

@include desktop {
  .category-header {
    margin: 16px 0px;
  }

  .tabs-header-category {
    margin-bottom: 16px;
  }

  .period-combobox {
  }
}
</style>
<style lang="scss">

@media only screen and (max-width : 1024px) {
  .tabs-header-category {
    .v-tabs-slider-wrapper {
      width: 25% !important;
    }
  }
}
</style>
