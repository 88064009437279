<template>
  <div class="column-chart">
    <PageTitle :label="titleSection" iconPath="column-chart.svg" :isPcafSetting="true" />
    <TypeFilter :type="type" @update:type="handleUpdateType" :currency="currency" @update:currency="handleUpdateCurrencyType" />
    <TabFilter :tab="tab" @update:tab="handleUpdateTab" />
    <div class="chart-data">
      <ChartData :typeText="typeText" :key="key" :chartData="chartData" :tab="tab" :type="type" :currency="currency"
        @updateSelectedChartData="updateSelectedChartData" />
      <ChartDetail :selectedChartData="selectedChartData" :type="type" :tab="tab" :typeText="typeText" :currency="currency" class="chart-detail" />
      <ChartDetailPopup :dialog="dialogChartDetail" @close="dialogChartDetail = false">
        <ChartDetail :selectedChartData="selectedChartData" :type="type" :tab="tab" :typeText="typeText" :currency="currency" :isPopup="true" />
      </ChartDetailPopup>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import PageTitle from '@/components/products/common/page-title.vue';
import TypeFilter from '@/components/dashboard/home-pcaf/type-filter';
import TabFilter from '@/components/dashboard/home-pcaf/tab-filter';
import ChartData from '@/components/dashboard/home-pcaf/column-chart/chart-data';
import ChartDetail from '@/components/dashboard/home-pcaf/column-chart/chart-detail';
import ChartDetailPopup from '@/components/dashboard/home-pcaf/dialogs/DetailChartPopup.vue';
import { getDashboardApi } from '@/api/pcaf';
import { getPcafPayload } from '@/utils/pcaf';
export default {
  components: { PageTitle, TypeFilter, TabFilter, ChartData, ChartDetail, ChartDetailPopup },
  props: {
    branchIds: {
      type: Array,
      default: () => [],
    },
    assetId: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      tab: 0,
      type: 2,
      typeText: this.$t("dashboard_main.radio_select_investment_and_financing_amount"),
      chartData: [],
      selectedChartData: {},
      key: 0,
      dialogChartDetail: false,
      inputTypes: [
        this.$t("dashboard_main.radio_select_investment_and_financing_amount"),
        this.$t("dashboard_main.radio_select_ghg_emissions_"),
      ],
      isLoading: false,
      currency: 1,
    }
  },
  watch: {
    watchData: {
      handler() {
        if (!this.isLoading) return;
        this.getChartData();
      },
      immediate: true,
    }
  },
  computed: {
    ...mapState('userData', ['contractor']),
    ...mapState('pcaf', ['dashboard']),
    watchData() {
      return this.type + this.tab + this.branchIds + this.assetId + this.isLoading + this.currency;
    },
    titleSection() {
      return this.$t("dashboard_main.title_trends_in_investment_and_loan_amounts_and_ghg_emissions")
    }
  },
  created() {
    this.type = this.dashboard.columnChart.selectedType || 2;
    this.tab = this.dashboard.columnChart.selectedTab;
    this.currency = this.dashboard.columnChart.selectedCurrency || 1;
    this.typeText = this.inputTypes[this.type];
    this.isLoading = true;
  },
  methods: {
    ...mapActions('pcaf', ['updateColumnChart']),
    handleUpdateType(value, text) {
      this.type = value;
      this.typeText = text;
      this.updateColumnChart({
        ...this.dashboard.columnChart,
        selectedType: value,
      });
    },
    handleUpdateTab(value) {
      this.tab = value;
      this.updateColumnChart({
        ...this.dashboard.columnChart,
        selectedTab: value,
      });
    },
    updateSelectedChartData(chartData, isMobile, isClicked = true) {
      this.selectedChartData = chartData;
      if (isMobile && isClicked) {
        this.dialogChartDetail = true;
      }
      let storeData = {};
      switch (this.tab) {
        case 0:
          storeData = {
            ...this.dashboard.columnChart,
            selectedHalfYear: null,
            selectedQuarterYear: null,
            selectedMonthYear: null,
            selectedYear: chartData.year,
          }
          break;
        case 1:
          storeData = {
            ...this.dashboard.columnChart,
            selectedYear: null,
            selectedMonthYear: null,
            selectedQuarterYear: null,
            selectedHalfYear: chartData.time_range,
          }
          break;
        case 2:
          storeData = {
            ...this.dashboard.columnChart,
            selectedYear: null,
            selectedHalfYear: null,
            selectedMonthYear: null,
            selectedQuarterYear: chartData.time_range,
          }
          break;
        default:
          storeData = {
            ...this.dashboard.columnChart,
            selectedYear: null,
            selectedHalfYear: null,
            selectedQuarterYear: null,
            selectedMonthYear: `${chartData.month}-${chartData.year}`,
          }
          break;
      }
      this.updateColumnChart(storeData);
    },
    getChartData() {
      const payload = {
        contractor_id: this.contractor,
        duration_type: [this.tab + 1],
      }
      if (this.branchIds.length > 0) {
        payload.branch_id = this.branchIds;
      }
      if (this.assetId !== 0) {
        payload.pcaf_select = [this.assetId];
      }
      const pcafPayload = getPcafPayload(this.type, this.currency);
      payload.data_type = pcafPayload[0];
      payload.pcaf_type = pcafPayload[1];
      getDashboardApi(payload).then(res => {
        switch (this.tab) {
          case 0:
            this.chartData = res.data['year'];
            break;
          case 1:
            this.chartData = res.data['half_year'];
            break;
          case 2:
            this.chartData = res.data['quarter_year'];
            break;
          default:
            this.chartData = res.data['month_year'];
            break;
        }
        this.key++;
      })
    },
    handleUpdateCurrencyType(value) {
      this.currency = value;
      this.updateColumnChart({
        ...this.dashboard.columnChart,
        selectedCurrency: value,
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.column-chart {
  padding: 16px;
  background-color: $monoWhite;
  border-radius: 8px;

  .product-title {
    margin-top: 0;
  }
}

.chart-data {
  display: flex;
  flex-flow: row;
  padding-top: 16px;
  justify-content: space-between;
  .chart-detail {
    display: none;
  }
}
@include desktop {
  .chart-data {
    .chart-detail {
      display: block;
    }
  }
}

@media only screen and (max-width: $desktop) {
  .column-chart {
    padding: 8px;
  }
}
</style>