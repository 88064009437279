<template>
  <div class="container-chart">
    <div class="assets-type-list">
      <div class="assets-type-item" v-for="(item, index) in assetsTypes" :key="index">
        <span class="assets-point" :style="{ backgroundColor: item.color }"></span>
        <span class="assets-title">{{ item.name }}</span>
      </div>
    </div>
    <div v-if="tab === 0">
      <line-chart
        :typeText="typeText"
        :tab="tab"
        :type="type"
        :chartData="chartData"
        :assetsNameTrend="assetsNameTrend"
        @updateSelectedChartData="updateSelectedChartData"
        :yearsNotRegisterData="yearsNotRegisterData"
        :assetsTypes="assetsTypes"
        :startMonth="startMonth"
        :currency="currency"
      />
    </div>
    <div v-if="tab === 1" class="trend-chart-container">
      <column-chart
        :typeText="typeText"
        :type="type"
        :tab="tab"
        @updateSelectedChartData="updateSelectedChartData"
        :chartData="chartData"
        :totalScopes="totalScopes"
        :assetsTypes="assetsTypes"
        :currency="currency"
      />
      <ChartDetailColumn
        :typeText="typeText"
        :type="type"
        :selectedChartData="selectedChartData"
        :trendTotalAssets="getTrendTotalAssets"
        :currency="currency"
      />
    </div>
    <ChartDetailPopup v-if="tab === 1" :dialog="dialogChartDetail" @close="dialogChartDetail = false">
      <ChartDetailColumn
        :typeText="typeText"
        :type="type"
        :selectedChartData="selectedChartData"
        :trendTotalAssets="getTrendTotalAssets"
        :isPopup="true"
        :currency="currency"
      />
    </ChartDetailPopup>
    <ChartDetailPopup v-if="tab === 0" :dialog="dialogChartDetail" @close="dialogChartDetail = false">
      <ChartDetailLine
        :typeText="typeText"
        :type="type"
        :selectedChartData="selectedChartData"
        :trendTotalAssets="getTrendTotalAssets"
        :isPopup="true"
        :currency="currency"
      />
    </ChartDetailPopup>
  </div>
</template>
<script>
import ColumnChart from '@/components/dashboard/home-pcaf/trend-industry-chart/column-chart/index.vue';
import LineChart from '@/components/dashboard/home-pcaf/trend-industry-chart/line-chart/index.vue';
import ChartDetailColumn from '@/components/dashboard/home-pcaf/trend-industry-chart/column-chart/chart-detail.vue';
import ChartDetailLine from '@/components/dashboard/home-pcaf/trend-industry-chart/line-chart/chart-detail.vue';
import ChartDetailPopup from '@/components/dashboard/home-pcaf/dialogs/DetailChartPopup.vue';

export default {
  components: { ColumnChart, LineChart, ChartDetailPopup, ChartDetailLine, ChartDetailColumn },
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    tab: {
      type: Number,
      default: 0,
    },
    type: {
      type: Number,
      default: 0,
    },
    typeText: {
      type: String,
      default: '',
    },
    assetsNameTrend: {
      type: Array,
      default: () => [],
    },
    yearsNotRegisterData: {
      type: Array,
      default: () => [],
    },
    totalScopes: {
      type: Array,
      default: () => [],
    },
    startMonth: {
      type: Number,
      default: 4,
    },
    currency: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      tabChart: 0,
      assetsTypes: [],
      selectedChartData: {},
      dialogChartDetail: false,
    };
  },
  methods: {
    updateSelectedChartData(chartData, isMobile, isClicked = false) {
      this.selectedChartData = chartData;
      if (isMobile && isClicked) {
        this.dialogChartDetail = true;
      }
    },
    getRandomColor() {
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      const alpha = 1;

      return `rgba(${r},${g},${b},${alpha})`;
    }
  },
  computed: {
    getTrendTotalAssets() {
      return this.assetsNameTrend.map((name, index) => {
        return {
          text: name,
          borderColor: this.assetsTypes[index]?.color,
          value: this.selectedChartData['emission_scope'+(index)]
        }
      })
    },
  },
  mounted() {
    // this.assetsTypes = ASSETS_NAMES_COLORS;
  },
  watch: {
    chartData: {
      handler() {},
      deep: true,
    },
    assetsNameTrend: {
      handler() {
        this.assetsTypes = this.assetsNameTrend.map((name, index) => {
          return {
            name: name,
            color:  this.getRandomColor()
          }
        })

      },
      deep: true,
    }
  },
};
</script>
<style lang="scss" scoped>
.container-chart {
  width: 100%;
  padding-top: 16px;
}
.assets-type-list {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 16px;
  .assets-type-item {
    margin-right: 8px;
    line-height: 14px;
  }
  .assets-point {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 8px;
  }
  .assets-title {
    font-size: 11px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-align: left;
    color: rgba(64, 77, 80, 1);
  }
}
.trend-chart-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
}
</style>
