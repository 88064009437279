<template>
  <div class="padding-top-layout mr-md-40-px">
    <div class="page-title-header">
      <page-title class="page-title" :class="!isExistPcaf && 'mb-0'" :title="dashboardTitle"/>
      <div v-if="isExistPcaf" class="tabs-header__title">
        {{ $t("dashboard_main.title_calculation_method_used_for_presentation") }}
        <div class="helper-icon parent">
          <img
            :src="getSettingIcon('helper-icon.svg')"
            @mouseover="changeTooltipImage($event)"
            @mouseleave="changeTooltipImage($event, 'mouseleave')"
            alt=""
          />
          <div v-if="showTooltip" class="helper-icon tooltip" v-html="tooltipText"></div>
        </div>
        <v-tabs
          v-model="selectedTab"
          color="#404D50"
          background-color="transparent"
          active-class="v-tab-header--active"
          height="40"
          hide-slider
        >
          <v-tab>{{ $t("dashboard_main.tab_standard") }}</v-tab>
          <v-tab v-if="isExistPcaf">PCAF</v-tab>
        </v-tabs>
      </div>
    </div>
    <div class="switch-display-format">
      <div class="info-block">
        <h4 class="label">{{ $t('location_market.label_current_display') }}</h4>
        <div class="option-selected">
          {{ getUnitName }}
        </div>
        <div class="option-selected">{{ getElectricName }}</div>
      </div>
      <div class="separate"></div>
      <common-button
        class="common-btn"
        type="colored"
        :label="$t('location_market.button_switch_display_format')"
        @action="dialog = !dialog"
      ></common-button>
    </div>
    <div class="dashboard-home">
      <div class="dashboard-filter">
        <dashboard-filter @update="handlerUpdateBranchIds" @updateAssetId="handlerUpdateAssetId" :tab="selectedTab"/>
      </div>
      <div class="tabs-content">
        <HomeStandard v-if="selectedTab === 0" :branchIds="branchIds" :isGetAllFilter="isGetAllFilter" :isLoading="isLoading" />
        <HomePCAF v-if="selectedTab === 1" :branchIds="branchIds" :assetId="assetId" />
      </div>
    </div>
    <settings-unit-electricity
      :dialog="dialog"
      @close="dialog = false"
      @submit="handleSubmit"
      :initialUnit="getSelectedUnit"
      :initialElectricity="getSelectedElectricity"
    />
  </div>
</template>

<script>
import PageTitle from '@/components/pageTitle/pageTitle.vue';
import HomeStandard from './home-standard.vue';
import HomePCAF from './home-PCAF.vue'
import DashboardFilter from './components/dashboard-filter/index.vue';
import { mapState, mapActions, mapGetters } from "vuex";
import { ROUTES } from "@/router/constants";
import SettingsUnitElectricity from '@/components/dialogs/settings-unit-electricity';
import i18n from '@/lang/i18n.js';
import CommonButton from '@/components/utils/button.vue';

export default {
  components: {
    HomeStandard,
    HomePCAF,
    DashboardFilter,
    PageTitle,
    SettingsUnitElectricity,
    CommonButton
  },
  data() {
    return {
      selectedTab: 0,
      branchIds: [],
      showTooltip: false,
      assetId: 0,
      isGetAllFilter: true,
      isLoading: true,
      unitName: 't-CO2',
      electricName: i18n.t('location_market.label_ratio_market', {
        standardLabel: i18n.t('location_market.label_standard'),
      }),
      selectedUnit: 1,
      selectedElectricity: 1,
      dialog: false,
    };
  },
  async mounted() {
    const breadcrum = [
      {
        text: this.$t('dashboard_main.title_dashboard'),
        disabled: true,
        href: ROUTES.HOME_DASHBOARD
      },
    ]
    this.updateBreadCrumb(breadcrum)
  },
  computed: {
    ...mapState('userData', ['planType', 'isExistPcaf']),
    ...mapState('settingsDashboard', ['itemsSettings']),
    ...mapGetters({
      contractor: 'userData/getContractor',
    }),
    tooltipText() {
      return this.$t("dashboard_main.tooltip_selecting_tab");
    },
    dashboardTitle() {
      return this.$t("dashboard_main.title_dashboard");
    },
    getUnitName() {
      return this.itemsSettings[this.contractor]?.unitName || this.unitName;
    },
    getElectricName() {
      if (
        this.itemsSettings[this.contractor]?.electricName ===
        this.$t('location_market.label_ratio_market', { standardLabel: '' })
      ) {
        return this.$t('location_market.label_ratio_market', {
          standardLabel: this.$t('location_market.label_standard'),
        });
      } else if (
        this.itemsSettings[this.contractor]?.electricName ===
        this.$t('location_market.label_ratio_location', { standardLabel: '' })
      ) {
        return this.$t('location_market.label_ratio_location', {
          standardLabel: this.$t('location_market.label_standard'),
        });
      }
      return this.electricName;
    },
    getSelectedUnit() {
      return this.itemsSettings[this.contractor]?.selectedUnit || this.selectedUnit;
    },
    getSelectedElectricity() {
      return this.itemsSettings[this.contractor]?.selectedElectricity || this.selectedElectricity;
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.isStayingDashboardDetailRoutes(to, from)) {
        vm.actionUpdateSettings({});
      }
    });
  },
  methods : {
    ...mapActions('commonApp', ['updateBreadCrumb']),
    ...mapActions('settingsDashboard', ['actionUpdateSettings']),
    isStayingDashboardDetailRoutes(to, from) {
      const dashboardPath = ROUTES.DASHBOARD;
      const emissionsPathByPeriod = ROUTES.EMISSIONS + '/' + ROUTES.EMISSIONS_BY_BASE_PERIOD;
      const emissionsPathByCategory = ROUTES.EMISSIONS + '/' + ROUTES.EMISSIONS_BY_BASE_CATEGORY;

      return (
        (to.path === dashboardPath && (from.path.startsWith(emissionsPathByPeriod) || from.path.startsWith(emissionsPathByCategory))) ||
        (from.path === dashboardPath && (to.path.startsWith(emissionsPathByPeriod) || to.path.startsWith(emissionsPathByCategory)))
      );
    },
    handleSubmit(val) {
      this.dialog = false;
      const dataItemsSettingsStore = {};
      dataItemsSettingsStore[this.contractor] = val;

      this.actionUpdateSettings(dataItemsSettingsStore);
    },
    handlerUpdateBranchIds(branchIds, isGetAllFilter) {
      this.branchIds = branchIds;
      this.isGetAllFilter = isGetAllFilter;
      this.isLoading = false;
    },
    handlerUpdateAssetId(assetId) {
      this.assetId = assetId;
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return "";
    },
    changeTooltipImage(event, type) {
      if (type === "mouseleave") {
        event.target.src                        = this.getSettingIcon("helper-icon.svg");
        event.target.parentElement.style.cursor = "default";
        this.showTooltip                        = false;
      } else {
        event.target.src                        = this.getSettingIcon("helper-icon_active.svg");
        event.target.parentElement.style.cursor = "pointer";
        this.showTooltip                        = true;
      }
    },
  },
 watch: {
  selectedTab: {
    handler(value) {
      if (value === 0) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
        }, 100);
      }
    }
  },
 }
};
</script>
<style lang="scss" scoped>
$separateBgColor: rgb(42 42 48 / 10%);

.switch-display-format {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 12px 16px;
  background-color: $monoOffWhite;
  margin: 0 16px 24px;
  border-radius: 4px;

  > .info-block {
    display: flex;
    align-items: center;
    gap: 10px;
    > .label {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.42px;
      color: $backDark;
    }

    > .option-selected {
      font-size: 11px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.33px;
      padding: 2px 8px 3px;
      color: $goldMid2;
      border-radius: 2px;
      border: 1px solid $goldMid2;
      user-select: none;
    }
  }

  > .separate {
    width: 1px;
    height: 40px;
    background-color: $separateBgColor;
    display: none;
  }

  > .common-btn {
    letter-spacing: 0.42px;
    line-height: 24px;
  }
}

@include tablet {
  .switch-display-format {
    flex-direction: row;
    padding: 8px 16px;
    width: max-content;
    margin-left: 24px;
    margin-right: 0;
    > .separate {
      display: block;
    }
  }
}

.page-title-header {
  margin: 24px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding-left: 16px;

  @media only screen and (min-width: 640px) {
    padding-left: 24px;
  }

  .page-title {
    margin: 0 !important;
  }

  .mt-header-title {
    margin-top: 24px !important;
  }

  .tabs-header__title {
    word-break: keep-all;
    padding-right: 24px;

    @media only screen and (max-width: 1023px) {
      padding-top: 40px;
    }
  }

  @media only screen and (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;

    .page-title {
      margin-bottom: 0;
    }

    .tabs-header__title {
      padding-top: 0;
    }
  }

  ::v-deep {
    .v-slide-group__wrapper {
      border-radius: 4px;
      overflow: hidden;
      box-shadow: 0px 36px 33px 0px rgba(160, 181, 186, 0.07), 0px 13.141px 12.046px 0px rgba(160, 181, 186, 0.11), 0px 6.38px 5.848px 0px rgba(160, 181, 186, 0.13), 0px 3.127px 2.867px 0px rgba(160, 181, 186, 0.17), 0px 1.237px 1.134px 0px rgba(160, 181, 186, 0.24);
    }

    .v-tab.v-tab-header--active {
      background-color: $blueMid;
      color: $monoWhite !important;

      &:hover {
        opacity: 0.9;
        transition: opacity 0.2s linear;
        background: $blueMid;
      }
    }

    .v-tab {
      background-color: $monoOffWhite;
      flex: 1;
      width: 75px !important;
      min-width: 75px !important;

      &:hover {
        transition: background 0.2s linear;
        background: $monoExtraLight;
      }
    }

    .v-tab:hover::before {
      border-bottom: none !important;
    }
  }
}

.helper-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
}
@media only screen and (min-width: 1024px){
 .helper-icon.tooltip {
    position: absolute;
    width: 400px;
    max-width: unset;
  }
}
.parent {
  position: relative;
}
.helper-icon .tooltip {
  // display: none;
  // display: block;
  justify-content: left;
  background: $monoWhite;
  border-radius: 4px;
  color: #404d50;
  margin: 0 auto;
  font-size: 15px;
  padding: 10px;
  height: unset;
  width: 315px;
  max-width: -moz-max-content;
  max-width: max-content;
  position: absolute;
  right: unset;
  /* left: 0px; */
  right: 0;
  // top: 20px;
  bottom: unset;
  z-index: 9999;
  position: fixed;
  width: 100%;
  margin-top: 20px;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  word-break: break-word;
}
.page-title {
  padding-top: 40px;
}
@include desktop {
  .helper-icon .tooltip {
    position: absolute;
    width: 400px;
    max-width: unset;
  }
  .page-title {
    padding-top: 0;
  }
}
@media (max-width: 787px) {
  .dashboard-home {
    margin: 0 !important;
  }
}
.dashboard-home {
  margin: 0 24px;
  padding-bottom: 40px;
}
.tabs-header {
  background-color: $monoWhite;
  padding: 16px 20px 0px 20px;
  border-radius: 4px;
  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03px;
    color: $monoBlack;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
</style>
<style lang="scss">
.tabs-home-dashboard {
  &.tabs-header {
    .v-slide-group__wrapper {
      align-items: end !important;
    }
  }
}
@include desktop {
  .mt-header-title{
    margin-top: 44px !important;
  }
  .tabs-home-dashboard {
    &.tabs-header {
      .v-slide-group__wrapper {
        .v-tabs-slider-wrapper {
          width: 200px !important;
        }
        .v-tab {
          width: 200px !important;
        }
      }
    }
  }
}
.tabs-content {
  .theme--light.v-tabs-items {
    background-color: transparent;
  }
}
.tabs-home-dashboard {
  &.tabs-header {
    .v-slide-group__content {
      height: 40px ;
      padding-top: 2px;
    }
    .v-tab {
      height: 38px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .tabs-home-dashboard {
    .v-tab:before {
      background-color: transparent;
    }
  }
}
</style>
