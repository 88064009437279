<template>
  <div class="pie-chart">
    <PageTitle :label="labelSection" iconPath="pie-chart.svg" :isPcafSetting="true" />
    <TypeFilter :type="type" @update:type="handleUpdateType" :currency="currency" @update:currency="handleUpdateCurrencyType" />
    <TabFilter v-if="isInvestment" :tab="tab" @update:tab="handleUpdateTab" />
    <PeriodFilter
      v-if="!isInvestment && isLoading"
      @targetPeriod="getPeriod"
      :periodStartAt="periodStartAt"
      :periodEndAt="periodEndAt"
      :startMonth="startMonth"
      :type="type"
    />
    <div class="chart-data">
      <ChartData
        :key="key"
        :chartData="chartData"
        :tab="tab"
        :type="type"
        :selectedRange="selectedRange"
        :isDisabledNext="isDisabledNext"
        :isDisabledPrev="isDisabledPrev"
        :assetId="assetId"
        :currency="currency"
        @handleButton="onHandleButton"
        @openChartDetail="openChartDetail"
      />
      <ChartDetail :key="keyDetail" :selectedChartData="chartData" :tab="tab" :type="type" :assetId="assetId" :currency="currency" />
    </div>
    <ChartDetailPopup :dialog="dialogChartDetail" @close="dialogChartDetail = false">
      <ChartDetail :key="keyDetail" :selectedChartData="chartData" :tab="tab" :type="type" :isPopup="true" :assetId="assetId" :currency="currency" />
    </ChartDetailPopup>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import PageTitle from '@/components/products/common/page-title.vue';
import TypeFilter from '@/components/dashboard/home-pcaf/type-filter';
import TabFilter from '@/components/dashboard/home-pcaf/tab-filter';
import PeriodFilter from '@/components/dashboard/home-pcaf/period-filter';
import ChartData from '@/components/dashboard/home-pcaf/pie-chart/chart-data';
import ChartDetail from '@/components/dashboard/home-pcaf/pie-chart/chart-detail';
import ChartDetailPopup from '@/components/dashboard/home-pcaf/dialogs/DetailChartPopup.vue';
import { getPieChartApi } from '@/api/pcaf';
import moment from 'moment';
import { formatDataList, getPcafPayload, isTotalEmission } from '@/utils/pcaf';
export default {
  components: { PageTitle, TypeFilter, TabFilter, PeriodFilter, ChartData, ChartDetail, ChartDetailPopup },
  props: {
    branchIds: {
      type: Array,
      default: () => [],
    },
    assetId: {
      type: Number,
      default: 0,
    },
    startMonth: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      tab: 0,
      type: 2,
      key: 0,
      keyDetail: 1,
      chartData: {},
      selectedChartData: {},
      periodStartAt: null,
      periodEndAt: null,
      selectedRange: null,
      indexAtFirstBar: null,
      formatYearList: [],
      formatMonthYearList: [],
      formatHalfYearList: [],
      formatQuarterYearList: [],
      isDisabledNext: true,
      isDisabledPrev: true,
      isLoading: false,
      dialogChartDetail: false,
      currency: 1,
    };
  },
  mounted() {
    this.type = this.dashboard.pieChart.selectedType || 2;
    this.tab = this.dashboard.pieChart.selectedTab;
    this.currency = this.dashboard.pieChart.selectedCurrency || 1;
    this.isLoading = true;
    this.formatDurationList();
  },
  computed: {
    ...mapState('userData', ['contractor']),
    ...mapState('pcaf', ['dashboard']),
    watchData() {
      return this.type + this.tab + this.branchIds + this.assetId + this.isLoading + this.currency;
    },
    labelSection() {
      return this.$t("dashboard_main.title_detailed_information_by_asset_class");
    },
    isInvestment() {
      return [2].includes(this.type);
    }
  },
  watch: {
    watchData: {
      async handler() {
        if (!this.isLoading || !this.isInvestment) return;
        this.handleUpdateStoreData();
        this.getSelectedDefault();
        await this.getChartData();
      },
      immediate: true,
    },
    indexAtFirstBar: {
      handler(value) {
        this.isDisabledPrev = false;
        this.isDisabledNext = false;
        if (value === 0) {
          this.isDisabledPrev = true;
          this.isDisabledNext = false;
        }
        let maxLength = 0;
        if (this.tab === 0) {
          maxLength = this.formatYearList.length - 1;
        } else if (this.tab === 3) {
          maxLength = this.formatMonthYearList.length - 1;
        } else if (this.tab === 1) {
          maxLength = this.formatHalfYearList.length - 1;
        } else if (this.tab === 2) {
          maxLength = this.formatQuarterYearList.length - 1;
        }
        if (value === maxLength) {
          this.isDisabledPrev = false;
          this.isDisabledNext = true;
        }
        if (maxLength === 0) {
          this.isDisabledPrev = true;
          this.isDisabledNext = true;
        }
      },
    },
  },
  methods: {
    ...mapActions('pcaf', ['updatePieChart']),
    handleUpdateType(value) {
      this.type = value;
    },
    handleUpdateTab(value) {
      this.tab = value;
    },
    async getChartData() {
      const currentYear = new Date().getFullYear();
      const tmpTime = new Date().getMonth() + 1;
      const currentMonth = tmpTime.toString().padStart(2, '0');
      const currentDateTime = `${currentYear}-${currentMonth}`;
      let payload = {
        contractor_id: this.contractor,
        duration_type: [this.tab + 1],
        start_at: this.periodStartAt || `${currentYear}-${moment(this.startMonth, 'M').format('MM')}`,
        end_at: this.periodEndAt || currentDateTime,
      };
      if (this.branchIds.length > 0) {
        payload.branch_id = this.branchIds;
      }
      if (this.assetId !== 0) {
        payload.pcaf_select = [this.assetId];
      }
      const pcafPayload = getPcafPayload(this.type, this.currency);
      payload.data_type = pcafPayload[0];
      payload.pcaf_type = pcafPayload[1];
      await getPieChartApi(payload).then((res) => {
        this.chartData = res;
        this.key++;
        this.keyDetail++;
      });
    },
    getPeriod(start, end) {
      if (start !== null && end !== null) {
        this.periodStartAt = start;
        this.periodEndAt = end;
        if (!this.isLoading) return;
        this.getChartData();
        this.handleUpdateStoreData();
      }
    },
    formatDurationList() {
      this.formatYearList = formatDataList(moment, this.startMonth, 'year');
      this.formatHalfYearList = formatDataList(moment, this.startMonth, 'half-year');
      this.formatQuarterYearList = formatDataList(moment, this.startMonth, 'quarter-year');
      this.formatMonthYearList = formatDataList(moment, this.startMonth, 'month');
    },
    getSelectedDefault() {
      let index = -1;
      if (isTotalEmission(this.type)) {
        if (this.dashboard.pieChart.selectedRange) {
          const splitData = this.dashboard.pieChart.selectedRange.split('/');
          this.periodStartAt = splitData[0]?.trim() === 'null' ? null : splitData[0]?.trim();
          this.periodEndAt = splitData[1]?.trim() === 'null' ? null : splitData[1]?.trim();
        } else {
          this.periodStartAt = null;
          this.periodEndAt = null;
        }
        return;
      } else {
        if (this.tab === 0) {
        // year tab
        const oldSelectedYear = this.dashboard.pieChart.selectedYear;
        index = this.formatYearList.findIndex((item) => item.year === Number(oldSelectedYear));
        if (index < 0) {
          index = this.formatYearList.length - 1;
        }
        if (index < 0) return;
        this.periodStartAt = this.formatYearList[index]?.start_at;
        this.periodEndAt = this.formatYearList[index]?.end_at;
        this.selectedRange = this.formatYearList[index]?.selected_range;
        this.indexAtFirstBar = index;
        } else if (this.tab === 1) {
          // half year tab
          const oldSelectedHalfYear = this.dashboard.pieChart.selectedHalfYear;
          const order = Number(oldSelectedHalfYear?.split('-')[0]);
          const year = Number(oldSelectedHalfYear?.split('-')[1]);
          if (order && year) {
            index = this.formatHalfYearList.findIndex((item) => {
              return item.order === order && item.year === year;
            });
          } else {
            index = this.formatHalfYearList.length - 1;
          }
          if (index < 0) return;
          this.periodStartAt = this.formatHalfYearList[index]?.start_at;
          this.periodEndAt = this.formatHalfYearList[index]?.end_at;
          this.selectedRange = this.formatHalfYearList[index]?.selected_range;
          this.indexAtFirstBar = index;
        } else if (this.tab === 2) {
          // quarter year tab
          const oldSelectedQuarterYear = this.dashboard.pieChart.selectedQuarterYear;
          const order = Number(oldSelectedQuarterYear?.split('-')[0]);
          const year = Number(oldSelectedQuarterYear?.split('-')[1]);
          if (order && year) {
            index = this.formatQuarterYearList.findIndex((item) => {
              return item.order === order && item.year === year;
            });
          } else {
            index = this.formatQuarterYearList.length - 1;
          }
          if (index < 0) return;
          this.periodStartAt = this.formatQuarterYearList[index]?.start_at;
          this.periodEndAt = this.formatQuarterYearList[index]?.end_at;
          this.selectedRange = this.formatQuarterYearList[index]?.selected_range;
          this.indexAtFirstBar = index;
        } else if (this.tab === 3) {
          // month year tab
          const oldSelectedMonthYear = this.dashboard.pieChart.selectedMonthYear;
          if (oldSelectedMonthYear) {
            index = this.formatMonthYearList.findIndex(item => item.start_at === moment(oldSelectedMonthYear?.replace('/', '-'), 'YYYY-MM').format('YYYY-MM'));
          } else {
            index = this.formatMonthYearList.length - 1;
          }
          if (index < 0) return;
          this.periodStartAt = this.formatMonthYearList[index]?.start_at;
          this.periodEndAt = this.formatMonthYearList[index]?.end_at;
          this.selectedRange = this.formatMonthYearList[index]?.selected_range;
          this.indexAtFirstBar = index;
        }
      }
    },
    onHandleButton(value) {
      if (value === 'next') {
        let maxLength = 0;
        if (this.tab === 0) {
          maxLength = this.formatYearList.length - 1;
        } else if (this.tab === 1) {
          maxLength = this.formatHalfYearList.length - 1;
        } else if (this.tab === 2) {
          maxLength = this.formatQuarterYearList.length - 1;
        } else if (this.tab === 3) {
          maxLength = this.formatMonthYearList.length - 1;
        }

        if (this.indexAtFirstBar === maxLength) return;
        this.indexAtFirstBar += 1;
        if (this.tab === 0) {
          this.periodStartAt = this.formatYearList[this.indexAtFirstBar]?.start_at;
          this.periodEndAt = this.formatYearList[this.indexAtFirstBar]?.end_at;
          this.selectedRange = this.formatYearList[this.indexAtFirstBar]?.selected_range;
        } else if (this.tab === 1) {
          this.periodStartAt = this.formatHalfYearList[this.indexAtFirstBar]?.start_at;
          this.periodEndAt = this.formatHalfYearList[this.indexAtFirstBar]?.end_at;
          this.selectedRange = this.formatHalfYearList[this.indexAtFirstBar]?.selected_range;
        } else if (this.tab === 2) {
          this.periodStartAt = this.formatQuarterYearList[this.indexAtFirstBar]?.start_at;
          this.periodEndAt = this.formatQuarterYearList[this.indexAtFirstBar]?.end_at;
          this.selectedRange = this.formatQuarterYearList[this.indexAtFirstBar]?.selected_range;
        } else if (this.tab === 3) {
          this.periodStartAt = this.formatMonthYearList[this.indexAtFirstBar]?.start_at;
          this.periodEndAt = this.formatMonthYearList[this.indexAtFirstBar]?.end_at;
          this.selectedRange = this.formatMonthYearList[this.indexAtFirstBar]?.selected_range;
        }
      } else {
        if (this.indexAtFirstBar === 0) return;
        this.indexAtFirstBar -= 1;

        if (this.tab === 0) {
          this.periodStartAt = this.formatYearList[this.indexAtFirstBar]?.start_at;
          this.periodEndAt = this.formatYearList[this.indexAtFirstBar]?.end_at;
          this.selectedRange = this.formatYearList[this.indexAtFirstBar]?.selected_range;
        } else if (this.tab === 1) {
          this.periodStartAt = this.formatHalfYearList[this.indexAtFirstBar]?.start_at;
          this.periodEndAt = this.formatHalfYearList[this.indexAtFirstBar]?.end_at;
          this.selectedRange = this.formatHalfYearList[this.indexAtFirstBar]?.selected_range;
        } else if (this.tab === 2) {
          this.periodStartAt = this.formatQuarterYearList[this.indexAtFirstBar]?.start_at;
          this.periodEndAt = this.formatQuarterYearList[this.indexAtFirstBar]?.end_at;
          this.selectedRange = this.formatQuarterYearList[this.indexAtFirstBar]?.selected_range;
        } else if (this.tab === 3) {
          this.periodStartAt = this.formatMonthYearList[this.indexAtFirstBar]?.start_at;
          this.periodEndAt = this.formatMonthYearList[this.indexAtFirstBar]?.end_at;
          this.selectedRange = this.formatMonthYearList[this.indexAtFirstBar]?.selected_range;
        }
      }
      this.handleUpdateStoreData();
      this.getChartData();
    },
    handleUpdateStoreData() {
      let storeData = {};
      if (isTotalEmission(this.type)) {
        storeData = {
          selectedRange: `${this.periodStartAt}/${this.periodEndAt}`,
          selectedYear: null,
          selectedHalfYear: null,
          selectedQuarterYear: null,
          selectedMonthYear: null,
        };
      } else {
        storeData = {
          ...this.dashboard.pieChart,
          selectedCurrency: this.currency,
        };
        if (this.tab === 0) {
          storeData = {
            ...storeData,
            selectedYear: this.selectedRange,
            selectedHalfYear: null,
            selectedQuarterYear: null,
            selectedMonthYear: null,
          };
        } else if (this.tab === 1) {
          storeData = {
            ...storeData,
            selectedYear: null,
            selectedHalfYear: this.selectedRange,
            selectedQuarterYear: null,
            selectedMonthYear: null,
          };
        } else if (this.tab === 2) {
          storeData = {
            ...storeData,
            selectedYear: null,
            selectedHalfYear: null,
            selectedQuarterYear: this.selectedRange,
            selectedMonthYear: null,
          };
        } else if (this.tab === 3) {
          storeData = {
            ...storeData,
            selectedYear: null,
            selectedHalfYear: null,
            selectedQuarterYear: null,
            selectedMonthYear: this.selectedRange,
          };
        }
      }
      this.updatePieChart({
        ...storeData,
        selectedType: this.type,
        selectedTab: this.tab,
      });
    },
    openChartDetail() {
      this.dialogChartDetail = true;
    },
    handleUpdateCurrencyType(value) {
      this.currency = value;
    }
  },
};
</script>
<style lang="scss" scoped>
.pie-chart {
  padding: 16px;
  background-color: $monoWhite;
  border-radius: 8px;
  margin-top: 40px;

  .product-title {
    margin-top: 0;
  }
}

.chart-data {
  display: flex;
  flex-flow: row;
  margin-top: 16px;
}

@media only screen and (max-width: $desktop) {
  .pie-chart {
    padding: 8px;
  }
}
</style>
