<template>
  <div class="mr-md-40-px">
    <div class="dashboard-home">
      <div class="emission-by-period">
        <emission-by-period :branchIds="branchIds" :isGetAllFilter="isGetAllFilter" :isLoading="isLoading" :startMonth="startMonth" />
      </div>
      <div class="emission-by-category">
        <emission-by-category :branchIds="branchIds" :isGetAllFilter="isGetAllFilter" :isLoading="isLoading" :startMonth="startMonth" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.emission-by-period {
  padding: 8px;
  background-color: $monoWhite;
  border-radius: 8px;

  ::v-deep {
    .period-header {
      margin-top: 0;
    }
  }
}

.emission-by-category {
  padding: 8px;
  background-color: $monoWhite;
  border-radius: 8px;
  margin-top: 40px;

  ::v-deep {
    .category-header {
      margin-top: 0;
    }
  }
}

@media (max-width: 575px) {
  .emission-by-category {
    margin-top: 48px;
  }
}

.mt-header-title {
  margin-top: 76px;
}

.mb-20-px {
  margin-bottom: 20px;
}

.mr-md-40-px {
  margin-right: 0;
}

.emission-category {
  display: flex;
  justify-content: center;
}

@include desktop {
  .emission-by-category {
    padding: 16px; 
  }
  .mt-header-title {
    margin-top: 40px;
  }
  .emission-by-period {
    padding: 16px;
  }
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex';
import EmissionByPeriod from './components/emissions-by-period/index.vue';
import EmissionByCategory from './components/emissions-by-category/index.vue';
import { getStartMonth } from '@/api/dashboard';

export default {
  props: {
    branchIds: {
      type: Array,
      default: () => [],
    },
    isGetAllFilter: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    EmissionByPeriod,
    EmissionByCategory,
  },
  data() {
    return {
      items: [
        {
          text: this.$t('dashboard_main.label_home'),
          disabled: true,
          href: '/',
        },
      ],
      keyRender: 0,
      startMonth: 3,
    };
  },
  async mounted() {
    this.updateBreadCrumb(this.items);
  },
  created() {
    getStartMonth().then((res) => {
      this.startMonth = res.data.start_month;
    }).catch((err) => {
      console.warn(err)
    });
  },
  computed: {
    ...mapGetters({
      contractor: 'userData/getContractor',
    }),
    isShowEmissionCategoryMobile() {
      return this.windowWidth < 1024;
    },
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
  },
};
</script>
