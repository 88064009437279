<template>
  <div class="pcaf-tab-filter" :class="classFilterTab">
    <v-tabs class="tab-filter" v-model="selectedTab" color="#404D50" background-color="transparent">
      <v-tab v-for="(item, index) in dataType" :key="index">
        {{ item }}
      </v-tab>
    </v-tabs>
  </div>
</template>
<script>
export default {
  props: {
    tab: {
      type: Number,
      default: 0
    },
    isTrendLineChart: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    selectedTab: {
      get() {
        return this.tab;
      },
      set(value) {
        this.$emit('update:tab', value);
      },
    },
    dataType() {
      return this.isTrendLineChart ? [
        this.$t("dashboard_main.label_by_class"),
        this.$t("dashboard_main.label_pile_up"),
      ] : [
        this.$t("dashboard_main.tab_by_year"), 
        this.$t("dashboard_main.tab_semi_annual"), 
        this.$t("dashboard_main.tab_quarterly"), 
        this.$t("dashboard_main.tab_by_month")
      ];
    },
    classFilterTab() {
      return this.isTrendLineChart ? 'tabs-trend-line-chart' : 'tabs-default-chart'
    }
  },
}
</script>
<style lang="scss" scoped>
.pcaf-tab-filter {
  margin-bottom: 16px;
  .tab-filter {
    ::v-deep .v-tabs-bar {
      height: 38px;
    }
  }
}
</style>