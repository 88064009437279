<template>
  <div class="quality-score-chart">
    <PageTitle :label="labelSection" iconPath="column-chart.svg" :isPcafSetting="true" />
    <!-- <TypeFilter :type="type" :currency="currency" @update:currency="handleUpdateCurrencyType" chart="quality-scopre" /> -->
    <TabFilter :tab="tab" @update:tab="handleUpdateTab" />
    <div class="quality-score-chart-container">
      <ChartData :tab="tab" :chartData="chartData" @updateSelectedChartData="updateSelectedChartData" />
      <ChartDetail :tab="tab" :selectedChartData="selectedChartData" />
    </div>
    <ChartDetailPopup :dialog="dialogChartDetail" @close="dialogChartDetail = false">
      <ChartDetail  :tab="tab" :selectedChartData="selectedChartData" :isPopup="true" />
    </ChartDetailPopup>
  </div>
</template>
<script>
import PageTitle from '@/components/products/common/page-title.vue';
import TypeFilter from '@/components/dashboard/home-pcaf/type-filter';
import TabFilter from '@/components/dashboard/home-pcaf/tab-filter';
import ChartData from '@/components/dashboard/home-pcaf/quality-score-line-chart/chart-data';
import ChartDetail from '@/components/dashboard/home-pcaf/quality-score-line-chart/chart-detail.vue';
import { getChangeScoreChartApi } from '@/api/pcaf';
import ChartDetailPopup from '@/components/dashboard/home-pcaf/dialogs/DetailChartPopup.vue';
import { mapActions, mapState } from 'vuex';
import { HALF_YEAR, QUARTER_YEAR } from '@/constants/pcaf';
import i18n from '@/lang/i18n.js';

export default {
  components: { PageTitle, TypeFilter, TabFilter, ChartData, ChartDetail, ChartDetailPopup },
  props: {
    branchIds: {
      type: Array,
      default: () => [],
    },
    assetId: {
      type: Number,
      default: 0,
    },
    startMonth: {
      type: Number,
      default: 4,
    }
  },
  data() {
    return {
      tab: 0,
      type: 0,
      chartData: [],
      selectedChartData: {
        duration_id: 1,
        start_at: '2022/01',
        end_at: '2022/12',
        year: '2017',

        total_emission: 0.26,
        ratio_emission: 11.2,
        up_emission: 1,

        total_amount: '32',
        ratio_amount: null,
        up_amount: 0,
      },
      timeFilters: [
        { text: 'year', value: 0 },
        { text: 'half_year', value: 1 },
        { text: 'quarter_year', value: 2 },
        { text: 'month_year', value: 3 },
      ],
      dialogChartDetail: false,
      type: 2,
      currency: 1,
    };
  },
  computed: {
    ...mapState('pcaf', ['dashboard']),
    propsChange() {
      //listener for props change
      return this.tab + this.branchIds + this.assetId + this.currency;
    },
    labelSection() {
      return this.$t("dashboard_main.title_changes_in_data_quality_score");
    }
  },
  watch: {
    propsChange: {
      handler() {
        this.getChartData();
        this.handleUpdateStoreData();
      },
    },
  },
  created() {
    this.getChartData();
    this.tab = this.dashboard.qualityScoreLineChart.selectedTab || 0;
    this.currency = this.dashboard.qualityScoreLineChart.selectedCurrency || 1;
  },
  methods: {
    ...mapActions('pcaf', ['updateQualityScoreLineChart']),
    handleUpdateTab(value) {
      this.tab = value;
    },
    getChartData() {
      let params = {
        contractor_id: this.$store.state.userData.contractor,
        branch_id: this.branchIds,
        duration_type: [this.tab + 1],
        pcaf_type: this.currency
      };
      if (this.assetId !== 0) {
        params.pcaf_select = [this.assetId];
      }
      getChangeScoreChartApi(params).then((res) => {
        const findItemFilter = this.timeFilters.find((time) => time.value === this.tab);
        this.chartData = res.data[findItemFilter.text];

        this.chartData = this.chartData?.map((item) => {
          let year = item.year;
          let yearTime = item.year;
          switch (this.tab) {
            case 0:
              year = item.year;
              break;
            case 1:
              if(i18n.locale === 'vi') {
                year = `${HALF_YEAR.find(text => text.id === item.order)?.value} ${item.year_order}`;
              } else {
                year = `${item.year_order} ${HALF_YEAR.find(text => text.id === item.order)?.value}`;
              }
              break;
            case 2:
              year = `${item.year_order} ${QUARTER_YEAR.find(text => text.id === item.order)?.value}`;
              break;
            default:
              year = `${item.year}/${item.month}`;
              break;
          }
          return {
            ...item,
            yearTime: yearTime,
            year: year.toString(),
            total_dq_ratio: Number(item.total_dq_ratio)
          };
        });
      });
    },
    updateSelectedChartData(chartData, isMobile, isClicked = false) {
      this.selectedChartData = chartData;
      if (isMobile && isClicked) {
        this.dialogChartDetail = true;
      }
    },
    handleUpdateCurrencyType(value) {
      this.currency = value;
    },
    handleUpdateStoreData() {
      this.updateQualityScoreLineChart({
        ...this.dashboard.qualityScoreLineChart,
        selectedCurrency: this.currency,
        selectedTab: this.tab,
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.quality-score-chart {
  padding: 16px;
  background-color: $monoWhite;
  border-radius: 8px;
  margin-top: 40px;

  .product-title {
    margin-top: 0;
  }

  .tabs-default-chart {
    margin-top: 16px;
  }
}

.quality-score-chart-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
}

@media only screen and (max-width: $desktop) {
  .quality-score-chart {
    padding: 8px;
  }
}
</style>