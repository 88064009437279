<template>
  <div class="container-breakdown">
    <div class="header">{{ $t('dashboard_main.label_breakdown') }}</div>
    <div class="content">
      <div class="content-block" v-for="(item, index) in data" :key="index">
        <div class="title-block">{{ item.company_name }}</div>
        <div class="descipt-block">
          {{ getDetailType(item) }}
          <span class="unit" style="display: inline-block">{{ getUnit }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatBigNumber, math, formatValue } from '@/concerns/newRegisterData/wijmo.helper';
import { getUnit as getUnitText } from '@/utils/getTextWithCondition';
import { addThoundSandCommaWithTwoDigit } from '@/utils/convertNumber';
import { isTotalEmission } from '@/utils/pcaf';
import { formatValueUnit } from '@/concerns/chart';
import { mapState } from 'vuex';

export default {
  props: {
    dataChartDetail: {
      type: Object,
      default: () => {},
    },
    type: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    currency: {
      type: Number,
      default: 1
    }
  },
  mounted() {
    this.getDataDetailChart();
  },
  computed: {
    ...mapState('settingsDashboard', ['itemsSettings']),
    getUnitState() {
      return this.itemsSettings[this.$store.state.userData.contractor]?.unitName || 't-CO2';
    },
    getUnit() {
      return getUnitText(this.type, this.currency, this.getUnitState);
    },
    isTypeCanChangeUnit() {
      return this.type !== 2;
    },
    isTco2Unit() {
      return this.getUnitState === 't-CO2';
    },
  },
  data() {
    return {
      data: [],
    };
  },
  methods: {
    getDataDetailChart() {
      if (this.dataChartDetail.detail && this.dataChartDetail.detail.length > 0) {
        this.data = {...this.dataChartDetail.detail};
      }
    },
    getDetailType(item) {
      let totalValue = 0;
      if (isTotalEmission(this.type)) {
        totalValue = item?.total_emission;
      } else {
        switch (this.currency) {
          case 1:
            totalValue = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(item?.total_amount)) / 1000000}`), 50);
            break;
          case 2:
          case 3:
            totalValue = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(item?.total_amount)) / 1000}`), 50);
            break;
          default:
            break;
        }
      }
      const formattedTotalValue = this.isTypeCanChangeUnit ? formatValueUnit(totalValue, this.isTco2Unit) : totalValue;
      return totalValue == 0 ? '0.00' : addThoundSandCommaWithTwoDigit(formattedTotalValue, '', false, true);
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.container-breakdown {
  background-color: $monoOffWhite;
  &.chart-popup {
    background-color: #ffffff;
  }
  .header {
    padding: 8px 16px;
    border-bottom: 1px solid $monoDusty;
    // font-family: Source Han Sans JP;
    font-size: 11px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: $monoDark;
  }
  .content-block {
    padding: 8px 16px;
    .title-block {
      // font-family: Source Han Sans JP;
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.05em;
      color: $monoDark;
      word-break: break-word;
    }
  }
  .descipt-block {
    font-family: Century Gothic Pro;
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.05em;
    color: $monoBlack;
    word-break: break-all;
    .unit {
      font-family: "Source Han Sans";
    }
  }
  .unit {
    // font-family: Source Han Sans JP;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: $monoBlack;
  }
}
</style>
