<template>
  <div>
    <div class="period-wrapper">
      <div class="period-header">
        <div class="period-title">
          <img src="img/icons/time.svg" alt="time" />
          <h2 class="period__title">{{ $t('dashboard_main.title_ghg_emissions_by_period') }}</h2>
        </div>
      </div>

      <div class="tabs-header-period">
        <v-tabs v-model="selectedTab" color="#404D50" background-color="transparent">
          <v-tab v-for="(tab, index) in tabs" :key="index">{{ tab }}</v-tab>
        </v-tabs>
      </div>

      <div class="tabs-content">
        <chart-column-period
          :tab="selectedTab"
          :chartData="chartData"
          :branchIds="branchIds"
          :isEnabledScope3="isEnabledScope3"
          :isGetAllFilter="isGetAllFilter"
          :startMonth="startMonth"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.period-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tabs-header-period {
  height: 38px;
  display: flex;
  align-items: center;
  ::v-deep {
    .v-slide-group__wrapper {
      align-items: center !important;
    }
    .v-tabs-bar {
      height: 38px!important;
    }
  }
}

.tabs-content {
  .theme--light.v-tabs-items {
    background-color: transparent;
  }
}
</style>

<script>
import { getEmissionsByPeriod } from '@/api/dashboard';
import { mapActions, mapGetters, mapState } from 'vuex';
import ChartColumnPeriod from '@/views/dashboard/components/emissions-by-period/column-chart/index.vue';
import { HALF_YEAR, QUARTER_YEAR } from '@/constants/pcaf';
import i18n from '@/lang/i18n.js';
import { DASHBOARD_DISPLAY_UNIT } from '@/constants/dashboard';

export default {
  props: {
    branchIds: {
      type: Array,
      default: () => [],
    },
    isGetAllFilter: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    startMonth: {
      type: Number,
      default: 3,
    },
  },
  components: { ChartColumnPeriod },
  data() {
    return {
      selectedTab: 0,
      windowWidth: window.innerWidth,
      chartData: [],
      chartDataLocationMarket: {},
      isEnabledScope3: false,
    };
  },
  created() {
    this.selectedTab = this.$store.state.dashboard.selectedEmissionsByPeriodDashboard.selectedTab;
  },
  computed: {
    ...mapGetters({
      contractor: 'userData/getContractor',
    }),
    ...mapState('settingsDashboard', ['itemsSettings']),
    watchData() {
      return this.selectedTab + this.branchIds + this.isLoading;
    },
    tabs() {
      return [
        this.$t('dashboard_main.tab_by_year'),
        this.$t('dashboard_main.tab_semi_annual'),
        this.$t('dashboard_main.tab_quarterly'),
        this.$t('dashboard_main.tab_by_month'),
      ];
    },
    selectedElectricity() {
      return this.itemsSettings[this.contractor]?.selectedElectricity || 1;
    },
    selectedElectricLabel() {
      return this.selectedElectricity === DASHBOARD_DISPLAY_UNIT.market ? 'market' : 'location';
    },
  },
  watch: {
    selectedTab: {
      handler(newVal) {
        this.updateSelectedEmissionsByPeriodDashboard({
          ...this.$store.state.dashboard.selectedEmissionsByPeriodDashboard,
          selectedTab: newVal,
        });
      },
      deep: true,
    },
    watchData: {
      handler() {
        if (this.isLoading) return;
        this.getChartData();
      },
    },
    selectedElectricity(valueSelected) {
      if (valueSelected === DASHBOARD_DISPLAY_UNIT.market) {
        this.chartData = this.getChartDataFollowMarketOrLocation(this.chartDataLocationMarket.market);
      } else {
        this.chartData = this.getChartDataFollowMarketOrLocation(this.chartDataLocationMarket.location);
      }
    },
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  methods: {
    ...mapActions('dashboard', ['updateSelectedEmissionsByPeriodDashboard', 'setIsEnableScope3']),
    async getChartData() {
      const params = {
        contractor_id: this.$store.state.userData.contractor,
        branch_id: this.branchIds,
        type: this.selectedTab === 1 || this.selectedTab === 2 ? this.selectedTab + 1 : this.selectedTab === 3 ? 1 : 0,
      };
      await getEmissionsByPeriod(params).then((result) => {
        this.isEnabledScope3 = result?.display_scope_3;
        this.setIsEnableScope3(this.isEnabledScope3);

        this.chartDataLocationMarket = result.data;
        this.chartData = this.getChartDataFollowMarketOrLocation(result.data[this.selectedElectricLabel]);
      });
    },
    getChartDataFollowMarketOrLocation(data) {
      if (!data) return;
      let orderSortedName = '';
      if (this.selectedTab === 0 || this.selectedTab === 3) {
        //selected year and month
        orderSortedName = 'year';
      } else if (this.selectedTab === 1 || this.selectedTab === 2) {
        orderSortedName = 'year_order';
      }

      return data
        .slice()
        .sort((a, b) => {
          if (a[orderSortedName] !== b[orderSortedName]) return a[orderSortedName] - b[orderSortedName];
          else return a.order - b.order;
        })
        .map((item) => {
          let plusObject = {};
          switch (this.selectedTab) {
            case 0:
              plusObject = {
                time_range: item[orderSortedName],
                year: item[orderSortedName],
              };
              break;
            case 1:
              plusObject = {
                time_range:
                  i18n.locale === 'vi'
                    ? `${HALF_YEAR.find((text) => text.id === item.order)?.value} ${item[orderSortedName]}`
                    : `${item[orderSortedName]} ${HALF_YEAR.find((text) => text.id === item.order)?.value}`,
                year: item[orderSortedName],
              };
              break;
            case 2:
              plusObject = {
                time_range: `${item[orderSortedName]} ${QUARTER_YEAR.find((text) => text.id === item.order)?.value}`,
                year: item[orderSortedName],
              };
              break;
            case 3:
              plusObject = {
                time_range: item[orderSortedName] + '/' + item.month,
                month: item.month,
                year: item[orderSortedName],
              };
              break;
            default:
              plusObject = {
                time_range: item[orderSortedName],
                year: item[orderSortedName],
              };
              break;
          }
          return {
            emission_scope1: item.emission_scope1,
            emission_scope2: item.emission_scope2,
            emission_scope3: item.emission_scope3,
            emission_scope4: item.emission_scope4?.total_emission ? item.emission_scope4?.total_emission : 0,
            s4Original: item.emission_scope4,
            ...plusObject,
            original: {
              duration_id: item.duration_id,
              emission_scope1: item.emission_scope1,
              emission_scope2: item.emission_scope2,
              emission_scope3: item.emission_scope3,
              emission_scope4: item.emission_scope4?.total_emission ? item.emission_scope4?.total_emission : 0,
              total_emission: item.total_emission,
            },
            detail_ratio: item.detail_ratio,
            total_emission: item.total_emission,
            order: item.order,
          };
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.period-header {
  margin-top: 40px;

  .period-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;


    .period__title {
      font-family: 'Source Han Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.1em;
      color: $monoBlack;
      padding-left: 16.5px;
    }
  }
}

.tabs-header {
  margin-bottom: 48px;
}
@media only screen and (max-width: 1024px) {
  .tabs-header-period {
    ::v-deep .v-tabs-slider-wrapper {
      width: 25% !important;
    }
  }
}
</style>
