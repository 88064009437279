<template>
  <div>
    <div class="assets-type-list">
      <div class="assets-type-item" v-for="(item, index) in assetsTypes" :key="index">
        <span class="assets-point" :style="{ backgroundColor: item.color }"></span>
        <span class="assets-title">{{ item.name }}</span>
      </div>
    </div>
    <div v-if="tab === 0">
      <line-chart :assetId="assetId" :assetsHasRegister="assetsHasRegister" :typeText="typeText" :tab="tab" :type="type" :chartData="chartData" :startMonth="startMonth" :assetsNameTrend="assetsNameTrend"  @updateSelectedChartData="updateSelectedChartData" :yearsNotRegisterData="yearsNotRegisterData" :currency="currency" />
    </div>
    <div v-if="tab === 1" class="trend-chart-container">
      <column-chart :typeText="typeText" :type="type" :tab="tab" @updateSelectedChartData="updateSelectedChartData" :chartData="chartData" :startMonth="startMonth" :assetId="assetId" :currency="currency" />
      <ChartDetailColumn :typeText="typeText" :type="type" :selectedChartData="selectedChartData" :trendTotalAssets="getTrendTotalAssets" :currency="currency"/>
    </div>
    <ChartDetailPopup
      v-if="tab === 1"
      :dialog="dialogChartDetail"
      @close="dialogChartDetail = false"
    >
      <ChartDetailColumn :typeText="typeText" :type="type" :selectedChartData="selectedChartData" :trendTotalAssets="getTrendTotalAssets" :isPopup="true" :currency="currency"/>
    </ChartDetailPopup>
    <ChartDetailPopup
      v-if="tab === 0"
      :dialog="dialogChartDetail"
      @close="dialogChartDetail = false"
    >
      <ChartDetailLine :typeText="typeText" :type="type" :selectedChartData="selectedChartData" :trendTotalAssets="getTrendTotalAssets" :isPopup="true" :currency="currency"/>
    </ChartDetailPopup>
  </div>
</template>
<script>
import ColumnChart from '@/components/dashboard/home-pcaf/trend-line-chart/column-chart/index.vue';
import LineChart from '@/components/dashboard/home-pcaf/trend-line-chart/line-chart/index.vue';
import ChartDetailColumn from '@/components/dashboard/home-pcaf/trend-line-chart/column-chart/chart-detail.vue';
import ChartDetailLine from '@/components/dashboard/home-pcaf/trend-line-chart/line-chart/chart-detail.vue';
import { ASSETS_NAMES_COLORS } from '@/components/dashboard/home-pcaf/trend-line-chart/line-chart/define';
import ChartDetailPopup from '@/components/dashboard/home-pcaf/dialogs/DetailChartPopup.vue';
export default {
  components: { ColumnChart, LineChart, ChartDetailColumn, ChartDetailPopup, ChartDetailLine },
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    tab: {
      type: Number,
      default: 0,
    },
    type: {
      type: Number,
      default: 0,
    },
    typeText: {
      type: String,
      default: '',
    },
    assetsNameTrend: {
      type: Array,
      default: () => [],
    },
    yearsNotRegisterData: {
      type: Array,
      default: () => [],
    },
    startMonth: {
      type: Number,
      default: 4,
    },
    assetsHasRegister: {
      type: Array,
      default: () => [],
    },
    assetId: {
      type: Number,
      default: 0,
    },
    currency: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      tabChart: 0,
      assetsTypes: [],
      selectedChartData: {},
      dialogChartDetail: false,
    };
  },
  methods: {
    updateSelectedChartData(chartData, isMobile, isClicked = false) {
      this.selectedChartData = chartData;
      if (isMobile && isClicked) {
        this.dialogChartDetail = true;
      }
    }
  },
  computed: {
    getTrendTotalAssets() {
      return this.assetsNameTrend.map((nameAssets, index) => {
        return ({
          borderColor: this.assetId === 0 ? ASSETS_NAMES_COLORS[index]?.color : ASSETS_NAMES_COLORS.find(assets => assets.id === this.assetId)?.color,
          text: nameAssets.replace("・", "/"),
          value: this.selectedChartData[ASSETS_NAMES_COLORS[index]?.scopeName]
        })
      });
    },
  },
  mounted() {
  },
  watch: {
    assetsNameTrend: {
      handler() {
        //when filter by assetId => assetsNameTrend has only one value
        if(this.assetId !== 0) {
          this.assetsTypes = [{
            color: ASSETS_NAMES_COLORS.find(assets => assets.id === this.assetId)?.color,
            name: this.assetsNameTrend[0].replace('・', '/'),
          }]
          return;
        }
        this.assetsTypes = this.assetsNameTrend.map((nameAssets, index) => {
          return ({
              color: ASSETS_NAMES_COLORS[index]?.color,
              name: nameAssets.replace('・', '/'),
            })
        })
      },
      deep: true
    },
    chartData: {
      handler() {
      },
      deep: true
    },
  }
};
</script>
<style lang="scss" scoped>
.assets-type-list {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 16px 0;
  flex-wrap: wrap;
  .assets-type-item {
    margin-right: 8px;
    line-height: 14px;
  }
  .assets-point {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 8px;
  }
  .assets-title {
    font-size: 11px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-align: left;
    color: rgba(64, 77, 80, 1);
  }
}
.trend-chart-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
}
</style>
