<template>
  <div class="chart__wrapper chart-column" ref="chartPcaf1">
    <div class="chart">
      <!-- header -->
      <div class="chart__header">
        <div class="chart__header--left">
          <button
            type="button"
            class="chart-btn-action"
            :disabled="isDisabledPrev"
            @click="prev"
            @mouseover="handleMouseOverPrevBtn"
            @mouseleave="isMouseOverPrev = false"
            @mousedown="isBlurPrev = true"
            @mouseup="isBlurPrev = false"
            @touchstart="handleTouchStartPrevBtn"
            @touchend="handleTouchEndPrevBtn"
          >
            <img :src="prevIconUrl || prevIcon" alt="Prev" />
          </button>
        </div>

        <div class="chart__header--center">
          <div class="chart-legend">
            <div class="legend scope1">
              <div class="bar-color"></div>
              <div class="bar-name">{{labelText}}</div>
            </div>
          </div>
        </div>

        <div class="chart__header--right">
          <button
            type="button"
            class="chart-btn-action"
            :disabled="isDisabledNext"
            @click="next"
            @mouseover="handleMouseOverNextBtn"
            @mouseleave="isMouseOverNext = false"
            @mousedown="isBlurNext = true"
            @mouseup="isBlurNext = false"
            @touchstart="handleTouchStartNextBtn"
            @touchend="handleTouchEndNextBtn"
          >
            <img :src="nextIconUrl || nextIcon" alt="Next" />
          </button>
        </div>
      </div>

      <!-- body -->
      <div class="chart__body">
        <div class="chart__body--left" ref="yearLeft" :style="{'min-width': minWidth}">
          <div class="axisY">
            <div
              class="axis-item"
              v-for="(item, index) in axisYItemsSource"
              :key="index + '-axis-y'"
              :class="{ unit: index === axisYItemsCount }"
              :style="{bottom: `${item.percent}%`}"
            >
              {{ item.text }}
            </div>
          </div>
        </div>

        <div class="chart__body--center" ref="bodyCenter" @scroll="syncScroll('body')">
          <div class="chart-body-content">
          <div class="transition" :style="{ width: transitionWidth }"></div>
          <div
            class="chart-bar"
            @click="handleGetValueChart(item)"
            @mouseover="onMouseOver(item)"
            @mouseleave="onMouseLeave()"
            :class="{ selected: item.time_range === selectedTimeRange, hovered: item.time_range === hoverTimeRange }"
            v-for="(item, index) in chartValue"
            :key="index + '-bar-chart'"
          >
            <div class="active"></div>
            <div class="hover"></div>
            <div v-if="hasNegativeNumber" class="number-wrapper">
              <div
                v-if="!isNegativeTotalData(item)"
                class="positive-number"
                :style="{ height: `calc(100% - ${item.zero_value_percent})`, bottom: item.zero_value_percent }"
              >
                <div
                  class="bar bar--scope1 animation"
                  :style="{ height: item.emission_percent }"
                  @mouseover="toolTipOn(item)"
                  @mouseleave="toolTipOff"
                  @mousemove="updateTooltipPosition"
                ></div>
              </div>
              <div class="zero-crossline" :style="{ height: item.zero_value_percent }"></div>
              <div v-if="isNegativeTotalData(item)" class="negative-number" :style="{ height: item.zero_value_percent }">
                <div
                  class="bar bar--scope1 animation"
                  :style="{ height: item.emission_percent }"
                  @mouseover="toolTipOn(item)"
                  @mouseleave="toolTipOff"
                  @mousemove="updateTooltipPosition"
                ></div>
              </div>
            </div>
            <div v-else class="number-wrapper">
              <div 
                class="bar bar--scope1 animation" 
                :style="{ height: item.emission_percent }" 
                @mouseover="toolTipOn(item)"
                @mouseleave="toolTipOff"
                @mousemove="updateTooltipPosition"
              ></div>
            </div>
              <!-- tooltip -->
              <div
              id="tooltip-column"
              class="tooltip"
              :style="{ left: tooltipPosition.x + 'px', top: tooltipPosition.y + 'px' }"
              v-if="isToolTipOn(item)"
            >
              <div class="tooltip-container">
                <p class="tooltip-title">{{chartTitle}}</p>
                <div class="tooltip-content">
                  <div class="tooltip-content-left">
                    <span>{{getTypeText}}</span>
                    <span>{{ratioTitle}}</span>
                  </div>
                  <div class="tooltip-content-right">
                    <span
                      >{{
                        totalEmissionValue
                      }}
                      {{unit}}</span
                    >
                    <span>{{changeYearEveryValue}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="chart__body--right"></div>
      </div>
        </div>
      </div>
      <!-- footer -->
      <div class="chart__footer">
        <div class="chart__footer--left" :style="{minWidth: footerWidth+ 'px'}">
          <button
            type="button"
            class="chart-btn-action"
            :disabled="isDisabledPrev"
            @click="prev"
            @mouseover="handleMouseOverPrevBtn"
            @mouseleave="isMouseOverPrev = false"
            @mousedown="isBlurPrev = true"
            @mouseup="isBlurPrev = false"
            @touchstart="handleTouchStartPrevBtn"
            @touchend="handleTouchEndPrevBtn"
          >
            <img :src="prevIconUrl || prevIcon" alt="Prev" />
          </button>
        </div>

        <div class="chart__footer--center" ref="footerCenter" @scroll="syncScroll('footer')">
          <div class="axisX">
            <div
              class="axis-item"
              :class="{ active: item.value === selectedTimeRange }"
              v-for="(item, index) in axisXItemsSource"
              :key="index + '-axis-x'"
            >
              <span class="axis-item-year">{{ item.year }}</span>
            </div>
          </div>
        </div>

        <div class="chart__footer--right">
          <button
            type="button"
            class="chart-btn-action"
            :disabled="isDisabledNext"
            @click="next"
            @mouseover="handleMouseOverNextBtn"
            @mouseleave="isMouseOverNext = false"
            @mousedown="isBlurNext = true"
            @mouseup="isBlurNext = false"
            @touchstart="handleTouchStartNextBtn"
            @touchend="handleTouchEndNextBtn"
          >
            <img :src="nextIconUrl || nextIcon" alt="Next" />
          </button>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import { TABLET_WIDTH, MOBILE_WIDTH } from '@/constants/screen-size';
import { formatValue, $_helper_isNumberType,$_helper_isNegativeNumber, math, formatBigNumber } from '@/concerns/newRegisterData/wijmo.helper'
import { addThoundSandCommaWithTwoDigit } from '@/utils/convertNumber';
import { getTitle, chartTitleMonthYear } from '@/utils/getTextWithCondition';
import { HALF_YEAR, QUARTER_YEAR } from '@/constants/pcaf';
import { getWidthYAxisByTextContent} from '@/utils/calcTextWidth';
import { getUnitText, isTotalEmission, getLabelText } from '@/utils/pcaf';
import i18n from '@/lang/i18n.js';
import { prepareChartAxisY, getMaxChartItemsCount, formatValueUnit } from '@/concerns/chart';
import debounce from 'lodash/debounce';
import { mapState } from 'vuex';

const MAX_CHART_ITEMS = 6;
const AXIS_Y_ITEM_COUNT = 8;
export default {
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    type: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    typeText: {
      type: String,
      default: '',
    },
    currency: {
      type: Number,
      default: 1,
    }
  },
  data() {
    return {
      indexAtFirstBar  : 0,
      min              : 0,
      max              : 800,
      axisYItemsSource : [
        {
          value: 0,
          text : '0',
          percent: 0,
        },
        {
          value: 100,
          text : '100',
          percent: 12.5,
        },
        {
          value: 200,
          text : '200',
          percent: 25,
        },
        {
          value: 300,
          text : '300',
          percent: 37.5,
        },
        {
          value: 400,
          text : '400',
          percent: 50,
        },
        {
          value: 500,
          text : '500',
          percent: 62.5,
        },
        {
          value: 600,
          text : '600',
          percent: 75,
        },
        {
          value: 700,
          text : '700',
          percent: 87.5,
        },
        {
          value: 800,
          text : 't-CO2',
          percent: 100,
        },
      ],
      axisXItemsSource : [],
      chartValue       : [],
      selectedTimeRange: '',
      hoverTimeRange   : '',
      isDisabledPrev   : false,
      isDisabledNext   : false,
      isMouseOverNext  : false,
      isMouseOverPrev  : false,
      isBlurNext       : false,
      isBlurPrev       : false,
      isFirstRender    : true,
      transitionWidth  : '100%',
      footerWidth      : 0,
      minWidth: 0,
      originChartValue: [],
      tooltipPosition: { x: 0, y: 0 },
      rangeTooltipData: null,
      axisYItemsCount: 8,
      nextIconUrl: '',
      prevIconUrl: '',
      maxChartItem: 6
    };
  },
  async mounted() {
    this.handlePrepareDataMounted();

    if (this.indexAtFirstBar + this.maxChartItem >= this.originChartValue.length) {
      this.isDisabledNext = true;
    } else {
      this.isDisabledNext = false;
    }

    if (this.indexAtFirstBar <= 0) {
      this.isDisabledPrev = true;
    } else {
      this.isDisabledPrev = false;
    }

    this.footerWidth = this.$refs.yearLeft?.clientWidth;
    const debouncedHandleResize = debounce(() => {
      this.handleResize();
    }, 1000);
    window.addEventListener('resize', debouncedHandleResize);
    window.addEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapState('settingsDashboard', ['itemsSettings']),
    getUnit() {
      return this.itemsSettings[this.$store.state.userData.contractor]?.unitName || 't-CO2';
    },
    isToolTipOn() {
      return (item) => {
        return this.rangeTooltipData?.time_range === item.time_range;
      }
    },
    totalEmissionValue() {
      return this.formatNumberWithComma(this.rangeTooltipData?.total_emission) || this.formatNumberWithComma(this.rangeTooltipData?.total_amount)      
    },
    chartTitle() {
      return chartTitleMonthYear(this.tab, this.rangeTooltipData.year, this.rangeTooltipData.order, this.rangeTooltipData.month)
    },
    unit() {
      return getUnitText(this.type, this.currency, this.getUnit);
    },
    ratioTitle() {
      return getTitle(this.tab);
    },
    getTypeText() {
      return this.typeText;
    },
    changeYearEveryValue() {
      let ratioValue = this.rangeTooltipData?.ratio_amount || this.rangeTooltipData?.ratio_emission;
      ratioValue = ratioValue === null || ratioValue === undefined ? '-' : this.formatNumberWithComma(ratioValue)
      let unit = '';
      if (ratioValue !== '-') {
        unit = '%';
        if (this.rangeTooltipData?.up_amount === 1 || this.rangeTooltipData?.up_emission === 1) {
          unit += '↑';
        } else {
          unit += '↓';
        }
      }
      return ratioValue + unit;
    },
    countTotal() {
      return function (item) {
        return isTotalEmission(this.type) ? item?.total_emission : item?.total_amount;
      }
    },
    nextIcon() {
      if (this.isDisabledNext) {
        this.isBlurNext      = false;
        this.isMouseOverNext = false;
        return 'img/icons/next-disabled.svg';
      }

      if (this.isBlurNext) {
        return 'img/icons/next.svg';
      }

      if (this.isMouseOverNext) {
        return 'img/icons/next-hover.svg';
      }

      return 'img/icons/next.svg';
    },
    prevIcon() {
      if (this.isDisabledPrev) {
        this.isBlurPrev      = false;
        this.isMouseOverPrev = false;
        return 'img/icons/prev-disabled.svg';
      }

      if (this.isBlurPrev) {
        return 'img/icons/prev.svg';
      }

      if (this.isMouseOverPrev) {
        return 'img/icons/prev-hover.svg';
      }

      return 'img/icons/prev.svg';
    },
    hasNegativeNumber() {
      return this.chartValue.find(item => this.countTotal(item) < 0);
    },
    labelText() {
      return getLabelText(this.type);
    },
  },
  watch: {
    tab() {
      this.nextIconUrl = '';
      this.prevIconUrl = '';
    },
    itemsSettings() {
      this.handlePrepareDataMounted();
    }
  },
  methods : {
    handlePrepareDataMounted() {
      const isTco2Unit = this.getUnit === 't-CO2';
      this.originChartValue = [];
      this.chartData.forEach(item => {
        const value = isTotalEmission(this.type)
          ? formatValueUnit(item.total_emission, isTco2Unit) 
          : item.total_emission;

        let timeRange;
        
        switch (this.tab) {
          case 0:
            timeRange = item.year;
            break;
          case 1:
          case 2:
            timeRange = `${item.order}/${item.year}`;
            break;
          default:
            timeRange = `${item.year}/${item.month}`;
            break;
        }
        this.originChartValue.push({
          ...item,
          time_range: timeRange,
          total_emission: value,
        });
      });

      this.transformChartData();
      this.selectedFirstChartItem();
    },
    handleResize() {
      this.prepareChartDataValue();
      if (this.indexAtFirstBar + this.maxChartItem >= this.originChartValue.length) {
        this.isDisabledNext = true;
      } else {
        this.isDisabledNext = false;
      }

      if (this.indexAtFirstBar <= 0) {
        this.isDisabledPrev = true;
      } else {
        this.isDisabledPrev = false;
      }
    },
    syncScroll(origin) {
      if (origin === 'body') {
        this.$refs.footerCenter.scrollLeft = this.$refs.bodyCenter.scrollLeft;
      } else if (origin === 'footer') {
        this.$refs.bodyCenter.scrollLeft = this.$refs.footerCenter.scrollLeft;
      }
    },
    handleScroll() {
      if(this.rangeTooltipData) {
        this.toolTipOff();
      }
    },
    formatNumber(num) {
      return num === 0 ? num : Number(formatValue(num));
    },
    formatNumberWithComma(value) {
      if ($_helper_isNumberType(value)) {
        return addThoundSandCommaWithTwoDigit(value?.toString(), "", false, true)
      }
      return value;
    },
    updateTooltipPosition(event) {
      const element = document.getElementById('tooltip-column');
      const width = element?.clientWidth;
      const height = element?.clientHeight;

      this.tooltipPosition.x = event.clientX - width / 2;
      this.tooltipPosition.y = event.clientY - height - 10;
    },
    toolTipOn(item) {
      if(window.innerWidth < TABLET_WIDTH) {
        if(this.rangeTooltipData) {
          this.toolTipOff();
        }
      }
      else {
        this.rangeTooltipData = item;
      }
    },
    toolTipOff() {
      this.rangeTooltipData = '';
    },
    transformChartData() {
      if (this.originChartValue.length === 0) {
        if (this.type === 2) {
          this.axisYItemsSource[AXIS_Y_ITEM_COUNT].text = getUnitText(this.type, this.currency);
        }
        this.getWidthOfText();
        return
      }
      const summary = this.originChartValue?.map(
        (item) => {
          let negativeTotal = 0;
          let positiveTotal = 0;
          if ($_helper_isNumberType(this.countTotal(item))) {
            $_helper_isNegativeNumber(this.countTotal(item)) ?
              negativeTotal = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(negativeTotal))} + ${math.bignumber(formatValue(this.countTotal(item)))}`), 50)
              : positiveTotal = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(positiveTotal))} + ${math.bignumber(formatValue(this.countTotal(item)))}`), 50);
            return [negativeTotal, positiveTotal];
          }
          return this.countTotal(item);
        }
      );
      let index = -1;
      if (this.tab === 0) {
        // year tab
        const oldSelectedYear = this.$store.state.pcaf.dashboard?.columnChart?.selectedYear;
        index = this.originChartValue.findIndex((item) => {
          return item.year === oldSelectedYear;
        });
        if (index < 0) {
          index = this.originChartValue.length -1;
        }
      } else if (this.tab === 1) {
        // half year tab
        const oldSelected = this.$store.state.pcaf.dashboard?.columnChart?.selectedHalfYear;
        const order = oldSelected?.split('/')[0];
        const year = oldSelected?.split('/')[1];
        index = this.originChartValue.findIndex((item) => {
          return item.order === Number(order) && item.year === Number(year);
        })
        if (index < 0) {
          index = this.originChartValue.length -1;
        }
      } else if (this.tab === 2) {
        // half year tab
        const oldSelected = this.$store.state.pcaf.dashboard?.columnChart?.selectedQuarterYear;
        const order = oldSelected?.split('/')[0];
        const year = oldSelected?.split('/')[1];
        index = this.originChartValue.findIndex((item) => {
          return item.order === Number(order) && item.year === Number(year);
        })
        if (index < 0) {
          index = this.originChartValue.length -1;
        }
      } else if (this.tab === 3) {
        // month tab
        const oldSelectedMonthYear = this.$store.state.pcaf.dashboard?.columnChart?.selectedMonthYear;
        const month = oldSelectedMonthYear?.split('-')[0];
        const year = oldSelectedMonthYear?.split('-')[1];
        index = this.originChartValue.findIndex((item) => {
          return item.month === month && item.year.toString() === year;
        });
        if (index < 0) {
          index = this.originChartValue.length -1;
        }
      }
      const listIndex = this.originChartValue.length - 1;
      if (index >= 0) {
        if (index < this.maxChartItem) {
          this.indexAtFirstBar = index;
          if ((listIndex - index) < this.maxChartItem) {
            this.indexAtFirstBar = listIndex - this.maxChartItem + 1;
          }
        } else if (index >= listIndex - 5) {
          // index in last 3 elements
          this.indexAtFirstBar = index === listIndex - 5 ? listIndex - 7 : listIndex - 5;
        } else {
          this.indexAtFirstBar = index;
        }
      } else {
        this.indexAtFirstBar = listIndex - 5;
      }
      [this.axisYItemsSource, this.min, this.max] = prepareChartAxisY(summary, this.axisYItemsCount, this.unit);
      this.prepareChartDataValue();
    },
    getMaxChart() {
      const maximumColumnsToShow = MAX_CHART_ITEMS;
      if (window.innerWidth < MOBILE_WIDTH) {
        this.maxChartItem = maximumColumnsToShow;
        return;
      }
      const props = {
        maximumColumnsToShow,
        processedData: this.originChartValue,
        indexAtFirstBar: this.indexAtFirstBar,
        axisYItemsSource: this.axisYItemsSource,
        chartContainerRef: this.$refs.chartPcaf1,
        MOBILE_WIDTH,
        getSummary: this.getSummary,
        axisYItemsCount: AXIS_Y_ITEM_COUNT
      }
      this.maxChartItem = getMaxChartItemsCount(props);
    },
    selectedFirstChartItem() {
      if (!this.originChartValue.length) {
        return;
      }
      let index = -1;
      if (this.tab === 0) {
        // year tab
        const oldSelectedYear = this.$store.state.pcaf.dashboard?.columnChart?.selectedYear;
        index = this.originChartValue.findIndex((item) => {
          return item.year === oldSelectedYear;
        });
        if (index < 0) {
          index = this.originChartValue.length -1;
        }
      } else if (this.tab === 1) {
        // half year tab
        const oldSelected = this.$store.state.pcaf.dashboard?.columnChart?.selectedHalfYear;
        const order = oldSelected?.split('/')[0];
        const year = oldSelected?.split('/')[1];
        index = this.originChartValue.findIndex((item) => {
          return item.order === Number(order) && item.year === Number(year);
        })
        if (index < 0) {
          index = this.originChartValue.length -1;
        }
      } else if (this.tab === 2) {
        // half year tab
        const oldSelected = this.$store.state.pcaf.dashboard?.columnChart?.selectedQuarterYear;
        const order = oldSelected?.split('/')[0];
        const year = oldSelected?.split('/')[1];
        index = this.originChartValue.findIndex((item) => {
          return item.order === Number(order) && item.year === Number(year);
        })
        if (index < 0) {
          index = this.originChartValue.length -1;
        }
      } else if (this.tab === 3) {
        // month tab
        const oldSelectedMonthYear = this.$store.state.pcaf.dashboard?.columnChart?.selectedMonthYear;
        const month = oldSelectedMonthYear?.split('-')[0];
        const year = oldSelectedMonthYear?.split('-')[1];
        index = this.originChartValue.findIndex((item) => {
          return item.month === month && item.year.toString() === year;
        });
        if (index < 0) {
          index = this.originChartValue.length -1;
        }
      }
      
      if (index >= 0) {
        const item = this.originChartValue[index];
        this.selectedTimeRange = item.time_range;
        this.$emit('updateSelectedChartData', item, window.innerWidth < TABLET_WIDTH, false)
      } else {
        const latestDurationIndex = this.originChartValue.length - 1;
        const item                = this.originChartValue[latestDurationIndex];
        this.selectedTimeRange = item.time_range;
        this.$emit('updateSelectedChartData', item, window.innerWidth < TABLET_WIDTH, false)
      }

      if (this.indexAtFirstBar + this.maxChartItem >= this.originChartValue.length) {
        this.isDisabledNext = true;
      } else {
        this.isDisabledNext = false;
      }

      if (this.indexAtFirstBar <= 0) {
        this.isDisabledPrev = true;
      } else {
        this.isDisabledPrev = false;
      }
    },
    prepareChartDataValue() {
      if (!this.originChartValue.length) {
        return;
      }

      let index              = 0;
      let chartValueOriginal = [];
      this.getMaxChart();
      do {
        chartValueOriginal.push(this.originChartValue[index + this.indexAtFirstBar]);
        index++;
      } while (index < this.maxChartItem);

      chartValueOriginal = chartValueOriginal.filter((item) => !!item);

      this.axisXItemsSource = chartValueOriginal.map((item) => {
        let year = item.year;
        switch (this.tab) {
          case 0:
            year = item.year;
            break;
          case 1:
            year = i18n.locale === 'vi' ? `${HALF_YEAR.find(text => text.id === item.order)?.value} ${item.year_order}` : `${item.year_order} ${HALF_YEAR.find(text => text.id === item.order)?.value}`;
            break;
          case 2:
            year = `${item.year_order} ${QUARTER_YEAR.find(text => text.id === item.order)?.value}`;
            break;
          default:
            year = `${item.year}/${item.month}`
            break;
        }
        return {
          label   : item.time_range,
          value   : item.time_range,
          year: year,
        };
      });

      this.chartValue = chartValueOriginal.map((item) => {
        return {
          ...item,
          total_emission: '0',
          total_amount: '0',
        };
      });

      const timeout = this.isFirstRender ? 1000 : 0;

      setTimeout(() => {
        const summary = this.getSummary(chartValueOriginal);
        [this.axisYItemsSource, this.min, this.max] = prepareChartAxisY(summary, this.axisYItemsCount, this.unit);
        this.getWidthOfText();
        this.chartValue = chartValueOriginal.map((item) => {
          return {
            ...item,
            emission_percent: this.calcScopeEmission(this.countTotal(item)),
            zero_value_percent: (Math.abs(Number(this.min) / (this.max - this.min)) * 100) + '%',
          };
        });
      }, timeout);

      setTimeout(() => {
        this.transitionWidth = 0;

        this.footerWidth = this.$refs.yearLeft?.clientWidth;
      }, timeout + 500);

      if (this.isFirstRender) {
        setTimeout(() => {
          this.isFirstRender = false;
        }, timeout + 2000);
      }
    },
    getSummary(chartValueOriginal) {
      const summary = chartValueOriginal.map(
          (item) => {
            let negativeTotal = 0;
            let positiveTotal = 0;
            if ($_helper_isNumberType(this.countTotal(item))) {
              $_helper_isNegativeNumber(this.countTotal(item)) ?
                negativeTotal = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(negativeTotal))} + ${math.bignumber(formatValue(this.countTotal(item)))}`), 50)
                : positiveTotal = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(positiveTotal))} + ${math.bignumber(formatValue(this.countTotal(item)))}`), 50);
              return [negativeTotal, positiveTotal];
            }
            return this.countTotal(item);
          }
        );
      return summary;
    },
    onClickChartItem(item) {
      this.selectedTimeRange = item.time_range;

      this.$emit('onUpdateStateDetail', item);

      if (window.outerWidth < TABLET_WIDTH) {
        this.$emit('openDialogStateDetail', item);
        return;
      }
    },
    onMouseOver(item) {
      this.hoverTimeRange = item.time_range;
    },
    onMouseLeave() {
      this.hoverTimeRange = '';
    },
    handleMouseOverNextBtn() {
      this.isMouseOverNext = true;
      this.nextIconUrl = '';
    },
    handleTouchStartNextBtn() {
      this.prevIconUrl = '';
      this.nextIconUrl = 'img/icons/next-hover.svg';
    },
    handleTouchEndNextBtn() {
      setTimeout(() => {
        if (this.isDisabledNext) {
          this.nextIconUrl = 'img/icons/next-disabled.svg';
        } else {
          this.nextIconUrl = 'img/icons/next.svg';
        }
      }, 200);
    },
    next() {
      this.prevIconUrl = '';
      if (this.indexAtFirstBar + this.maxChartItem >= this.originChartValue.length) {
        this.isDisabledNext = true;
        return;
      }

      this.isDisabledNext  = false;
      this.indexAtFirstBar = this.indexAtFirstBar + 1;
      this.$store.dispatch('dashboard/changeIndexAtFirstBarByPeriodWithYearly', this.indexAtFirstBar);
      this.prepareChartDataValue();
      if (this.indexAtFirstBar + this.maxChartItem >= this.originChartValue.length) {
        this.isDisabledNext = true;
      }

      if (this.indexAtFirstBar > 0) {
        this.isDisabledPrev = false;
      } else {
        this.isDisabledPrev = true;
      }
    },
    handleMouseOverPrevBtn() {
      this.isMouseOverPrev = true;
      this.prevIconUrl = '';
    },
    handleTouchStartPrevBtn() {
      this.nextIconUrl = '';
      this.prevIconUrl = 'img/icons/prev-hover.svg';
    },
    handleTouchEndPrevBtn() {
      setTimeout(() => {
        if (this.isDisabledPrev) {
          this.prevIconUrl = 'img/icons/prev-disabled.svg';
        } else {
          this.prevIconUrl = 'img/icons/prev.svg';
        }
      }, 200);
    },
    prev() {
      this.nextIconUrl = '';
      if (this.indexAtFirstBar <= 0) {
        this.isDisabledPrev = true;
        return;
      }

      this.isDisabledPrev = false;

      this.indexAtFirstBar = this.indexAtFirstBar - 1;
      this.prepareChartDataValue();
      this.$store.dispatch('dashboard/changeIndexAtFirstBarByPeriodWithYearly', this.indexAtFirstBar);
      if (this.indexAtFirstBar <= 0) {
        this.isDisabledPrev = true;
      }

      if (this.indexAtFirstBar + this.maxChartItem < this.originChartValue.length) {
        this.isDisabledNext = false;
      }
    },
    isPositiveNumber(item) {
      return this.countTotal(item) >= 0;
    },
    calcScopeEmission(emissionsValue) {
      if (emissionsValue < 0) {
        return Math.abs((Number(emissionsValue) / (this.min))) * 100 + '%';
      } else {
        return Math.abs((Number(emissionsValue) / (this.max))) * 100 + '%';
      }
    },
    getWidthOfText() {
      const  text = this.axisYItemsSource[0]?.text.length > addThoundSandCommaWithTwoDigit(this.axisYItemsSource[7]?.value.toString().length, "", false, true).length  ? this.axisYItemsSource[0]?.text : this.axisYItemsSource[7]?.text?.toString();
      const width = getWidthYAxisByTextContent(text);
      this.minWidth = width < 60 ? '60px' : width + 2 + 'px';
    },
    handleGetValueChart(chartItem) {
      this.selectedTimeRange = chartItem.time_range;
      this.$emit('updateSelectedChartData', chartItem, window.innerWidth < TABLET_WIDTH)
    },
    prepareDataByType(item) {
      this.countTotal(item);
    },
    isNegativeTotalData(item) {
      if (isTotalEmission(this.type)) {
        return item.total_emission < 0;
      } else {
        return item.total_amount < 0;
      }
    }
  },
};

</script>
<style lang="scss" scoped>
@import "@/views/dashboard/components/emissions-by-period/yearly/chart-2/index.scss";
@media (max-width: $tablet) {
  .axis-item-scope {
    white-space: break-spaces;
    letter-spacing: 0.33px !important;
  }
  .chart-bar,
  .axis-item {
    min-width: 57px;
  }
  .chart__footer--center,
.chart__body--center {
  overflow-x: scroll !important;
  white-space: nowrap;
  overflow-y: hidden !important;
}

.chart__body--center::-webkit-scrollbar {
  display: none;
}
.chart__footer--center::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 6px solid rgba(236, 220, 220, 0);
  background-clip: padding-box;
  background-color: rgba(22, 24, 35, 0.06);
}
.chart__footer--center::-webkit-scrollbar {
  width: 18px;
}
}

.chart__wrapper {
  @include desktop {
    max-width: calc(100% - 197px);
  }

  .chart__body {
    .chart__body--right {
      display: block;
    }
    .chart__body--center,
    .chart__footer--center {
      overflow: initial;
    }
  }
}

.chart__wrapper {
  .chart__header {
    .chart__header--left {
      padding: 0 0 16px;
    }

    .chart__header--center {
      padding-bottom: 16px;
      .chart-legend {
        padding: 3px 0px;
      }
    }

    .chart__header--right {
      padding: 0 0 16px;
    }
  }
}
@media screen and (max-width: 1365px) {
  .chart__wrapper {
    .chart__footer {
      min-height: 72px;
      .chart__footer--left {
        * {
          display: none;
        }
      }
      .chart__footer--center {
        padding-right: 8px;
      }
      .chart__footer--right {
        * {
          display: none;
        }
      }
    }
  }
}
@media (min-width: 1024px) and (max-width: 1366px) {
  .chart__wrapper {
    .chart__body {
      .chart__body--center {
        .chart__body--right {
          display: block;
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .chart-column {
    &.chart__wrapper {
      .chart__body--right {
        display: block;
      }

      .chart__footer {
        .chart__footer--center {
          .axisX .axis-item {
            transform: unset;
            height: unset;
            padding: 20px 10px;

            .axis-item-year {
              word-break: break-all;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .chart-column {
    &.chart__wrapper {
      margin-right: 0;
      .chart__footer {
        .chart__footer--center {
          .axisX .axis-item {
            color: $monoBlack;
          }
        }
      }
    }   
  }
}

@media (max-width: 1365px) {
  .chart-column {
    &.chart__wrapper {
      .chart__footer {
        .chart__footer--left {
          height: 72px;
          * {
            display: none;
          }
        }
        .chart__footer--right {
          height: 72px;
          * {
            display: none;
          }
        }
      }
    }
  }
}

.chart__header {
  .chart__header--center {
    padding-bottom: 16px;
  }
}

.chart-column {
  .chart-body-content {
    display: flex;
    align-items: center;
    width: 100%;
    align-self: stretch;
  }
  &.chart__wrapper {
    .chart__body {
      .chart__body--left {
        z-index: 0;
      }
    }
  }
}
.tooltip {
  position: fixed;
  background-color: #000;
  color: #fff;
  font-size: 12px;
  z-index: 99;
}
.tooltip-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid rgba(42, 42, 48, 0.1);
  background: #fff;
  box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24),
    0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17),
    0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13),
    0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11),
    0px 36px 33px 0px rgba(160, 181, 186, 0.07);
  color: #000;
  padding: 5px;
  min-width: 139px;
}
.tooltip-title {
  color: $monoBlack;
  font-size: 11px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 4px;
}
.tooltip-content {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
.tooltip-content-left,
.tooltip-content-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  span {
    color: #404d50;
    font-size: 11px;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.33px;
  }
}
.tooltip-content-left {
  span {
    font-weight: 700;
  }
}
.tooltip-content-right {
  span {
    font-weight: 500;
  }
}
.chart__wrapper {
  padding: 0 16px 16px;
  margin-right: 16px;
  .chart__header {
    .chart-legend {
      .bar-color {
        background: $goldMid !important;
      }
    }
  }
  .chart__body {
    .chart__body--left {
      min-width: 53px;
      .axisY {
        justify-content: unset;
        position: relative;
        .axis-item {
          position: absolute;
          right: 0;
          min-width: unset;
        }
      }
    }
    .chart__body--center {
      .transition {
        background-color: $monoWhite;
      }

      .number-wrapper {
        width: 100%; 
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        position: relative;
        .positive-number {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-direction: column;
          position: absolute;
        }
        .negative-number {
          flex: 1;
          position: absolute;
        }
        .zero-crossline {
          border-top: 1px solid #ddd;
          height: 1px;
          width: 100%;
          position: absolute;
        }
        .bar--scope1 {
          background: $goldMid !important;
          width: 22.07px;

          @media only screen and (max-width: 450px) {
            width: 13.4px;
          }
        }
      }
    }
  }
}
</style>

