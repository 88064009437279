<template>
  <div class="trend-industry-chart">
    <PageTitle :label="labelSection" iconPath="column-chart.svg" :isPcafSetting="true" />
    <TypeFilter :type="type" @update:type="handleUpdateType" :currency="currency" @update:currency="handleUpdateCurrencyType" />
    <TabFilter :tab="tab" @update:tab="handleUpdateTab" :isTrendLineChart="true" />
    <ChartData
        :typeText="typeText"
        :type="type"
        :tab="tab"
        :chartData="chartData"
        :currency="currency"
        @updateSelectedChartData="updateSelectedChartData"
        :assetsNameTrend="assetsNameTrend"
        :totalScopes="totalScopes"
        :startMonth="startMonth"
      />
  </div>
</template>
<script>
import PageTitle from '@/components/products/common/page-title.vue';
import TypeFilter from '@/components/dashboard/home-pcaf/type-filter';
import TabFilter from '@/components/dashboard/home-pcaf/tab-filter';
import ChartData from '@/components/dashboard/home-pcaf/trend-industry-chart/chart-data';
import { mapActions, mapState } from 'vuex';
import { getTrendsSectorApi } from '@/api/pcaf';
import { getPcafPayload, isTotalEmission } from '@/utils/pcaf';
export default {
  components: { PageTitle, TypeFilter, TabFilter, ChartData },
  props: {
    branchIds: {
      type: Array,
      default: () => [],
    },
    assetId: {
      type: Number,
      default: 0,
    },
    startMonth: {
      type: Number,
      default: 4,
    }
  },
  data() {
    return {
      tab: 0,
      type: 2,
      chartData: [],
      selectedChartData: {},
      typeText: this.$t("dashboard_main.radio_select_investment_and_financing_amount"),
      inputTypes: [
        this.$t("dashboard_main.radio_select_investment_and_financing_amount"),
        this.$t("dashboard_main.radio_select_ghg_emissions_"),
      ],
      assetsNameTrend: [],
      yearsNotRegisterData: [],
      totalScopes: [],
      currency: 1
    };
  },
  mounted() {
    this.getChartData();
  },
  watch: {
    watchData: {
      handler() {
        this.getChartData();
        this.handleUpdateStoreData();
      },
    },
  },
  created() {
    this.tab = this.dashboard.trendIndustryChart.selectedTab;
    this.type = this.dashboard.trendIndustryChart.selectedType || 2;
    this.currency = this.dashboard.trendIndustryChart.selectedCurrency || 1;
    this.typeText = this.inputTypes[this.type];
    this.getChartData();
  },
  computed: {
    ...mapState('pcaf', ['dashboard']),
    labelSection() {
      return this.$t("dashboard_main.title_trends_by_industry_sector");
    },
    watchData() {
      return this.type + this.tab + this.branchIds + this.assetId + this.currency;
    },
  },
  methods: {
    ...mapActions('pcaf', ['updateTrendIndustryChart']),

    async getChartData() {
      let payload = {
        contractor_id: this.$store.state.userData.contractor,
        branch_id: this.branchIds,
      };
      if (this.assetId !== 0) {
        payload.pcaf_select = [this.assetId];
      }
      const pcafPayload = getPcafPayload(this.type, this.currency);
      payload.data_type = pcafPayload[0];
      payload.pcaf_type = pcafPayload[1];
      await getTrendsSectorApi(payload).then((res) => {
        let dataResponse = res;
        this.assetsNameTrend = dataResponse?.data.map((dataItem) => {
          return  dataItem.item_name;
        })
        if(isTotalEmission(this.type)) {
          const CALCULATOR_TYPE = 'total_emission';
          if(this.tab === 0) { //line chart
            this.chartData = this.transferDataLine(CALCULATOR_TYPE, dataResponse);
          }else { //column chart
            this.chartData = this.transferDataColumn(CALCULATOR_TYPE, dataResponse);
          }
        }
        else {
          const CALCULATOR_TYPE = 'total_amount';
          if(this.tab === 0) { //line chart
            this.chartData = this.transferDataLine(CALCULATOR_TYPE, dataResponse);
          }else { //column chart
            this.chartData = this.transferDataColumn(CALCULATOR_TYPE, dataResponse);
          }
        }
      })
    },
    transferDataLine(CALCULATOR_TYPE, dataResponse) {
      if(dataResponse.data.length === 0) {
        this.yearsNotRegisterData = dataResponse.totalEmissionGhg.map(emission => ({year: emission?.year.toString()}));
        return [];
      }
      let transformedDataLine = dataResponse.totalEmissionGhg.map((detailItem) => {
        const newItem = { year: detailItem?.year?.toString() };
        dataResponse.data.forEach((dataItem, index) => {
          const detail = dataItem.detail.find((detail) => detail.year === detailItem?.year);
          newItem[('item' + dataItem.item_name).toString()] = detail ? +detail[CALCULATOR_TYPE] : null;
          newItem[('detail' + dataItem.item_name).toString()] = detail;
        });
        newItem.detail = detailItem;
        return newItem;
      });
      if(transformedDataLine.length === 0) {
        const yearsAll = dataResponse.data[0].detail.map(detailItem => ({year: detailItem.year}))
        transformedDataLine = yearsAll.map(detailItem => {
          const newItem = { year: detailItem?.year?.toString() };
          dataResponse.data.forEach((dataItem, index) => {
            const detail = dataItem.detail.find((detail) => detail.year === detailItem?.year);
            if(detail) {
              newItem[('item' + dataItem.item_name).toString()] = detail ? +detail[CALCULATOR_TYPE] : null;
              newItem[('detail' + dataItem.item_name).toString()] = detail;
            }
          });
          newItem.detail = detailItem;
          return newItem;
        })
      }
     return transformedDataLine;
    },
    transferDataColumn(CALCULATOR_TYPE, dataResponse) {
      let transformedDataColumn = dataResponse.totalEmissionGhg.map(item => {
        const total_emission_original = item.total_emission
        const result = {
          time_range: item?.year?.toString(),
          original: {
            month: item?.year?.toString(),
            year: item?.year?.toString(),
            duration_id: item.duration_id,
          },
          zero_value_percent: '0%',
          year: item?.year?.toString(),
        };

        let total_emissionCalc = 0;
        dataResponse.data.forEach((category, index) => {
          const detailItem = category.detail.find(detail => detail.year.toString() === item?.year.toString());
          detailItem['nameAssets'] = category?.item_name;
          let findIndexScope = index;
          if(!this.totalScopes.includes(findIndexScope)) {
            this.totalScopes.unshift(findIndexScope);
          }
          if (detailItem) {
            result[`emission_scope${findIndexScope}`] = detailItem[CALCULATOR_TYPE];
            total_emissionCalc+=  +detailItem[CALCULATOR_TYPE]
            result['detail' + `emission_scope${findIndexScope}`] = detailItem;
          }
        });
        result.original = item;
        result.original.total_emission = total_emissionCalc;
        result.detail = item;
        result.detail.total_emission_original = total_emission_original
        return result;
      });
      if(transformedDataColumn.length === 0) {
        const listYear = []
        let currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;
        if (this.startMonth > currentMonth) {
          currentYear = currentYear - 1;
        }
        const startYear = 2017;
        for (let year = startYear; year <= currentYear; year++) {
          listYear.push({year: year.toString(), time_range: year.toString()})
        }
        const yearsAll = listYear;
        transformedDataColumn = yearsAll.map(item => {
          const result = {
          time_range: item?.year?.toString(),
          original: {
            month: item?.year?.toString(),
            year: item?.year?.toString(),
            duration_id: item?.duration_id,
          },
          zero_value_percent: '0%',
          year: item?.year?.toString(),
        };

        let total_emissionCalc = 0;
        dataResponse.data.forEach((category, index) => {
          const detailItem = category.detail.find(detail => detail.year.toString() === item?.year.toString());
          let findIndexScope = index;
          if(!this.totalScopes.includes(findIndexScope)) {
            this.totalScopes.unshift(findIndexScope);
          }
          detailItem['nameAssets'] = category?.item_name;
          if (detailItem) {
            result[`emission_scope${findIndexScope}`] = detailItem[CALCULATOR_TYPE];
            total_emissionCalc= total_emissionCalc + Number(detailItem[CALCULATOR_TYPE])
            result['detail' + `emission_scope${findIndexScope}`] = detailItem;
          }
        });
        result.original[CALCULATOR_TYPE] = total_emissionCalc;
        return result;
        })
      }
      if (!this.totalScopes.length) {
        const defaultScopes = [1,2,3,4,5];
        this.totalScopes = [...defaultScopes];
      }
      return transformedDataColumn;
    },
    updateSelectedChartData(chartData) {
      this.selectedChartData = chartData;
    },
    updateSelectedChartData() {},
    handleUpdateType(value, text)  {
      this.type = value;
      this.typeText = text;
    },
    handleUpdateTab(value) {
      this.tab = value;
    },
    handleUpdateCurrencyType(value) {
      this.currency = value;
    },
    handleUpdateStoreData() {
      this.updateTrendIndustryChart({
        ...this.dashboard.trendIndustryChart,
        selectedType: this.type,
        selectedTab: this.tab,
        selectedCurrency: this.currency,
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.trend-industry-chart {
  padding: 16px;
  background-color: $monoWhite;
  border-radius: 8px;
  margin-top: 40px;

  .product-title {
    margin-top: 0;
  }
}

.chart-data {
  display: flex;
  flex-flow: row;
  margin-top: 48px;
  }

@media only screen and (max-width: $desktop) {
  .trend-industry-chart {
    padding: 8px;
  }
}
</style>
