<template>
  <div class="period-filter-wrapper">
    <span class="period-text">{{$t('dashboard_main.label_target_period')}}</span>
    <div class="container-tab">
      <div class="row">
        <v-select
          solo
          flat
          :items="yearFromList"
          v-model="period.yearFrom"
          :label="$t('dashboard_main.placeholder_select_year')"
          class="custom-select select-type select-default list-menu--select select-item methods-export"
          :menu-props="{ contentClass: 'select-menu export-select' }"
        />
        <v-select
          solo
          flat
          :items="getMonthList(period.yearFrom)"
          item-text="text"
          :item-value="itemValueSelect"
          v-model="period.monthFrom"
          :label="$t('dashboard_main.placeholder_select_month')"
          class="custom-select select-type select-default list-menu--select select-item methods-export"
          :menu-props="{ contentClass: 'select-menu export-select' }"
        />
      </div>
      <span class="line">ー</span>

      <div class="row">
        <v-select
          solo
          flat
          :items="yearToList"
          v-model="period.yearTo"
          :label="$t('dashboard_main.placeholder_select_year')"
          class="custom-select select-type select-default list-menu--select select-item methods-export"
          :menu-props="{ contentClass: 'select-menu export-select' }"
        />
        <v-select
          solo
          flat
          :items="getMonthList(period.yearTo)"
          item-text="text"
          :item-value="itemValueSelect"
          v-model="period.monthTo"
          :label="$t('dashboard_main.placeholder_select_month')"
          class="custom-select select-type select-default list-menu--select select-item methods-export"
          :menu-props="{ contentClass: 'select-menu export-select' }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { calcYearFromStartMonthCustome, convertMonthCalendar, getMonthListCustome } from '@/utils/registerData';
import { convertMonthValueByMoment } from '@/utils/multiLanguage';
import { mapState } from 'vuex';

export default {
  props: {
    periodStartAt: {
      type: String,
      default: null,
    },
    periodEndAt: {
      type: String,
      default: null,
    },
    startMonth: {
      type: Number,
      default: 4,
    },
    type: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // monthList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      yearFromList: [],
      yearToList: [],
      period: {
        yearFrom: null,
        monthFrom: null,
        yearTo: null,
        monthTo: null,
      },
    };
  },
  mounted() {
    let currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    if (this.startMonth > currentMonth) {
      currentYear = currentYear - 1;
    }
    const latestDate = moment(`${currentYear}/${this.startMonth}`, 'YYYY/M').add(11, 'months').format('YYYY/MM');
    this.yearFromList = calcYearFromStartMonthCustome(this.startMonth, 2017, false).map(item => item.value);
    this.yearToList = [...this.yearFromList];
    // if (this.periodStartAt && this.periodEndAt) {
    //   this.period.yearFrom = Number(this.periodStartAt?.split('-')[0]);
    //   this.period.monthFrom = convertMonthCalendar(this.periodStartAt?.split('-')[1], true);
    //   this.period.yearTo = Number(this.periodEndAt?.split('-')[0]);
    //   this.period.monthTo = convertMonthCalendar(this.periodEndAt?.split('-')[1], true);
    //   return;
    // }
    this.period.yearFrom = 2017;
    this.period.monthFrom = convertMonthCalendar(this.startMonth, true);
    this.period.yearTo = Number(latestDate.split('/')[0]);
    this.period.monthTo = convertMonthCalendar(latestDate.split('/')[1], true);
  },
  computed: {
    ...mapState('userData', ['language']),
    startAt() {
      const yearFrom = this.period.yearFrom;
      const monthFrom = convertMonthValueByMoment(this.period.monthFrom);
      if (yearFrom && monthFrom) {
        const paddedMonthFrom = monthFrom.toString().padStart(2, '0');
        return `${yearFrom}-${paddedMonthFrom}`;
      }
      return null;
    },
    endAt() {
      const yearTo = this.period.yearTo;
      const monthTo = convertMonthValueByMoment(this.period.monthTo)
      if (yearTo && monthTo) {
        const paddedMonthTo = monthTo.toString().padStart(2, '0');
        return `${yearTo}-${paddedMonthTo}`;
      }
      return null;
    },
    itemValueSelect() {
      return this.language.code === 'en' ? 'text' : 'value'
    },
    watchPeriod() {
      return this.startAt + this.endAt + this.type;
    },
  },
  watch: {
    watchPeriod() {
      this.getTargetPeriod();
    },
    periodStartAt() {
      this.period.yearFrom = Number(this.periodStartAt?.split('-')[0]);
      this.period.monthFrom = convertMonthCalendar(this.periodStartAt?.split('-')[1], true);
    },
    periodEndAt() {
      this.period.yearTo = Number(this.periodEndAt?.split('-')[0]);
      this.period.monthTo = convertMonthCalendar(this.periodEndAt?.split('-')[1], true);
    }
  },
  methods: {
    getMonthList(yearSelected) {
      const monthList = getMonthListCustome(yearSelected, this.startMonth);
      return monthList;
    },
    getTargetPeriod() {
      this.$emit('targetPeriod', this.startAt, this.endAt);
    },
  },
};
</script>
<style lang="scss" scoped>
.period-filter-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.period-text {
  // font-family: Source Han Sans JP;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-align: left;
  color: $monoBlack;
  min-width: 64px;
}
.container-tab {
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 16px;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.line {
  // font-family: Source Han Sans JP;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.05em;
  text-align: left;
  color: $monoMid;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media screen and (max-width: 1023px) {
    padding: 16px 0px;
  }
}     
::v-deep .custom-select .v-label {
  // font-family: Source Han Sans JP;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-align: left;
  color: $monoMid;
}
::v-deep .row .select-item {
  max-width: calc(50% - 8px) !important;
}
@include desktop {
  .container-tab {
    display: flex;
    flex-direction: row;
    width: 70%;
  }
  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  .line {
    transform: none;
  }
}
@include large-desktop {
  .container-tab {
    display: flex;
    flex-direction: row;
    width: 50%;
  }
  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  .line {
    transform: none;
  }
}
</style>
